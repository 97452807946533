<template>
    <div class="search-container" :class="[customClass, {'search-auditor': isAuditor}, $mq]">
        <input type="search" :value="search" @input="handleInput" class="search" :placeholder="placeholder" autofocus @focus="handleFocus" @blur="handleBlur"/>
        <button v-if="search != ''" class="close" @click="handleCloseButton"></button>
    </div>
</template>

<script>
export default {
    name: 'SearchBar',
    props: {
        search: String,
        customClass: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isAuditor: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('on-search', e)
        },
        handleCloseButton() {
            this.$emit('on-button-click')
        },
        handleFocus(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$emit('on-focus')
        },
        handleBlur() {
            this.$emit('on-blur')
        }
    }
}
</script>

<style lang="scss" scoped>
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type='search']::placeholder {
    @include font-size(ml);
    font-family: $text;
}

.search-container {
    position: relative;
    width: 100%;
    margin: 0;
    padding-bottom: 12px;

    &.portrait{
        input[type='search']{
            @include font-size(s);
        }
        
        input[type='search']::placeholder {
            @include font-size(s);
            font-family: $text;
        }
    }

    &.selector-popup {
        padding-bottom: 0;
        border: 1px solid $color-neutral-300;
        @include border-radius(4px);

        .close {
            display: none;
        }
    }

    .search {
        @include display-flex();
        @include align-items(center);
        @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
        @include border-radius(4px);
        @include font-size(ml);
        height: 45px;
        width: 100%;
        background-color: #fff;
        padding: 0 60px 0 45px;
        border-bottom: none;
        font-family: $text;
        cursor: text;
    }

    .close {
        @include border-radius(4px);
        @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
        width: 33px;
        height: 33px;
        position: absolute;
        top: 7px;
        right: 10px;
        background-color: $neutro-t50;
        cursor: pointer;
    }
}
</style>
