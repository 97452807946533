<template>
    <div id="app">
        <lottie-animation :key="animationName" ref="anim" :animationData="require('@/../public/json/' + animationName)" :loop="loopAnimation" :autoPlay="true" @loopComplete="loopComplete" :speed="1" @complete="complete" @enterFrame="enterFrame" />
    </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
    name: 'Lottie',
    components: {
        LottieAnimation
    },
    props: {
        aditionalClass: {

        },
        animationName: {
            type: String,
            default: false
        },
        loopAnimation: {
            type: Boolean,
            default: false
        },
        timeToRepeat: {
            type: undefined,
            default: false
        },
        stopAnimation: {
            type: Boolean,
            default: false
        },
        frameStop: {
            type: undefined,
            default: false
        }
    },
    mounted() {
        this.$refs.anim.play()
    },
    methods: {
        loopComplete() {
            this.$refs.anim.pause()
            if (this.loopAnimation && this.timeToRepeat) {
                this.playEveryXTime(this.timeToRepeat)
            }
        },
        complete() {},
        playEveryXTime(time) {
            var self = this
            setTimeout(() => {
                if (self.$refs.anim != undefined) {
                    self.$refs.anim.play()
                }
            }, time)
        },
        enterFrame(frame) {
            if (this.frameStop) {
                if (frame.currentFrame.toString().substr(0, 2) == this.frameStop) {
                    this.$refs.anim.pause()
                }
            }
        }
    },
    watch: {
        stopAnimation(newValue, oldValue) {
            if (newValue) {
                this.$refs.anim.pause()
            }
        }
    }
}
</script>
