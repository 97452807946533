<template>
    <div class="vscroller">
        <div class="vscroller__head">{{ scrollerTitleHours }}</div>
        <div class="vscroller__body">
            <ul :style="{ webkitTransition: '-webkit-transform ' + transitionDuration / 1000 + 's ease-out', transition: 'transform ' + transitionDuration / 1000 + 's ease-out', webkitTransform: 'translate3d(0px, ' + currentTranslatedY + 'px, 0px)', transform: 'translate3d(0px, ' + currentTranslatedY + 'px, 0px)',  paddingLeft: '0px' }" ref="hours">
                <li class="hours" v-for="item in hours" :key="item.id" :data-val="item.value" :class="item.selected ? 'vselected' : ''">{{ item.value }}</li>
            </ul>
        </div>
        <span class="dots">:</span>
        <div class="vscroller__head2">{{ scrollerTitleMinutes }}</div>
        <div class="vscroller__body2">
            <ul :style="{ webkitTransition: '-webkit-transform ' + transitionDuration2 / 1000 + 's ease-out', transition: 'transform ' + transitionDuration2 / 1000 + 's ease-out', webkitTransform: 'translate3d(0px, ' + currentTranslatedY2 + 'px, 0px)', transform: 'translate3d(0px, ' + currentTranslatedY2 + 'px, 0px)',  paddingLeft: '0px' }" ref="minutes">
                <li class="minutes" v-for="item in minutes" :key="item.id" :data-val="item.value" :class="item.selected ? 'vselected2' : ''">{{ item.value }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HourTimeSelector',
    props: {
        givenValue: undefined,
        scrollerTitleHours: { type: String, default: '' },
        scrollerTitleMinutes: { type: String, default: '' },
        show: Boolean
    },
    data() {
        return {
            selectedIndex: 0,
            hourSelected: 0,
            minuteSelected: 0,
            startPosY: 0,
            currentPosY: 0,
            startTime: 0,
            endTime: 0,
            lastTime: new Date().getTime(),
            transitionDuration: 0,
            lastPosY: 0,
            lastV: 0,
            startTranslatedY: 80,
            currentTranslatedY: 80,
            haveClicked: false,
            isMouseDown: false,
            totalHeight: 40,
            startTranslatedY2: 80,
            currentTranslatedY2: 80,
            haveClicked2: false,
            isMouseDown2: false,
            totalHeight2: 40,
            selectedIndex2: 0,
            startPosY2: 0,
            currentPosY2: 0,
            startTime2: 0,
            endTime2: 0,
            lastTime2: new Date().getTime(),
            transitionDuration2: 0,
            lastPosY2: 0,
            lastV2: 0,
            hours: [],
            minutes: []
        }
    },
    computed: {},
    created() {
        this.load()
    },
    mounted() {
        let supportedTouch = false
        this.initHoursData()
        this.initMinutesData()
        if ('ontouchstart' in window) {
            supportedTouch = true
        }
        if (supportedTouch) {
            this.bindTouchEventsHours()
            this.bindTouchEventsMinutes()
        } else {
            this.bindMouseEventsHours()
            this.bindMouseEventsMinutes()
        }
        this.bindClickEventHours()
        this.bindClickEventMinutes()
    },
    updated() {},
    methods: {
        load() {
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    this.hours.push({ id: i, value: '0' + i, selected: false })
                } else {
                    this.hours.push({ id: i, value: i, selected: i == false })
                }
            }

            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    this.minutes.push({ id: i, value: '0' + i, selected: false })
                } else {
                    this.minutes.push({ id: i, value: i, selected: false })
                }
            }

            const date = this.givenValue

            if (date === '') {
                var h = 0
                this.selectedIndex = h
                this.hourSelected = h
                this.selectedIndex2 = h
                this.minuteSelected = h
            } else {
                const hour = date.substring(0, 2)
                const minutes = date.substring(3)

                this.hourSelected = hour
                this.selectedIndex = hour.charAt() == 0 ? parseInt(hour.substring(1)) : parseInt(hour)
                this.hours[this.selectedIndex].selected = true

                this.minuteSelected = minutes
                this.selectedIndex2 = minutes.charAt() == 0 ? parseInt(minutes.substring(1)) : parseInt(minutes)
                this.minutes[this.selectedIndex2].selected = true
            }
        },
        selected(item) {
            item.selected = true
        },
        initHoursData() {
            if (this.hours.length > 0) {
                this.hours.forEach((item) => {
                    if (item.selected === true) {
                        this.selectedIndex = parseInt(item.value)
                        this.hourSelected = item.value
                        return false
                    }
                    return true
                })
                this.startTranslatedY = 80 - this.selectedIndex * 40
                this.currentTranslatedY = this.startTranslatedY
            } else {
                this.hours = [{ value: 1, name: 'Please select..', selected: true }]
            }
            this.totalHeight = this.hours.length * 40
        },
        initMinutesData() {
            if (this.minutes.length > 0) {
                this.minutes.forEach((item) => {
                    if (item.selected === true) {
                        this.selectedIndex2 = parseInt(item.value)
                        this.minuteSelected = item.value
                        return false
                    }
                    return true
                })
                this.startTranslatedY2 = 80 - this.selectedIndex2 * 40
                this.currentTranslatedY2 = this.startTranslatedY2
            } else {
                this.minutes = [{ value: 1, name: 'Please select..', selected: true }]
            }
            this.totalHeight2 = this.minutes.length * 40
        },
        bindTouchEventsHours() {
            const el = this.$refs.hours
            // bind events
            el.addEventListener(
                'touchstart',
                (e) => {
                    this.startPosY = e.changedTouches[0].pageY
                    this.currentPosY = this.startPosY
                    this.startTime = new Date().getTime()
                    this.startTranslatedY = this.currentTranslatedY
                    this.lastV = 0
                    // console.log('touchstart!');
                },
                false
            )
            el.addEventListener(
                'touchmove',
                (e) => {
                    // console.log("mouseMove");
                    e.preventDefault() // prevent default selecting event when mouse moving
                    e.stopPropagation()
                    this.lastV = (e.changedTouches[0].pageY - this.lastPosY) / (new Date().getTime() - this.lastTime)
                    this.currentPosY = e.changedTouches[0].pageY
                    this.currentTranslatedY = this.startTranslatedY + this.currentPosY - this.startPosY
                    this.lastPosY = this.currentPosY
                    this.lastTime = new Date().getTime()
                },
                false
            )
            el.addEventListener(
                'touchend',
                () => {
                    this.endTime = new Date().getTime()
                    if (Math.abs(this.currentPosY - this.startPosY) > 5 && this.endTime - this.startTime > 50) {
                        const v = this.lastV
                        const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001
                        const t = Math.abs(v) / 0.001
                        let currentTranslatedY = this.currentTranslatedY
                        currentTranslatedY += s
                        const residue = currentTranslatedY % 40
                        if (Math.abs(residue) >= 20) {
                            if (residue < 0) {
                                currentTranslatedY += (40 + residue) * -1
                            } else {
                                currentTranslatedY += 40 - residue
                            }
                        } else {
                            currentTranslatedY -= residue
                        }
                        if (currentTranslatedY > 80) {
                            currentTranslatedY = 80
                        } else if (currentTranslatedY < (this.totalHeight - 120) * -1) {
                            currentTranslatedY = (this.totalHeight - 120) * -1
                        }
                        const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)
                        this.transitionDuration = t
                        this.currentTranslatedY = currentTranslatedY
                        var self = this
                        setTimeout(() => {
                            self.hours[self.selectedIndex].selected = false
                            self.selectedIndex = selectedIndex
                            // console.log("aqui");
                            self.hours[self.selectedIndex].selected = true
                            self.hourSelected = self.hours[self.selectedIndex].value
                            self.haveClicked = false
                            self.setValue()
                        }, 10)
                    } else {
                        this.haveClicked = true
                    }
                    this.startPosY = 0
                    this.currentPosY = 0
                    this.startTranslatedY = 0
                    this.startTime = 0
                    this.endTime = 0
                    this.lastPosY = 0
                    this.lastV = 0
                },
                false
            )
        },
        bindTouchEventsMinutes() {
            const el = this.$refs.minutes
            // bind events
            el.addEventListener(
                'touchstart',
                (e) => {
                    this.startPosY2 = e.changedTouches[0].pageY
                    this.currentPosY2 = this.startPosY2
                    this.startTime2 = new Date().getTime()
                    this.startTranslatedY2 = this.currentTranslatedY2
                    this.lastV2 = 0
                    // console.log('touchstart!');
                },
                false
            )
            el.addEventListener(
                'touchmove',
                (e) => {
                    e.preventDefault() // prevent default selecting event when mouse moving
                    e.stopPropagation()
                    this.lastV2 = (e.changedTouches[0].pageY - this.lastPosY2) / (new Date().getTime() - this.lastTime2)
                    this.currentPosY2 = e.changedTouches[0].pageY
                    this.currentTranslatedY2 = this.startTranslatedY2 + this.currentPosY2 - this.startPosY2
                    this.lastPosY2 = this.currentPosY2
                    this.lastTime2 = new Date().getTime()
                },
                false
            )
            el.addEventListener(
                'touchend',
                () => {
                    this.endTime2 = new Date().getTime()
                    if (Math.abs(this.currentPosY2 - this.startPosY2) > 5 && this.endTime2 - this.startTime2 > 50) {
                        const v = this.lastV2
                        const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001
                        const t = Math.abs(v) / 0.001
                        let currentTranslatedY = this.currentTranslatedY2
                        currentTranslatedY += s
                        const residue = currentTranslatedY % 40
                        if (Math.abs(residue) >= 20) {
                            if (residue < 0) {
                                currentTranslatedY += (40 + residue) * -1
                            } else {
                                currentTranslatedY += 40 - residue
                            }
                        } else {
                            currentTranslatedY -= residue
                        }
                        if (currentTranslatedY > 80) {
                            currentTranslatedY = 80
                        } else if (currentTranslatedY < (this.totalHeight2 - 120) * -1) {
                            currentTranslatedY = (this.totalHeight2 - 120) * -1
                        }
                        const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)
                        this.transitionDuration2 = t
                        this.currentTranslatedY2 = currentTranslatedY

                        var self = this
                        setTimeout(() => {
                            self.minutes[self.selectedIndex2].selected = false
                            self.selectedIndex2 = selectedIndex
                            self.minutes[self.selectedIndex2].selected = true
                            self.minuteSelected = self.minutes[self.selectedIndex2].value
                            self.haveClicked2 = false
                            self.setValue()
                        }, 10)
                    } else {
                        this.haveClicked2 = true
                    }
                    this.startPosY2 = 0
                    this.currentPosY2 = 0
                    this.startTranslatedY2 = 0
                    this.startTime2 = 0
                    this.endTime2 = 0
                    this.lastPosY2 = 0
                    this.lastV2 = 0
                },
                false
            )
        },
        bindMouseEventsHours() {
            const el = this.$refs.hours
            let mouseDown = null
            let mouseMove = null
            let mouseUp = null
            let mouseLeave = null
            let mouseWheel = null
            mouseDown = (e) => {
                // mouse down event
                // console.log("mouseMove");
                e.preventDefault() // prevent default selecting event when mouse moving
                e.stopPropagation()
                this.isMouseDown = true
                this.startPosY = e.pageY
                this.currentPosY = this.startPosY
                this.startTime = new Date().getTime()
                this.startTranslatedY = this.currentTranslatedY
                el.addEventListener('mousemove', mouseMove)
                el.addEventListener('mouseup', mouseUp)
                el.addEventListener('mouseleave', mouseLeave)
                // console.log('mouseDown!');
            }
            mouseMove = (e) => {
                // mouse move event
                if (this.isMouseDown) {
                    // console.log("mouseMove");
                    e.preventDefault() // prevent default selecting event when mouse moving
                    e.stopPropagation()
                    this.lastV = (e.pageY - this.lastPosY) / (new Date().getTime() - this.lastTime)
                    this.currentPosY = e.pageY
                    this.currentTranslatedY = this.startTranslatedY + this.currentPosY - this.startPosY
                    this.lastPosY = this.currentPosY
                    this.lastTime = new Date().getTime()
                    this.haveClicked = false
                }
            }
            mouseUp = () => {
                // mouse up event
                this.endTime = new Date().getTime()
                if (Math.abs(this.currentPosY - this.startPosY) > 5 && this.endTime - this.startTime > 20) {
                    const v = this.lastV
                    const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001
                    const t = Math.abs(v) / 0.001
                    let currentTranslatedY = this.currentTranslatedY
                    currentTranslatedY += s
                    const residue = currentTranslatedY % 40
                    if (Math.abs(residue) >= 20) {
                        if (residue < 0) {
                            currentTranslatedY += (40 + residue) * -1
                        } else {
                            currentTranslatedY += 40 - residue
                        }
                    } else {
                        currentTranslatedY -= residue
                    }
                    if (currentTranslatedY > 80) {
                        currentTranslatedY = 80
                    } else if (currentTranslatedY < (this.totalHeight - 120) * -1) {
                        currentTranslatedY = (this.totalHeight - 120) * -1
                    }
                    const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)
                    this.transitionDuration = t
                    this.currentTranslatedY = currentTranslatedY

                    var self = this
                    setTimeout(() => {
                        self.hours[self.selectedIndex].selected = false
                        self.selectedIndex = selectedIndex
                        self.hours[self.selectedIndex].selected = true
                        self.hourSelected = self.hours[self.selectedIndex].value
                        self.haveClicked = false
                        self.setValue()
                    }, 10)
                } else {
                    this.haveClicked = true
                }
                this.startPosY = 0
                this.currentPosY = 0
                this.startTranslatedY = 0
                this.startTime = 0
                this.endTime = 0
                this.lastPosY = 0
                this.lastV = 0
                this.isMouseDown = false
                el.removeEventListener('mousemove', mouseMove)
                el.removeEventListener('mouseup', mouseUp)
                el.removeEventListener('mouseleave', mouseLeave)
                // console.log('mouseUp!');
            }
            mouseLeave = () => {
                // mouse leave event
                if (this.isMouseDown) {
                    mouseUp()
                    // console.log('mouseLeave!');
                }
            }
            mouseWheel = (e) => {
                // mouse wheel event
                this.startTranslatedY = this.currentTranslatedY
                let currentTranslatedY = this.startTranslatedY + e.deltaY * 0.5
                const residue = currentTranslatedY % 40
                if (Math.abs(residue) >= 20) {
                    if (residue < 0) {
                        currentTranslatedY += (40 + residue) * -1
                    } else {
                        currentTranslatedY += 40 - residue
                    }
                } else {
                    currentTranslatedY -= residue
                }
                if (currentTranslatedY > 80) {
                    currentTranslatedY = 80
                } else if (currentTranslatedY < (this.totalHeight - 120) * -1) {
                    currentTranslatedY = (this.totalHeight - 120) * -1
                }
                this.transitionDuration = 0.2
                this.currentTranslatedY = currentTranslatedY
                const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)
                var self = this
                setTimeout(() => {
                    self.hours[self.selectedIndex].selected = false
                    self.selectedIndex = selectedIndex
                    self.hours[self.selectedIndex].selected = true
                    self.hourSelected = self.hours[self.selectedIndex].value
                    self.setValue()
                }, this.transitionDuration)
                this.startTranslatedY = 0
            }
            // bind events
            el.addEventListener('mousedown', mouseDown)
            el.addEventListener('wheel', mouseWheel)
        },
        bindMouseEventsMinutes() {
            const el = this.$refs.minutes
            let mouseDown = null
            let mouseMove = null
            let mouseUp = null
            let mouseLeave = null
            let mouseWheel = null
            mouseDown = (e) => {
                // mouse down event
                // console.log("mouseMove");
                e.preventDefault() // prevent default selecting event when mouse moving
                e.stopPropagation()
                this.isMouseDown2 = true
                this.startPosY2 = e.pageY
                this.currentPosY2 = this.startPosY2
                this.startTime2 = new Date().getTime()
                this.startTranslatedY2 = this.currentTranslatedY2
                el.addEventListener('mousemove', mouseMove)
                el.addEventListener('mouseup', mouseUp)
                el.addEventListener('mouseleave', mouseLeave)
                // console.log('mouseDown!');
            }
            mouseMove = (e) => {
                // mouse move event
                if (this.isMouseDown2) {
                    // console.log("mouseMove");
                    e.preventDefault() // prevent default selecting event when mouse moving
                    e.stopPropagation()
                    this.lastV2 = (e.pageY - this.lastPosY2) / (new Date().getTime() - this.lastTime2)
                    this.currentPosY2 = e.pageY
                    this.currentTranslatedY2 = this.startTranslatedY2 + this.currentPosY2 - this.startPosY2
                    this.lastPosY2 = this.currentPosY2
                    this.lastTime2 = new Date().getTime()
                    this.haveClicked2 = false
                }
            }
            mouseUp = () => {
                // mouse up event
                this.endTime2 = new Date().getTime()
                if (Math.abs(this.currentPosY2 - this.startPosY2) > 5 && this.endTime2 - this.startTime2 > 20) {
                    const v = this.lastV2
                    const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001
                    const t = Math.abs(v) / 0.001
                    let currentTranslatedY = this.currentTranslatedY2
                    currentTranslatedY += s
                    const residue = currentTranslatedY % 40
                    if (Math.abs(residue) >= 20) {
                        if (residue < 0) {
                            currentTranslatedY += (40 + residue) * -1
                        } else {
                            currentTranslatedY += 40 - residue
                        }
                    } else {
                        currentTranslatedY -= residue
                    }
                    if (currentTranslatedY > 80) {
                        currentTranslatedY = 80
                    } else if (currentTranslatedY < (this.totalHeight2 - 120) * -1) {
                        currentTranslatedY = (this.totalHeight2 - 120) * -1
                    }
                    const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)
                    this.transitionDuration2 = t
                    this.currentTranslatedY2 = currentTranslatedY
                    var self = this
                    setTimeout(() => {
                        self.minutes[self.selectedIndex2].selected = false
                        self.selectedIndex2 = selectedIndex
                        self.minutes[self.selectedIndex2].selected = true
                        self.minuteSelected = self.minutes[self.selectedIndex2].value
                        self.haveClicked2 = false
                        self.setValue()
                    }, 10)
                } else {
                    this.haveClicked2 = true
                }
                this.startPosY2 = 0
                this.currentPosY2 = 0
                this.startTranslatedY2 = 0
                this.startTime2 = 0
                this.endTime2 = 0
                this.lastPosY2 = 0
                this.lastV2 = 0
                this.isMouseDown2 = false
                el.removeEventListener('mousemove', mouseMove)
                el.removeEventListener('mouseup', mouseUp)
                el.removeEventListener('mouseleave', mouseLeave)
                // console.log('mouseUp!');
            }
            mouseLeave = () => {
                // mouse leave event
                if (this.isMouseDown2) {
                    mouseUp()
                    // console.log('mouseLeave!');
                }
            }
            mouseWheel = (e) => {
                // mouse wheel event
                this.startTranslatedY2 = this.currentTranslatedY2
                let currentTranslatedY = this.startTranslatedY2 + e.deltaY * 0.5
                const residue = currentTranslatedY % 40
                if (Math.abs(residue) >= 20) {
                    if (residue < 0) {
                        currentTranslatedY += (40 + residue) * -1
                    } else {
                        currentTranslatedY += 40 - residue
                    }
                } else {
                    currentTranslatedY -= residue
                }
                if (currentTranslatedY > 80) {
                    currentTranslatedY = 80
                } else if (currentTranslatedY < (this.totalHeight2 - 120) * -1) {
                    currentTranslatedY = (this.totalHeight2 - 120) * -1
                }
                this.transitionDuration2 = 0.2
                this.currentTranslatedY2 = currentTranslatedY
                const selectedIndex = Math.abs((currentTranslatedY - 80) / -40)

                var self = this
                setTimeout(() => {
                    self.minutes[self.selectedIndex2].selected = false
                    self.selectedIndex2 = selectedIndex
                    self.minutes[self.selectedIndex2].selected = true
                    self.minuteSelected = self.minutes[self.selectedIndex2].value
                }, this.transitionDuration2)
                this.startTranslatedY2 = 0
                this.setValue()
            }
            // bind events
            el.addEventListener('mousedown', mouseDown)
            el.addEventListener('wheel', mouseWheel)
        },
        bindClickEventHours() {
            const el = this.$refs.hours
            el.querySelectorAll('.hours').forEach(($li, index) => {
                $li.addEventListener('click', () => {
                    if (this.haveClicked) {
                        // console.log("bajo!");
                        const itemPositionY = $li.offsetTop
                        const currentTranslatedY = 80 - itemPositionY
                        this.transitionDuration = 0
                        this.currentTranslatedY = currentTranslatedY
                        this.hours[this.selectedIndex].selected = false
                        this.selectedIndex = index
                        this.hours[this.selectedIndex].selected = true
                        this.hourSelected = this.hours[this.selectedIndex].value
                        this.haveClicked = false
                        this.minutes[this.selectedIndex2].selected = false
                        this.minutes[this.selectedIndex2].selected = true
                        this.setValue()
                    }
                })
                return true
            })
        },
        bindClickEventMinutes() {
            const el = this.$refs.minutes
            el.querySelectorAll('.minutes').forEach(($li, index) => {
                $li.addEventListener('click', () => {
                    if (this.haveClicked2) {
                        const itemPositionY = $li.offsetTop
                        const currentTranslatedY = 80 - itemPositionY
                        this.transitionDuration2 = 0
                        this.currentTranslatedY2 = currentTranslatedY
                        this.minutes[this.selectedIndex2].selected = false
                        this.selectedIndex2 = index
                        this.minutes[this.selectedIndex2].selected = true
                        this.minuteSelected = this.minutes[this.selectedIndex2].value
                        this.haveClicked2 = false
                        this.hours[this.selectedIndex].selected = false
                        this.hours[this.selectedIndex].selected = true
                        this.hourSelected = this.hours[this.selectedIndex].value
                        this.haveClicked = false
                        this.setValue()
                    }
                })
                return true
            })
        },
        setValue() {
            if (this.hourSelected === 0) {
                this.hourSelected = '00'
            } else if (this.minuteSelected === 0) {
                this.minuteSelected = '00'
            }
            var result = this.hourSelected + ':' + this.minuteSelected
            this.$emit('input', result)
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped></style>
