// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { Api } from '@/core/api.js'

import { FORMAT } from '@/constants'

import localforage from 'localforage'
import { db_issues } from '@/database'
const api = new Api()

const db_tasks = localforage.createInstance({
    name: 'alexdb',
    storeName: 'tasks'
})

const db_tasks_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'tasks_queue'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        current: false,
        templates: {},
        timeframes: {
            current: 0,
            data: {}
        },
        date: false,
        type: 1,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: true,
            has_state: true,
            has_timing: true,
            has_mandatory: false,
            is_autosave: true,
            can_create: false,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: false,
                inPast: false,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: true,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: true,
                has_score: false
            }
        },
        current_date: moment().format('YYYY-MM-DD'),
        user_date_changed: false,
        categories: {}
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getById: (state, getters, rootState) => (id) => {
        return id ? state.items[id] : false
    },
    getItemsExcluded: (state, getters, rootState) => {
        return state.config.item.itemsExcluded
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => {
        return state.templates
    },
    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },
    getItemsBySection: (state, getters, rootState) => (check, section) => {
        var newSectionFound = false
        var startLooking = false
        var resultItems = {}
        var items = check ? Object.values({ ...state.items[check].items }) : []

        // CLEAN OF SUBITEMS
        items = items.filter((obj) => {
            return !obj.parent_id
        })

        // FIRST SORT BY ORDER AND ADD ITEMS TO RESULT
        items
            .sort(function (l, r) {
                return l.order - r.order
            })
            .forEach(function (item) {
                if (item.type != 8 && startLooking && !newSectionFound) {
                    resultItems[item.item_id] = item
                } else {
                    if (!startLooking && item.item_id == section) {
                        startLooking = true
                    } else if (startLooking) {
                        newSectionFound = true
                        startLooking = false
                    }
                }
            })

        return resultItems
    },
    getTasks: (state, getters, rootState) => (id) => {
        let tasks = id ? state.items[id].items : {}
        let realTasks = []

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]
            if (!item.parent_id) {
                realTasks.push(item)
            }
        })
        return realTasks
    },
    getTaskById: (state, getters, rootState) => (check_id, task_id) => {
        let tasks = state.items[check_id] ? state.items[check_id].items : {}
        let result = false

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]

            if (item.item_id == task_id) {
                result = item
            }
        })
        return result
    },
    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => (wich) => {
        return state.stats[wich]
    },
    getCurrentTimeframe: (state, getters, rootState) => {
        return state.timeframes.current
    },
    getTimeframes: (state, getters, rootState) => {
        return state.timeframes.data
    },
    getCheckTasksState: (state, getters, rootState) => (id) => {
        let items = id ? state.items[id].items : {}
        let values = []
        let result = ''

        Object.keys(items).map(function (id, i) {
            var val = items[id]

            if (val.type == 1 && !val.parent_id) {
                values.push(val.value ? 1 : 0)
            }
        })

        if (values.indexOf(0) != -1 && values.indexOf(1) != -1) {
            result = 'semichecked'
        } else if (values.indexOf(0) == -1) {
            result = 'checked'
        }

        return result
    },
    currentDate: (state, getters, rootState) => {
        return state.current_date
    },
    userDateChanged: (state, getters, rootState) => {
        return state.user_date_changed
    },
    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categories[id]
        }
        return state.categories
    }
}

// actions
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    loadCategories(context) {
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.get('checklist/1/categories').then(function (response) {
                var categories = response.data
                if (Object.values(response.data).length > 0) {
                    categories['all'] = {
                        id: 'all',
                        name: i18n.t('tasls.all_tasks'),
                        highlight: true,
                        order: -1,
                        color: '#374673',
                        type: 1
                    }
                }
                for (var k in categories) {
                    categories[k].score = 0
                    categories[k].total = 0
                    categories[k].alert_pending_count = 0
                    categories[k].alert_pending_show = false
                    categories[k].alert_important_show = false
                }

                context.commit('setCategories', categories)
            })
        }
    },
    loadAll({ state, context, commit, dispatch, rootState }, params) {
        var date = moment(params.date * 1000).format('YYYY-MM-DD')

        if ((typeof params.forceUpdate !== 'undefined' && params.forceUpdate) || date != state.date) {
            if (!fnCheckConnection()) {
                return loadTemplatesOffline(commit, dispatch)
            }

            let url = 'checklist/' + state.type + '/' + date
            return api
                .get(url, {})
                .then(function (response) {
                    _.forEach(_.values(response.data), async function (value, key) {
                        if (value.id !== 'undefined' && value !== 'undefined') {
                            await db_tasks.setItem(value.id, value)
                        }
                    })
                    commit('setDate', date)
                    return dispatch('mergeOffline', { data: response.data }).then(function (response2) {
                        return dispatch('format', { data: response2 }).then(() => {
                            return dispatch('calcScore')
                        })
                    })
                })
                .catch((error) => {
                    return loadTemplatesOffline(commit, dispatch)
                })
        } else {
            return loadTemplatesOffline(commit, dispatch)
        }
    },
    async addIssue(context, params) {
        context.dispatch('calcScore', params.checklist_ref)

        var oData = { template: params.tpl_id }

        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref

            let check = context.getters.getById(params.checklist_ref)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            oData.total = total
            oData.answered = answered
            oData.score = score
            oData.resume = JSON.stringify(resume)
        }

        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        if (params.isOfflineCreated) {
            oData.isOfflineCreated = true
        }

        if (params.toolQueue) {
            oData.toolQueue = params.toolQueue
        }

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/add', oData).then(function (response) {
                var items = { ...state.items }
                var reference = { checklist: Object.values(response.data)[0].id, type: Object.values(response.data)[0].type, status: Object.values(response.data)[0].status }
                var item = { ...items[params.checklist_ref].items[params.item_ref] }
                item.reference = reference
                items[params.checklist_ref].items[params.item_ref] = item
                Vue.set(state, 'items', items)
                return response.data
            })
        } else {
            if (canWorkOffline) {
                log('> task addIssue', oData)
                const issue = await store.dispatch('issues/add', oData)
                log('>> TASKS-ISSUE ITEM-ID', issue)
                const reference = {
                    checklist: issue.id,
                    type: issue.type,
                    status: issue.status
                }

                context.state.items[params.checklist_ref].items[params.item_ref].reference = reference
                const currentTimeframe = context.state.timeframes.current
                db_tasks.getItem(currentTimeframe.toString()).then((template) => {
                    if (template) {
                        template.templates[params.checklist_ref].items[params.item_ref].reference = reference
                        db_tasks.setItem(currentTimeframe, template)
                    }
                })

                return issue
            }
        }
    },
    async comment(context, params) {
        const canWorkOffline = context.rootGetters.getOffline

        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            action: 'comment',
            checklist: params.check,
            task: params.answer,
            message: params.message,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline,
            timeframe_id: state.timeframes.current
        }

        if (typeof params.file !== 'undefined') sended.file = params.file

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: sended, action: 'comment' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/comment', sended)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else if (!response.status) {
                        var error = {
                            check: params.check,
                            answer: params.answer,
                            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    } else {
                        var returned = {
                            check: params.check,
                            answer: params.answer,
                            message: response.data.message,
                            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                        }
                        if (typeof response.data.image !== 'undefined') {
                            returned.image = response.data.image
                        }

                        return context.commit('setComment', returned)
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        let offlineMessage = {
                            check: params.check,
                            answer: params.answer,
                            message: params.message,
                            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                        }
                        if (typeof params.file !== 'undefined') offlineMessage.image = params.file

                        return context
                            .dispatch('syncAction', sended)
                            .then((response) => {
                                context.commit('setComment', offlineMessage)
                            })
                            .catch(() => {
                                context.commit('setComment', offlineMessage)
                            })
                    } else {
                        var error = {
                            check: params.check,
                            answer: params.answer
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    }
                })
        } else if (context.rootGetters.getOffline) {
            let offlineMessage = {
                check: params.check,
                answer: params.answer,
                message: params.message,
                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
            }
            if (typeof params.file !== 'undefined') offlineMessage.image = params.file
            return context
                .dispatch('syncAction', sended)
                .then((response) => {
                    return context.commit('setComment', offlineMessage)
                })
                .catch(() => {
                    context.commit('setComment', offlineMessage)
                })
        } else {
            var error = {
                check: params.check,
                answer: params.answer,
                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
            }
            if (typeof params.file !== 'undefined') error.image = null
            else error.message = ''

            context.commit('setComment', error)
            return { status: false }
        }
    },
    async validate(context, params) {
        const canWorkOffline = context.rootGetters.getOffline
        context.commit('setValidation', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })
        await context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            timeframe_id: state.timeframes.current
        }

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'validate' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/validate', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'validate'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return true
                            })
                            .catch(() => {
                                return true
                            })
                    } else if (!response.status) {
                        return context.commit('setReset', { check: params.check, answer: params.answer })
                    } else {
                        return true
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'validate'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return true
                            })
                            .catch(() => {
                                return true
                            })
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'validate'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return true
                    })
                    .catch(() => {
                        return true
                    })
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer })
            }
        }
    },
    async unvalidate(context, params) {
        const canWorkOffline = context.rootGetters.getOffline
        context.commit('setUnvalidation', {
            check: params.check,
            answer: params.answer,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })
        await context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            timeframe_id: state.timeframes.current
        }

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'unvalidate' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/unvalidate', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'unvalidate'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return true
                            })
                            .catch(() => {
                                return true
                            })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'unvalidate'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return true
                            })
                            .catch(() => {
                                return true
                            })
                    }
                })
        } else if (context.rootGetters.getOffline) {
            paramsApi.action = 'unvalidate'
            return context
                .dispatch('syncAction', paramsApi)
                .then(() => {
                    return true
                })
                .catch(() => {
                    return true
                })
        }
    },
    complete(context, params) {
        context.dispatch('calcScore', params.checklist)

        let check = context.getters.getById(params.checklist)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        const canWorkOffline = context.rootGetters.getOffline

        let paramsApi = {
            checklist: params.checklist,
            complete_date: params.complete_date,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/complete', paramsApi)
                .then(function (response) {
                    let screenlockData = context.rootGetters['login/getScreenlockData'];
                    let screenlockFlag = context.rootGetters['getScreenlockFlag'];
                    if(screenlockFlag && params.checklist == screenlockData.checklist_id){
                        context.dispatch('login/getScreenlockTasks', null, { root: true })
                    }
                    return response
                })
                .catch((error) => {
                    return { status: false }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'complete'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return { status: false }
                    })
                    .catch(() => {
                        return { status: false }
                    })
            } else {
                return { status: false }
            }
        }
    },
    uncomplete(context, params) {
        const canWorkOffline = context.rootGetters.getOffline
        let paramsApi = {
            checklist: params.checklist
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/uncomplete', paramsApi)
                .then(function (response) {
                    let screenlockFlag = context.rootGetters['getScreenlockFlag'];
                    if(screenlockFlag && params.config.screenlock){
                        let screenlock = {
                            checklist_id: params.checklist,
                            message: params.config.screenlockMessage
                        }
                        context.commit('login/setScreenlockData', screenlock, { root: true });
                    }
                    return response
                })
                .catch((error) => {
                    console.error(error)
                    return { status: false }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'uncomplete'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return { status: false }
                    })
                    .catch(() => {
                        return { status: false }
                    })
            } else {
                return { status: false }
            }
        }
    },
    loadById(context, params) {
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.get('checklist/id/' + params.id, {}).then(function (response) {
                return context.dispatch('mergeOffline', { data: response.data }).then(function (response2) {
                    return context.dispatch('format', { data: response2 }).then(() => {
                        return context.dispatch('calcScore')
                    })
                })
            })
        }
    },
    reload() {
        store
            .dispatch('format', {
                data: checklist
            })
            .then(() => {
                store.dispatch('calcScore')
            })
    },
    async answer(context, params) {
        let value = params.value
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        const canWorkOffline = context.rootGetters.getOffline

        // In case of image or signature type, JSON-parse the value
        if (state.config.item.itemsImage.includes(params.answer_type)) {
            value = JSON.stringify(value)
        }

        // answer
        context.commit('setAnswer', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date,
            value: params.value,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })

        // calculate score
        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]
            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        const paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            value: value,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            timeframe_id: state.timeframes.current,
            type: params.answer_type
        }

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'answer' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/answer', paramsApi)
                .then(function (response) {
                    if (!response.status) {
                        if (canWorkOffline) {
                            logError(error)
                            paramsApi.action = 'answer'
                            return context
                                .dispatch('syncAction', paramsApi)
                                .then(() => {
                                    return context.dispatch('calcScore', params.check).then(function () {
                                        return { status: false }
                                    })
                                })
                                .catch(() => {
                                    return context.dispatch('calcScore', params.check).then(function () {
                                        return { status: false }
                                    })
                                })
                        } else {
                            context.commit('setReset', { check: params.check, answer: params.answer })
                            return context.dispatch('calcScore', params.check).then(function () {
                                return { status: false }
                            })
                        }
                    } else {
                        let value = response.data.value
                        // In case of image or signature type, JSON-parse the value
                        if (state.config.item.itemsImage.includes(params.answer_type)) {
                            value = splitCommasBase64(value)

                            // answer
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: params.answer,
                                emp: params.emp,
                                date: params.date,
                                value: value
                            })
                        }
                    }
                    return response
                })
                .catch((error) => {
                    if (canWorkOffline) {
                        logError(error)
                        paramsApi.action = 'answer'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                            .catch(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'answer'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
                    .catch(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer })
                return context.dispatch('calcScore', params.check).then(function () {
                    return { status: false }
                })
            }
        }
    },
    async apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let item = state.items[params.check].items[params.answer]
        let items = context.getters.getItemsBySection(params.check, params.answer)
        const canWorkOffline = context.rootGetters.getOffline

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })

        if (item.type == FORMAT.TITLE) {
            Object.keys(items).map(function (item_id, i) {
                let it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        await context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                let item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            apply: params.apply,
            total: total,
            answered: answered,
            score: score,
            timeframe_id: state.timeframes.current,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/apply', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'apply'
                return context.dispatch('syncAction', paramsApi)
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
            }
        }
    },
    async applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        var item = state.items[params.check].items[params.answer]
        if (item.type == FORMAT.TITLE) {
            let items = context.getters.getItemsBySection(params.check, params.answer)

            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        await context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },

    async reset(context, params) {
        const canWorkOffline = context.rootGetters.getOffline

        if (context.rootGetters.getOffline || fnCheckConnection()) {
            let subtask = context.getters.getSubtasks(params.check, params.answer)
            const sync = !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline

            context.commit('setReset', {
                check: params.check,
                answer: params.answer,
                requireSync: sync
            })

            await context.dispatch('calcScore', params.check)

            if (subtask.length > 0) {
                Object.keys(subtask).map((item_id, i) => {
                    var item = subtask[item_id]
                    context.commit('setReset', { check: params.check, answer: item, requireSync: sync })
                })
            }

            let check = context.getters.getById(params.check)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            const paramsApi = {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume),
                timeframe_id: state.timeframes.current
            }

            if (canWorkOffline) {
                await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'reset' })
            }

            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                return api
                    .post('checklist/task/reset', paramsApi)
                    .then((response) => {
                        if (!response.status) {
                            paramsApi.action = 'reset'
                            return context
                                .dispatch('syncAction', paramsApi)
                                .then((response) => {
                                    return { status: true }
                                })
                                .catch(() => {
                                    return { status: true }
                                })
                        } else {
                            return { status: true }
                        }
                    })
                    .catch(() => {
                        paramsApi.action = 'reset'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then((response) => {
                                return { status: true }
                            })
                            .catch(() => {
                                return { status: true }
                            })
                    })
            } else {
                paramsApi.action = 'reset'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then((response) => {
                        return { status: true }
                    })
                    .catch(() => {
                        return { status: true }
                    })
            }
        } else {
            return { status: false }
        }
    },

    format({ state, context, commit, dispatch, rootState, rootGetters }, params = {}) {
        // TODO APPEND
        // var append = params.append ? true : false;
        let screenlockData = rootGetters['login/getScreenlockData']
        if(typeof params.screenlock == 'undefined' && screenlockData.checklist_id){
            return true;
        }

        // SETTER
        var aFrames = {}
        var aItems = {}

        // if (append) {
        //   params.data = params.append;
        // }

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (item_id, i) {
                var tframe = params.data[item_id]

                tframe.completed = 0
                tframe.total = 0
                tframe.score = 0

                tframe.alert_pending_count = 0
                tframe.alert_pending_show = false
                tframe.alert_important_show = false

                Object.keys(tframe.templates).map(function (item_id, i) {
                    var list = tframe.templates[item_id]

                    // Timezone set
                    list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
                    list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
                    list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false
                    // Timezone set TASKS
                    if (list.schedule) {
                        list.schedule['start'] = list.schedule.start ? (moment.isMoment(list.schedule.start) ? list.schedule.start : moment(list.schedule.start * 1000)) : false
                        list.schedule['end'] = list.schedule.end ? (moment.isMoment(list.schedule.end) ? list.schedule.end : moment(list.schedule.end * 1000)) : false
                    }
                    if (list.limit) {
                        list.limit['start'] = list.limit.start ? (moment.isMoment(list.limit.start) ? list.limit.start : moment(list.limit.start * 1000)) : false
                        list.limit['end'] = list.limit.end ? (moment.isMoment(list.limit.end) ? list.limit.end : moment(list.limit.end * 1000)) : false
                    }

                    list.def = state.config
                    list.timeframe_id = tframe.id
                    list.uncompleted = 0
                    list.completed = 0
                    list.unanswered = 0
                    list.total = 0
                    list.score = -1

                    list.need_justification = false

                    // Loop for all items
                    Object.keys(list.items).map(function (item_id, i) {
                        var item = list.items[item_id]

                        // Timezone set
                        item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                        if (item.review) {
                            item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                        }
                        // Timezone set TASKS
                        item.planned_from_date = item.planned_from_date ? (moment.isMoment(item.planned_from_date) ? item.planned_from_date : moment(item.planned_from_date * 1000)) : false
                        item.planned_to_date = item.planned_to_date ? (moment.isMoment(item.planned_to_date) ? item.planned_to_date : moment(item.planned_to_date * 1000)) : false

                        // If type image - Split value to array
                        if (state.config.item.itemsImage.includes(item.type)) {
                            item.value = item.value && typeof item.value === 'string' ? splitCommasBase64(item.value) : item.value
                        }

                        item.complete_date = item.complete_date
                        item.available = 0
                        item.apply = parseInt(item.apply) == 1

                        item.config = typeof item.config === 'string' ? JSON.parse(item.config) : item.config

                        // If can pospone
                        if (item.margin > 0 && state.config.item.can_pospone) {
                            let now = moment().set({ m: 0, s: 0, ms: 0 })
                            let start = moment(list.business_date).set({ m: 0, s: 0, ms: 0 })
                            if (item.planned_from_date) {
                                start = moment(item.planned_from_date).set({ m: 0, s: 0, ms: 0 })
                                // } else if (item.planned_from_date) {
                                //   start = moment(item.planned_from_date).set({m: 0,s: 0,ms: 0 });
                            }
                            let end = moment(start).add(parseInt(item.margin), 'd')

                            if (now.isBetween(start, end) && now.isAfter(start)) {
                                start = now
                            }

                            item.available = Math.round(moment.duration(end.diff(list.business_date)).asDays(), 0)
                        }

                        list.items[item_id] = { ...item }
                        Vue.set(list.items, item_id, { ...item })
                    })

                    aItems[list.id] = list
                })

                // tframe.templates = Object.keys(tframe.templates)

                // SETTER
                aFrames[tframe.id] = tframe
                // state.timeframes.data[tframe.id] = tframe;
            })
        }

        // if (append) {

        //   aFrames = { ...aFrames, ...state.timeframes.data };
        //   aItems = { ...aItems, ...state.items };

        // }

        // SETTER
        commit('setFormat', { items: aItems })
        commit('setTimeframes', { frames: aFrames })
        return true
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        // TODO APPEND
        // var append = params.append ? true : false;

        var aFrames = state.timeframes.data
        var aCategories = state.categories
        var aItems = state.items

        // if (append) {
        //   aFrames = { ...aFrames, ...params.append };
        // }

        log('----- CALC')

        var alert_pending_count = {}
        var alert_pending_show = {}
        var alert_important_show = {}
        var c_alert_pending_count = {}
        var c_alert_pending_show = {}
        var c_alert_important_show = {}
        var tframe_id = typeof id === 'undefined' ? false : aItems[id].timeframe_id
        var category_id = typeof id === 'undefined' ? false : aItems[id].category_id

        // SETTER
        Object.keys(aFrames).map(function (item_id, i) {
            if (typeof id === 'undefined' || item_id == tframe_id) {
                var tframe = aFrames[item_id]
                tframe.completed = 0
                tframe.total = 0
                tframe.score = 0

                alert_pending_count[tframe.id] = 0
                alert_pending_show[tframe.id] = false
                alert_important_show[tframe.id] = false
                tframe.alert_pending_count = 0
                tframe.alert_pending_show = false
                tframe.alert_important_show = false
            }
        })

        // SETTER CATEGORIES
        Object.keys(aCategories).map(function (item_id, i) {
            if (typeof id === 'undefined' || item_id == category_id) {
                var category = aCategories[item_id]
                category.completed = 0
                category.total = 0
                category.score = 0

                c_alert_pending_count[category.id] = 0
                c_alert_pending_show[category.id] = false
                c_alert_important_show[category.id] = false
                category.alert_pending_count = 0
                category.alert_pending_show = false
                category.alert_important_show = false
            }
        })

        // SETTER
        Object.keys(aItems).map(function (item_id, i) {
            //   alert(item_id)
            var list = aItems[item_id]
            if (typeof id === 'undefined' || item_id == id) {
                let now = moment().set({ s: 0, ms: 0 })

                var past_score = list.score ? list.score : 0
                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total = 0
                list.score = 0
                list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false
                list.planned_items = 0
                list.to_review = 0
                list.current_day = list.business_date.format('YYYY-MM-DD') == now.format('YYYY-MM-DD')
                list.in_time = list.current_day && (list.timeframe_id == 0 || (now.valueOf() >= list.schedule.start.valueOf() && now.valueOf() <= list.schedule.end.valueOf()))
                list.completed_in_time = list.complete_date && (list.timeframe_id == 0 || list.complete_date.valueOf() <= list.schedule.end.valueOf())

                // Loop for all items
                Object.keys(list.items).map(function (item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // TODO VALIDATE IF ITEM require_review IS TRUE AND review IS FALSE
                    if (item.require_review && !item.review && item.apply && !item.planned_to_date) {
                        list.to_review++
                    }

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'

                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]

                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            log(list.items[key], value, operator, val)

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) && list.items[key] && list.items[key].is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true' || (value != null && val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value || (val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = parseFloat(current_order) + 0.5
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []
                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value
                            let date = alert.date ? alert.date : ''
                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && (alert.msg || alert.message)) {
                                if (message == '') message = alert.msg != '' ? alert.msg : alert.message
                                else message = message + '<br/>' + (alert.msg != '' ? alert.msg : alert.message)

                                if (preview == '') preview = alert.msg != '' ? alert.msg : alert.message
                                else preview = preview + ', ' + (alert.msg != '' ? alert.msg : alert.message)
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }

                            if (show && alert.template) {
                                if (message == '') message = i18n.tc('tasks.generated_checklist', date, { c: date })
                                else message = message + '<br/>' + i18n.tc('tasks.generated_checklist', date, { c: date })

                                if (preview == '') preview = i18n.tc('tasks.generated_checklist', date, { c: date })
                                else preview = preview + ', ' + i18n.tc('tasks.generated_checklist', date, { c: date })
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }
                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    // Scoring
                    if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date && item.apply) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }

                    if (item.planned_to_date) {
                        list.planned_items++
                    }
                })
                let divisor = parseInt(list.total + list.planned_items) == 0 ? 1 : parseInt(list.total + list.planned_items)
                list.score = Math.round((parseFloat(list.completed + list.planned_items) * 100) / divisor)

                if (past_score != -1 && past_score < 100 && list.score >= 100) {
                    dispatch('complete', { checklist: list.id, complete_date: moment().unix(), values: [] })
                    list.complete_date = moment()
                } else if (past_score != -1 && past_score >= 100 && list.score < 100) {
                    dispatch('uncomplete', { checklist: list.id, config: list.config })
                    list.complete_date = false
                }

                list.need_justification = false

                let limit_start = moment(list.limit.start)
                let limit_end = moment(list.limit.end)
                let schedule_start = moment(list.schedule.start)
                let schedule_end = moment(list.schedule.end)

                if (!now.isBetween(limit_start, limit_end) && parseInt(list.score) < 100 && now.isAfter(limit_end) && list.completed != list.total) {
                    list.need_justification = true
                    alert_pending_count[list.timeframe_id]++
                    if (list.category_id && aCategories[list.category_id]) {
                        c_alert_pending_count[list.category_id]++
                    }
                    if (aCategories['all']) {
                        c_alert_pending_count['all']++
                    }

                    if (!list.justify_id) {
                        alert_pending_show[list.timeframe_id] = true
                        if (list.category_id && aCategories[list.category_id]) {
                            c_alert_pending_show[list.category_id]++
                        }
                        if (aCategories['all']) {
                            c_alert_pending_show['all']++
                        }
                    }
                }

                if (now.isBetween(limit_start, limit_end) && !now.isBetween(schedule_start, schedule_end) && now.isAfter(schedule_end) && list.important && parseInt(list.score) < 100) {
                    alert_important_show[list.timeframe_id] = parseInt(list.score) < 100
                    if (list.category_id && aCategories[list.category_id]) {
                        c_alert_important_show[list.category_id]++
                    }
                    if (aCategories['all']) {
                        c_alert_important_show['all']++
                    }

                    list.important = parseInt(list.score) < 100
                }

                // SETTER
                aFrames[list.timeframe_id].completed += list.completed
                aFrames[list.timeframe_id].total += list.total

                // SETTER CATEGORIES
                if (list.category_id && aCategories[list.category_id]) {
                    aCategories[list.category_id].completed += list.completed
                    aCategories[list.category_id].total += list.total
                }
                if (aCategories['all']) {
                    aCategories['all'].completed += list.completed
                    aCategories['all'].total += list.total
                }

                aItems[list.id] = { ...list }
            } else if (list.timeframe_id == tframe_id) {
                aFrames[list.timeframe_id].completed += list.completed
                aFrames[list.timeframe_id].total += list.total

                if (list.category_id && aCategories[list.category_id]) {
                    aCategories[list.category_id].completed += list.completed
                    aCategories[list.category_id].total += list.total
                }
                if (aCategories['all']) {
                    aCategories['all'].completed += list.completed
                    aCategories['all'].total += list.total
                }
            }
        })

        // ORDER
        Object.keys(aItems).map(function (item_id, i) {
            let list = Object.assign({}, aItems[item_id])
            var items = Object.keys(list.items)
                .sort((a, b) => {
                    return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                })
                .reduce((prev, curr, i) => {
                    prev[i] = list.items[curr]
                    return prev
                }, {})

            let aux2 = {}
            for (var k in items) {
                aux2[items[k].item_id] = items[k]
            }

            list.items = aux2
            aItems[item_id] = list
        })

        // SETTER
        Object.keys(aFrames).map(function (item_id, i) {
            var tframe = aFrames[item_id]
            if (typeof id === 'undefined' || item_id == tframe_id) {
                tframe.alert_pending_count = alert_pending_count[tframe.id]
                tframe.alert_pending_show = alert_pending_show[tframe.id]
                tframe.alert_important_show = alert_important_show[tframe.id]

                tframe.score = parseInt(tframe.total) == 0 ? 0 : Math.floor((parseFloat(tframe.completed) * 100) / parseInt(tframe.total))
            }
        })

        // SETTER
        Object.keys(aCategories).map(function (item_id, i) {
            var category = aCategories[item_id]
            if (typeof id === 'undefined' || item_id == category_id || item_id == 'all') {
                category.alert_pending_count = c_alert_pending_count[category.id]
                category.alert_pending_show = c_alert_pending_show[category.id]
                category.alert_important_show = c_alert_important_show[category.id]

                category.score = parseInt(category.total) == 0 ? 0 : Math.floor((parseFloat(category.completed) * 100) / parseInt(category.total))
            }
        })

        commit('setScore', { items: aItems })
        commit('setTimeframes', { frames: aFrames })
        commit('setCategories', aCategories)
    },
    loadStats(context, params) {
        context.commit('resetStats', {})
        var now = moment()
        var currentDate = moment(params.date)
        var items = state.items
        var timeframes = state.timeframes.data

        // SET ALERTS --------------------------------------------------------------
        // --> Left important (urgentes)
        var left_important = {
            state: 'error',
            value: 0,
            name: '',
            route: { name: 'Checklist', params: { withAlert: 'alert_important_show' } }
        }

        Object.keys(items).map(function (item_id, i) {
            var item = items[item_id]
            var stillAvailable = false

            // Left important tasks
            var limit = item.limit
            var schedule = item.schedule

            if (currentDate.isBetween(limit.start, limit.end) && !currentDate.isBetween(schedule.start, schedule.end) && currentDate.isAfter(schedule.end) && currentDate.isSame(now, 'day')) {
                stillAvailable = true
            }
            if (!item.need_justification && item.important && stillAvailable) {
                left_important.value++
            }
        })
        if (left_important.value > 0) {
            left_important.name = i18n.tc('home.tasks.left_important', left_important.value)
            context.commit('setStats', {
                where: 'alerts',
                key_name: 'left_important',
                values: left_important
            })
        }

        // --> Left pending (unjustified)
        var left_pending = {
            state: 'warning',
            value: 0,
            name: '',
            route: { name: 'Checklist', params: { propDate: moment(currentDate).subtract(1, 'day'), withAlert: 'alert_pending_show' } }
        }

        api.post('checklist/stats/unjustified', { date: currentDate.format('YYYY-MM-DD') }).then(function (response) {
            left_pending.value = response.data ? response.data : 0
            if (left_pending.value > 0) {
                left_pending.name = i18n.tc('home.tasks.left_pending', left_pending.value)
                context.commit('setStats', {
                    where: 'alerts',
                    key_name: 'left_pending',
                    values: left_pending
                })
            }
        })

        // SET ACTIVITY ------------------------------------------------------------
        // -- Tasks done
        var tfCompleted = 0
        var tfTotal = 0
        var tfScore = 0

        Object.keys(timeframes).map(function (item_id, i) {
            var tf = timeframes[item_id]

            tf.score = isNaN(tf.score) ? 0 : tf.score
            tfCompleted += tf.completed
            tfTotal += tf.total
        })

        if (tfCompleted && tfTotal) {
            tfScore = Math.round((tfCompleted / tfTotal) * 100, 0)
        }
        context.commit('setStats', {
            where: 'activity',
            key_name: 'done',
            values: {
                name: i18n.t('home.activity.tasks_done'),
                value: tfScore + '%'
            }
        })
    },
    completeAll(context, params) {
        return api
            .post('checklist/answer', {
                checklist_id: params.check,
                employee: params.emp
            })
            .then(function (response) {
                var items = context.getters['getTasks'](params.check)

                Object.keys(items).map(function (id, i) {
                    var val = items[id]

                    if (val.type == 1 && !val.value && !val.parent_id) {
                        context.commit('setAnswer', {
                            check: params.check,
                            answer: val.item_id,
                            emp: params.emp,
                            date: params.date,
                            value: 1
                        })
                    }
                })
                context.dispatch('calcScore', params.check)

                return response
            })
    },
    resetAll(context, params) {
        return api
            .post('checklist/reset', {
                checklist_id: params.check,
                employee: params.emp
            })
            .then(function (response) {
                var items = context.getters['getTasks'](params.check)

                Object.keys(items).map(function (id, i) {
                    var val = items[id]

                    if (val.type == 1 && val.value && !val.parent_id) {
                        context.commit('setReset', {
                            check: params.check,
                            answer: val.item_id
                        })
                    }
                })
                context.dispatch('calcScore', params.check)

                return response
            })
    },
    checkDependencyTpl(context, params) {
        var result = false
        var templates = { ...state.items }
        Object.keys(templates).map(function (id, i) {
            var val = templates[id]
            if (val.template_id == params.tpl) {
                if (!val.complete_date) {
                    result = val
                }
            }
        })

        return result
    },
    recoverValues(context, params) {
        // TODO APPEND
        return api.post('checklist/recover/' + params.check, {}).then(function (response) {
            if (response.status) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', params.id)
            } else {
                router.go()
            }

            return response.data
        })
    },
    justify(context, params) {
        params.justify_date = moment().format('DD/MM/YYYY HH:mm:ss')
        console.log('justify', params)
        // TODO
        const canWorkOffline = context.rootGetters.getOffline
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/update', {
                    checklist: params.check,
                    reason: params.reason,
                    description: params.description
                })
                .then(function (response) {
                    context.commit('setJustified', {
                        check: params.check,
                        reason_id: params.reason,
                        reason_msg: params.description,
                        justify_user_id: params.justify_user_id,
                        justify_date: params.justify_date
                    })
                    context.dispatch('calcScore', params.check)

                    return response
                })
        } else {
            if (canWorkOffline) {
                const user = context.rootState.loginUser.local.user
                log('justify params', params)
                context.dispatch('syncAction', {
                    action: 'update',
                    checklist: params.check,
                    reason: params.reason,
                    description: params.description,
                    employee: user.id,
                    complete_date: params.justify_date,
                    timeframe_id: state.timeframes.current
                })

                context.commit('setJustified', {
                    check: params.check,
                    reason_id: params.reason,
                    reason_msg: params.description,
                    justify_user_id: user.id,
                    justify_date: params.justify_date
                })
                context.dispatch('calcScore', params.check)
                log('justify', state.timeframes.current)
                db_tasks.getItem(state.timeframes.current).then(function (item) {
                    log('item justify', item)
                    item.templates[params.check].justify_id = params.reason
                    item.templates[params.check].justify_msg = params.description
                    item.templates[params.check].justify_user_id = user.id
                    item.templates[params.check].justify_date = params.justify_date
                    db_tasks.setItem(state.timeframes.current, item)
                })
            } else {
            }
        }
    },
    async postpone({ rootState, commit, dispatch, getters }, params) {
        let loggeduser = rootState.loginUser.local.user

        commit('setPostposed', {
            check: params.check,
            answer: params.answer,
            date: params.date,
            reason_id: params.reason,
            reason_msg: params.description,
            employee: loggeduser.id
        })

        await dispatch('calcScore', params.check)

        let check = getters.getById(params.check)
        let total = check.total + check.planned_items
        let answered = check.completed + check.planned_items

        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/plan', {
                checklist: params.check,
                task: params.answer,
                date: params.date,
                reason: params.reason,
                description: params.description,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                return response
            })
    },
    updateIssue(context, params) {
        let item = context.getters['getTaskById'](params.check, params.answer)

        if (item) {
            let value = item.reference ? item.reference : false

            if (params.status) {
                value.status = params.status
            }
            if (params.toDelete) {
                value = false
            }

            context.commit('setAnswerProp', {
                check: params.check,
                answer: params.answer,
                prop: 'reference',
                value: value
            })
        }
    },
    syncAction(context, params) {
        params.queued_date = moment().valueOf()
        params.id = moment().valueOf()

        return context.dispatch('checklist/addTaskToQueue', params, { root: true }).then(() => {
            return { status: true }
        })
    },
    sync(context, queue) {
        let checklists = {}
        for (let key in queue) {
            let queueElement = queue[key]
            if (typeof checklists[queueElement.checklist] === 'undefined') {
                checklists[queueElement.checklist] = {
                    items: [],
                    resume: { it: 0, is: 0, a: 0, n: 0 },
                    total: 0,
                    answered: 0,
                    score: 0
                }
            }

            checklists[queueElement.checklist].resume = queueElement.resume
            checklists[queueElement.checklist].total = queueElement.total
            checklists[queueElement.checklist].answered = queueElement.answered
            checklists[queueElement.checklist].score = queueElement.score
            checklists[queueElement.checklist].items.push(queueElement)
        }
        return api
            .post(`checklist/${state.type}/sync`, { checklists: JSON.stringify(checklists) })
            .then(function (response) {
                if (response.data) {
                    const issues = response.data.filter((queue) => queue.issue_id)
                    if (issues.length > 0) {
                        context.dispatch('updateIssueOfflineDatabase', issues)
                        response.data = response.data.filter((queue) => !queue.issue_id)
                    }
                }
                return context
                    .dispatch('updateOfflineDatabase', response)
                    .then(() => {
                        return response
                    })
                    .catch(function (error) {
                        logError(error)
                        return false
                    })
            })
            .catch(function (error) {
                logError(error)
                return false
            })
    },
    async mergeOffline(context, params = {}) {
        await db_tasks_queue.iterate(function (answer, checklistId, iterationNumber) {
            const { timeframe_id, checklist, task } = answer
            log('mergeOffline - answer', answer)
            log('mergeOffline - params', params)
            if (typeof params.data[timeframe_id] !== 'undefined' && typeof params.data[timeframe_id].templates[checklist] !== 'undefined' && typeof params.data[timeframe_id].templates[checklist].items[task] !== 'undefined') {
                params.data[timeframe_id].templates[checklist].items[task].requireSync = true

                if (answer.action === 'answer' || answer.action === 'reset') {
                    params.data[timeframe_id].templates[checklist].items[task].employee = answer.employee
                    params.data[timeframe_id].templates[checklist].items[task].complete_date = answer.complete_date
                    params.data[timeframe_id].templates[checklist].items[task].value = FORMAT.PHOTO === task.type ? answer.value : answer.value.toString()
                }

                if (answer.action === 'validate') {
                    params.data[timeframe_id].templates[checklist].items[task].review = {
                        value: 1,
                        employee: context.rootGetters['employee/getEmployee'](answer.employee),
                        complete_date: moment(answer.queued_date)
                    }
                }

                if (answer.action === 'unvalidate') {
                    params.data[timeframe_id].templates[checklist].items[task].review = null
                }

                if (answer.action === 'comment' && typeof answer.file !== 'undefined' && answer.file) {
                    params.data[timeframe_id].templates[checklist].items[task].image = answer.file
                }
                if (answer.action === 'comment' && typeof answer.message !== 'undefined' && answer.message) {
                    params.data[timeframe_id].templates[checklist].items[task].message = answer.message
                }
            }
        })
        return params.data
    },
    async updateOfflineDatabase(context, response) {
        for (let index in response.data) {
            const { checklist, value, task, timeframe_id, template_id, type, image } = response.data[index]
            try {
                const offlineChecklists = await db_tasks.getItem(timeframe_id)

                if (type === 'answer') offlineChecklists.templates[checklist].items[task].value = value
                if (type === 'comment') offlineChecklists.templates[checklist].items[task].image = image
                //Para que no rompa la app
                if (timeframe_id) {
                    await db_tasks.setItem(timeframe_id, offlineChecklists)
                }
            } catch (error) {
                logError(error)
            }
        }
    },

    async updateIssueOfflineDatabase(context, response) {
        if (response) {
            const checklists = response
            log('>> updateIssueOfflineDatabase', checklists)
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await store.dispatch('issues/updateStateOfSync', checklist)

                log('>> updateOfflineDatabase issues', checklist)
                // Actualizar el id de sincronización de los registros
                if (checklist && checklist.checklist_temporal && checklist.checklist) {
                    const register = await db_issues.getItem(checklist.checklist_temporal)
                    if (register) {
                        register.id = checklist.checklist
                        register.issue_id = checklist.issue_id
                        await db_issues.removeItem(checklist.checklist_temporal)
                        await db_issues.setItem(checklist.checklist, register)
                    }
                    //change the key of the item and id
                    const copy = store.getters['issues/getAll']
                    log('>> updateOfflineDatabase issues store', copy)
                    copy[checklist.checklist_temporal].id = checklist.checklist
                    copy[checklist.checklist_temporal].requireSync = false
                    copy[checklist.checklist_temporal].issue_id = checklist.issue_id
                    copy[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const updateItem = { [checklist.checklist]: copy[checklist.checklist_temporal] }
                    delete copy[checklist.checklist_temporal]

                    const allItems = { ...updateItem, ...copy }
                    /* context.state.items = {...copy} */
                    await store.dispatch('issues/format', { data: allItems })

                    //update allItems
                    const newIssueItem = store.getters['issues/getAll'][copy.status]
                    newIssueItem[checklist.checklist_temporal].id = checklist.checklist
                    newIssueItem[checklist.checklist_temporal].requireSync = false
                    newIssueItem[checklist.checklist_temporal].issue_id = checklist.issue_id
                    newIssueItem[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const newAllItems = { ...newIssueItem }
                    store.commit('issues/setAllItems', { items: newAllItems, type: copy.status })
                }
            })
        }
    },
    async clearOfflineQueue(context, params) {
        log('clearOfflineQueue', params)
        const data = params.data
        const action = params.action
        //['answer', 'reset', 'validate', 'unvalidate', 'complete', 'uncomplete']
        const mapActionsToRemove = {
            answer: ['answer', 'reset'],
            reset: ['answer', 'reset'],
            validate: ['validate', 'unvalidate'],
            unvalidate: ['validate', 'unvalidate'],
            comment: ['comment']
        }

        const actionsToRemove = mapActionsToRemove[action]

        await db_tasks_queue.iterate(async function (answer, key, iterationNumber) {
            const isTheSameChecklist = answer.checklist === data.checklist && answer.task === data.task
            let isGalleryItem = false
            if (data.type === FORMAT.PHOTO) {
                const dataArr = JSON.parse(data.value)
                isGalleryItem = dataArr.length > 1
            }
            if (actionsToRemove.includes(answer.action) && isTheSameChecklist && !isGalleryItem) {
                await db_tasks_queue.removeItem(key)
            }
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    // Commons
    setDate(state, date) {
        Vue.set(state, 'date', date)
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value

        state.items[check].items[answer][prop] = value
        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let aux = state.items[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false
        aux.requireSync = payload.requireSync

        Vue.set(state.items[check].items, answer, { ...aux })
        Vue.set(state, 'items', { ...state.items })
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.items[check].items[answer].value = value
        state.items[check].items[answer].requireSync = payload.requireSync

        if (date) {
            var item = state.items[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.items[check].items[answer].employee = emp

        Vue.set(state, 'items', { ...state.items })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.items[check].items[answer].apply = apply
        state.items[check].items[answer].requireSync = payload.requireSync

        if (date) {
            state.items[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
            state.items[check].items[answer].value = null
        } else {
            state.items[check].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer
        const sync = payload.requireSync ? payload.requireSync : false

        state.items[check].items[answer].complete_date = false
        state.items[check].items[answer].employee = false
        state.items[check].items[answer].review = false
        state.items[check].items[answer].value = null
        state.items[check].items[answer].requireSync = sync

        Vue.set(state, 'items', { ...state.items })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.items[check].items[answer].review = {}
        state.items[check].items[answer].requireSync = payload.requireSync

        if (date) {
            var item = state.items[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].items[answer].review.employee = employee
        }
        state.items[check].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setPostposed(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var date = payload.date
        var reason_id = payload.reason_id
        var reason_msg = payload.reason_msg
        let employee = payload.employee

        // todo set employee

        state.items[check].items[answer].planned_reason_id = reason_id
        state.items[check].items[answer].planned_reason_msg = reason_msg
        state.items[check].items[answer].planned_to_date = date
        state.items[check].items[answer].employee = employee

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].review = false
        state.items[check].items[answer].requireSync = payload.requireSync

        Vue.set(state, 'items', { ...state.items })
    },
    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        state.stats[payload.where] = { ...state.stats[payload.where] }
        state.stats[payload.where][payload.key_name] = { ...payload.values }
    },
    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    // Tasks
    setCurrentTimeframe(state, value) {
        state.timeframes.current = value
    },
    setJustified(state, payload) {
        var check = payload.check
        var reason_id = payload.reason_id
        var reason_msg = payload.reason_msg
        var justify_user_id = payload.justify_user_id
        var justify_date = payload.justify_date

        state.items[check].justify_id = reason_id
        state.items[check].justify_msg = reason_msg
        state.items[check].justify_user_id = justify_user_id
        state.items[check].justify_date = justify_date

        Vue.set(state, 'items', { ...state.items })
    },
    setTimeframes(state, payload) {
        log(payload, ' -----------')
        state.timeframes.data = payload.frames
        Vue.set(state.timeframes, 'data', { ...payload.frames })
    },
    setCurrentDate(state, date) {
        state.user_date_changed = true
        Vue.set(state, 'current_date', date)
    },
    setCategories(state, payload) {
        var categories = payload
        state.categories = { ...categories }
        Vue.set(state, 'categories', { ...categories })
    },
    setItem(state, payload) {
        var item = payload
        state.items[item.id] = { ...item }
        Vue.set(state, 'items', { ...state.items })
    },
    setRequireSync(state, payload) {
        let check = payload.check
        let answer = payload.answer

        if (answer && typeof state.items[check] !== 'undefined' && typeof state.items[check].items[answer] !== 'undefined') {
            state.items[check].items[answer].requireSync = payload.requireSync
            state.items[check].items[answer] = { ...state.items[check].items[answer] }
            Vue.set(state, 'items', { ...state.items })
        }
    }
}

function loadTemplatesOffline(commit, dispatch) {
    let items = {}
    let count = 0
    return db_tasks
        .iterate(function (value, key, iterationNumber) {
            log('ITERATE', value, key, iterationNumber)
            if (value && (value.id || value.id === 0)) {
                items[value.id] = value
                count++
            }
        })
        .then(function () {
            if (count > 0) {
                let date = moment().format('YYYY-MM-DD')
                commit('setDate', date)
                dispatch('mergeOffline', { data: items }).then((response) => {
                    dispatch('format', { data: response })
                    dispatch('calcScore')
                })
            }
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
