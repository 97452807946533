import { Api } from '@/core/api.js'
const api = new Api()

import localforage from 'localforage'
const db_notification = localforage.createInstance({ name: 'alexdb', storeName: 'notification' })
const db_event = localforage.createInstance({ name: 'alexdb', storeName: 'event' })

const getDefaultState = () => {
    return {
        firebase_token: localStorage.getItem('firebase_token') || null,
        topics: localStorage.getItem('topics') ? JSON.parse(localStorage.topics) : []
    }
}

// initial state
const state = getDefaultState()

const getters = {
    getFireBaseToken(state, rootState) {
        return state.firebase_token
    },

    getTopics(state, rootState) {
        return state.topics
    }
}

const actions = {
    async addEvent(context, payload) {
        await db_notification.removeItem(payload.id)
        db_event.setItem(payload.id, payload)
    }

    // addNotification(context, payload) {
    //     db_notification.setItem(payload.id, payload)
    // }
}

const mutations = {
    setFirebaseToken(state, payload) {
        state.firebase_token = payload
        localStorage.setItem('firebase_token', payload)
    },

    setSubscribeTopic(state, payload) {
        let topics = state.topics
        topics.push(payload)

        state.topics = topics
        localStorage.setItem('topics', JSON.stringify(topics))
    },

    setUnsubscribeTopic(state, payload) {
        let topics = state.topics
        if (topics.includes(payload)) {
            topics.splice(topics.indexOf(payload), 1)
        }

        state.topics = topics
        localStorage.setItem('topics', JSON.stringify(topics))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
