<template>
    <div id="app">
        <template v-if="barcodeActive === true && userLogged">
            <div class="scanner">
                <!-- <div class="scan-box "></div> -->
                <div class="mask top"></div>
                <div class="mask left"></div>
                <div class="mask right">
                    {{ $t('label.barcode.camera_center') }}
                </div>
                <div class="mask bottom"></div>
                <div class="buttons">
                    <div class="button cancel" @click="destroyBarcode()"></div>
                    <div class="button change" @click="swapCamera()"></div>
                    <div class="button flash" :class="{ bounce: flashlight }" @click="switchLight()"></div>
                </div>
            </div>
        </template>
        <template v-else-if="cameraActive === true && userLogged">
            <div class="camera">
                <video autoplay :poster="imgSplash"></video>
                <div class="buttons">
                    <div id="cancel-camera" class="button cancel"></div>
                    <div id="take-camera" class="button take"></div>
                    <div id="flash-camera" class="button flash"></div>
                    <div id="swap-camera" class="button change"></div>
                </div>
            </div>
        </template>
        <div :class="{ barcode: barcodeActive === true || cameraActive === true }">
            <template v-if="layout == 'default-layout'">
                <DefaultLayout>
                    <router-view />
                </DefaultLayout>
            </template>
            <template v-if="layout == 'login-layout'">
                <LoginLayout>
                    <router-view />
                </LoginLayout>
            </template>
            <template v-if="layout == 'offline-layout'">
                <OfflineLayout>
                    <router-view />
                </OfflineLayout>
            </template>
            <template v-if="layout == 'auditor-layout'">
                <AuditorLayout>
                    <router-view />
                </AuditorLayout>
            </template>
        </div>
    </div>
</template>

<script>
import { Db } from '@/core/db.js'

import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from './router'
const default_layout = 'default'

const DefaultLayout = () => import('./layouts/Default-Layout.vue')
const LoginLayout = () => import('./layouts/Login-Layout.vue')
const OfflineLayout = () => import('./layouts/Offline-Layout.vue')
const AuditorLayout = () => import('./layouts/Auditor-Layout.vue')

Vue.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GoogleAnaylticsKey },
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_title: to.name,
                page_path: to.path
            }
        }
    },
    router
)

export default {
    components: {
        DefaultLayout,
        LoginLayout,
        OfflineLayout,
        AuditorLayout
    },
    data() {
        return {
            flashlight: false
        }
    },
    computed: {
        layout() {
            var layout = typeof this.$route.meta.layout !== 'undefined' ? this.$route.meta.layout : default_layout
            if (this.isAuditorLogin) {
                layout = 'auditor'
            }
            return layout + '-layout'
        },
        isAuditorLogin(){
            return (this.$store.getters.isAuditorLogin || this.$store.getters['loginUser/getAuditor']) && this.$route.name !== 'Login'
        },
        barcodeActive() {
            return this.$store.getters['getBarcodeActive']
        },

        cameraActive() {
            return this.$store.getters['getCameraActive']
        },

        userLogged() {
            var userLogged = this.$store.getters['loginUser/getLocalEmployee']
            if (!userLogged) {
                // this.destroyBarcode()
            }
            return userLogged
        },

        imgSplash() {
            return css_image_url + 'alexhead_06.svg'
        }
    },
    methods: {
        // BARCODE AUTO SCANNER METHODS
        switchLight(enable = true) {
            if (this.flashlight || !enable) {
                QRScanner.disableLight(function (err, status) {
                    log(status)
                })
            } else {
                QRScanner.enableLight(function (err, status) {
                    log(status)
                })
            }
            this.flashlight = !this.flashlight
        },

        swapCamera() {
            var self = this
            var camera = 0
            QRScanner.getStatus((status) => {
                camera = status && parseInt(status.currentCamera) == 1 ? 0 : 1
                if (camera == 1 && !status.canChangeCamera) camera = 0

                QRScanner.useCamera(camera, function (err, status) {
                    if (err) {
                        log(err)
                        log(status)
                    } else {
                        self.$store.commit('setCurrentCamera', camera)
                    }
                })
            })
        },

        destroyBarcode() {
            var self = this
            this.$store.commit('setBarcodeActive', false)
            this.switchLight(false)
            QRScanner.destroy(function (status) {
                log(status)
            })
        },

        cancelCamera() {
            this.$store.commit('setCameraActive', false)
        }
    },
    created() {
        // window.deferredPrompt = {}
        // let deferredPrompt
        // window.addEventListener('beforeinstallprompt', (e) => {
        //   // Prevent the mini-infobar from appearing on mobile
        //   e.preventDefault()
        //   window.deferredPrompt = e
        //   // Stash the event so it can be triggered later.
        //   deferredPrompt = e
        //   // Update UI notify the user they can install the PWA
        //   showInstallPromotion()
        // })

        const db = new Db()
        db.init()
    }
}
</script>

<style lang="scss">
@import '@/../public/css/variables.scss';
// @import '@/../public/css/vuetify.scss';
@import '@/../public/css/assets/_animations.scss';
@import '@/../public/css/_reset.scss';
@import '@/../public/css/_default.scss';
@import '@/../public/css/_fonts.scss';
@import '@/../public/css/style.scss';
@import '@/../public/css/assets/_pdf.scss';

#app {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;

    > div {
        height: 100%;
    }
    .scanner,
    .camera {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999999999999999999;
        // background-color: rgba(3, 3, 3, 0.2);
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-image: url('https://www.elnacional.cat/uploads/s1/76/58/29/3/alberto-chicote-instagram.jpeg');

        .mask {
            background-color: rgba(3, 3, 3, 0.6);
            position: fixed;
            z-index: 1;

            &.top,
            &.bottom {
                height: calc(50% - 100px);
                width: 100%;
            }

            &.left,
            &.right {
                top: calc(50% - 100px);
                width: calc(35% - 200px);
                // width: calc(50% - 300px);
                height: 200px;
            }

            &.top {
                top: 0;
                &:before {
                    content: '';
                    position: absolute;
                    left: calc(35% - 200px - 2px);
                    bottom: 0;
                    height: 1px;
                    width: 20px; /* or 100px */
                    border-bottom: 3px solid #fff;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: calc(35% + 2px - 20px);
                    bottom: 0;
                    height: 1px;
                    width: 20px; /* or 100px */
                    border-bottom: 3px solid #fff;
                }
            }

            &.bottom {
                bottom: 0;

                &:before {
                    content: '';
                    position: absolute;
                    left: calc(35% - 200px - 2px);
                    top: -2px;
                    height: 1px;
                    width: 20px; /* or 100px */
                    border-top: 3px solid #fff;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: calc(35% + 2px - 20px);
                    top: -2px;
                    height: 1px;
                    width: 20px; /* or 100px */
                    border-top: 3px solid #fff;
                }
            }

            &.left {
                left: 0;
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: -2px;
                    height: 20px;
                    width: 0px; /* or 100px */
                    border-right: 3px solid #fff;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    height: 20px;
                    width: 0px; /* or 100px */
                    border-right: 3px solid #fff;
                }
            }

            &.right {
                @include background($image: img('arrow.svg'), $position: bottom center, $size: 80px);
                @include display-flex();
                @include justify-content(center);
                @include align-items(flex-start);
                right: 0;
                width: 65%;
                font-size: 18px;
                font-family: $text-bold;
                padding: 30px 10%;
                color: #fff;
                line-height: 25px;
                text-align: center;

                &:before {
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: -2px;
                    height: 20px;
                    width: 0px; /* or 100px */
                    border-right: 3px solid #fff;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: -1px;
                    bottom: -2px;
                    height: 22px;
                    width: 0px; /* or 100px */
                    border-right: 3px solid #fff;
                }
            }
        }

        .scan-box {
            // transform: rotate(90deg);
            border-radius: 4px;
            background-color: transparent;
            width: 500px;
            height: 2px;
            background-color: red;
            top: calc(50% - 1px);
            left: calc(35% - 250px);
            position: fixed;
            z-index: 2;
            // -webkit-animation-direction: normal;
            // animation-direction: normal;
            // animation: colorchange 2s infinite;

            // &:after {
            //     content: '';
            //     position: absolute;
            //     z-index: -1;
            //     top: 0;
            //     bottom: 0;
            //     left: 50%;
            //     border-left: 2px solid #ff0000;
            //     transform: translate(-50%);
            //     // transform: rotate(90deg);
            // }
        }
        .buttons {
            @include display-flex();
            @include justify-content();
            position: absolute;
            bottom: 10px;
            width: 100%;
            // background-color: rgba(3, 3, 3, 1);

            // @include flex-direction(column);

            .button {
                @include border-radius(100%);
                @include font-size('m');
                @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.25));
                font-family: $text;
                // position: absolute;
                // min-width: 200px;
                min-height: 60px;
                min-width: 60px;
                bottom: 0px;
                padding: 20px 20px;
                margin: 30px;
                // background-color: #fff;
                text-align: center;
                cursor: pointer;
                max-width: calc(50% - 30px);
                z-index: 3;
                display: inline-block;
                margin: 10px;

                &.change {
                    left: 0;
                    @include background($image: img('change_camera.svg'), $size: 24px, $position: center center);
                    // margin-right: 0;
                }

                &.cancel {
                    @include background($image: img('cancel_camera.svg'), $size: 22px, $position: center center);
                    right: 0px;
                    // margin-left: 0;
                }

                &.take {
                    @include background($image: img('take_camera.svg'), $position: center center);
                    right: 0px;
                    width: 80px;
                    height: 80px;
                    margin-left: 15%;
                }

                &.flash {
                    @include background($image: img('flash_camera.svg'), $size: 24px, $position: center center);
                    right: 0px;
                    // margin-left: 0;

                    &.active {
                        rotate: 180deg;
                    }
                }
            }
        }

        video {
            width: 100%;
            height: 100%;
            background-color: #242020;
        }
    }

    .barcode {
        opacity: 0;
    }

    @keyframes colorchange {
        0%,
        100% {
            background-color: #f35;
            width: 500px;
            left: calc(50% - 250px);
        }
        50% {
            background-color: #893131;
            width: 550px;
            left: calc(50% - 275px);
        }
    }
}

@media screen and (max-width: 700px) {
    .scanner {
        .mask {
            &.top,
            &.bottom {
                height: calc(50% - 75px) !important;
                width: 100%;
            }

            &.left,
            &.right {
                top: calc(50% - 75px) !important;
                width: calc(50% - 200px) !important;
                height: 150px !important;
            }

            &.top {
                top: 0;
                &:before {
                    left: calc(35% - 150px - 2px) !important;
                }
            }

            &.bottom {
                bottom: 0;

                &:before {
                    left: calc(35% - 150px - 2px) !important;
                }
            }

            &.left {
                left: 0;
                width: calc(35% - 150px) !important;
            }

            &.right {
                padding: 10px !important;
                width: 65% !important;
                right: 0;
                line-height: 22px !important;
            }
        }

        .scan-box {
            width: 250px;
        }
    }

    @keyframes colorchange {
        0%,
        100% {
            background-color: #f35;
            width: 200px;
            left: calc(50% - 100px);
        }
        50% {
            background-color: #893131;
            width: 250px;
            left: calc(50% - 125px);
        }
    }
}

@media screen and (max-width: 450px) {
    .scanner {
        .mask {
            &.top,
            &.bottom {
                height: calc(50% - 75px) !important;
                width: 100%;
            }

            &.left,
            &.right {
                top: calc(50% - 75px) !important;
                // width: calc(50px) !important;
                width: calc(50px) !important;
                // width: calc(50% - 150px) !important;
                height: 150px !important;
            }

            &.top {
                top: 0;

                &:before {
                    left: calc(50px - 2px) !important;
                }
                &:after {
                    left: calc(182px) !important;
                }
            }

            &.bottom {
                bottom: 0;

                &:before {
                    left: calc(50px - 2px) !important;
                }

                &:after {
                    left: calc(182px) !important;
                }
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
                width: calc(100% - 50px - 150px) !important;
                padding: 0 10px !important;
            }
        }

        .scan-box {
            width: 250px;
        }
    }

    @keyframes colorchange {
        0%,
        100% {
            background-color: #f35;
            width: 200px;
            left: calc(50% - 100px);
        }
        50% {
            background-color: #893131;
            width: 250px;
            left: calc(50% - 125px);
        }
    }
}
</style>
