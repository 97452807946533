import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { TYPE, TYPE_STORE, FORMAT } from '@/constants'
import { Api } from '@/core/api.js'
// import localforage from 'localforage'
import { db_tasks_queue, db_audits_queue, db_issues_queue, db_registers_queue, db_templates } from '@/database'

const api = new Api()

const mapChecklist = {}
mapChecklist[TYPE.TASK] = TYPE_STORE.TASK
mapChecklist[TYPE.AUDIT] = TYPE_STORE.AUDIT
mapChecklist[TYPE.RECORD] = TYPE_STORE.RECORD
mapChecklist[TYPE.ISSUE] = TYPE_STORE.ISSUE

// const db_tasks_queue = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'tasks_queue'
// })

// const db_audits_queue = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'audits_queue'
// })

// const db_registers_queue = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'registers_queue'
// })

// const db_issues_queue = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'issues_queue'
// })

// const db_templates = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'templates'
// })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        // 1: Tareas, 2: Inspecciones, 3: Registros, 4: Incidencias, 5: Formacion
        checklist: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        templates: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        cIntime: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        cPast: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        register: {},
        defaults: {
            default: {
                date_format: 'YYYY-MM-DD',
                hour_format: 'HH:mm',
                datetime_format: 'YYYY-MM-DD HH:mm',
                has_score: false,
                has_state: false,
                has_timing: false,
                has_mandatory: false,
                is_autosave: true,
                can_create: true,
                item_search: false,
                has_calendar: true,
                temporal_view: false,
                close_when_completed: false,
                auth_users: {
                    pin: 2,
                    review: 2,
                    task: 1
                },
                can_edit: {
                    title: true,
                    state: false,
                    inPast: true,
                    inTime: true,
                    inFuture: true
                },
                item: {
                    itemsTitle: [8, 9],
                    itemsExcluded: [8, 9, 18, 19],
                    count_subtask: false, // Esto por si se quieren poder puntuar tambien las subtareas
                    rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                    has_periodicity: false
                }
            },
            1: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_create: false,
                can_edit: { inPast: false },
                item: { has_periodicity: true }
            },
            2: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_edit: { inPast: false, inFuture: false },
                item: { rate: true },
                close_when_completed: true,
                temporal_view: true
            },
            3: {
                is_autosave: false,
                can_edit: { title: false }
            },
            4: {
                has_state: true,
                can_edit: { state: true }
            },
            5: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_edit: { inPast: false },
                item_search: true,
                has_calendar: false,
                temporal_view: true
            }
        },
        task_queue: {},
        tq_active: false,
        audit_queue: {},
        aq_active: false,
        num_max_tq_batch: 5,
        register_queue: {},
        rq_active: false,
        issues_queue: {},
        iq_active: false,
        showNoApply: localStorage.getItem('showNonApply') === null || localStorage.getItem('showNonApply') == 'true' ? true : false
    }
}

// initial state
const state = getDefaultState()

const loadLocalForageInformation = async () => {
    state.task_queue = []
    state.audit_queue = []
    state.register_queue = []
    state.issues_queue = []

    let loadTasksPromise = new Promise((resolve, reject) => {
        db_tasks_queue
            .iterate(function (value, key, iterationNumber) {
                state.task_queue[key] = value
            })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                logError(err)
                reject()
            })
    })

    let loadAuditsPromise = new Promise((resolve, reject) => {
        db_audits_queue
            .iterate(function (value, key, iterationNumber) {
                state.audit_queue[key] = value
            })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                logError(err)
                reject()
            })
    })

    let loadRegisterPromise = new Promise((resolve, reject) => {
        db_registers_queue
            .iterate(function (value, key, iterationNumber) {
                state.register_queue[key] = value
            })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                logError(err)
                reject()
            })
    })

    let loadIssuesPromise = new Promise((resolve, reject) => {
        db_issues_queue
            .iterate(function (value, key, iterationNumber) {
                state.issues_queue[key] = value
            })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                logError(err)
                reject()
            })
    })

    return Promise.all([loadTasksPromise, loadAuditsPromise, loadRegisterPromise, loadIssuesPromise])
}

loadLocalForageInformation()

// getters
const getters = {
    getAllChecklist: (state, getters, rootState) => (cType) => {
        return state.checklist[cType]
    },
    getDefaults: (state, getters, rootState) => (cType) => {
        var def = {}
        def = { ...state.defaults['default'], ...state.defaults[cType] }
        def.item = {
            ...state.defaults['default'].item,
            ...state.defaults[cType].item
        }
        def.auth_users = {
            ...state.defaults['default'].auth_users,
            ...state.defaults[cType].auth_users
        }
        def.can_edit = {
            ...state.defaults['default'].can_edit,
            ...state.defaults[cType].can_edit
        }

        return def
    },
    getShowNoApply: (state, getters, rootState) => {
        return state.showNoApply
    },
    getTemplates: (state, getters, rootState) => (cType) => {
        return state.templates[cType]
    },
    getChecklist: (state, getters, rootState) => (cType, id) => {
        return state.checklist[cType][id]
    },
    getRegister: (state, getters, rootState) => {
        return state.register
    },
    getAnswer: (state, getters, rootState) => (cType, check, answer) => {
        return state.checklist[cType][check].items[answer]
    },
    getIntimeChecklist: (state, getters, rootState) => (cType) => {
        return state.cIntime[cType]
    },
    getPastChecklist: (state, getters, rootState) => (cType) => {
        return state.cPast[cType]
    },
    getChecklistProp: (state, getters, rootState) => (cType, check, prop) => {
        return state.checklist[cType][check][prop]
    },
    getAnswerProp: (state, getters, rootState) => (cType, check, answer, prop) => {
        return state.checklist[cType][check].items[answer][prop]
    },
    getTaskQueue: (state, getters, rootState) => {
        return state.task_queue
    },
    getTaskQueueActive: (state, getters, rootState) => {
        return state.tq_active
    },
    getAuditQueue: (state, getters, rootState) => {
        return state.audit_queue
    },
    getAuditQueueActive: (state, getters, rootState) => {
        return state.aq_active
    },
    getRegisterQueue: (state, getters, rootState) => {
        return state.register_queue
    },
    getRegisterQueueActive: (state, getters, rootState) => {
        return state.rq_active
    },
    getIssuesQueue: (state, getters, rootState) => {
        return state.issues_queue
    },
    getIssuesQueueActive: (state, getters, rootState) => {
        return state.iq_active
    }
}

const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    loadChecklist(context, params) {
        return api
            .get('checklist/' + params.cType + '/' + params.date, {})
            .then(function (response) {
                context.commit('formatChecklist', {
                    data: response.data,
                    cType: params.cType
                })
            })
            .catch((error) => {
                logError(error)
                return false
            })
    },
    loadIssues(context, params) {
        var cType = 4
        var parameters = {}
        if (params.status) {
            parameters.status = params.status
        }
        return api.post('issues/' + params.state, parameters).then(function (response) {
            context.commit('formatChecklist', { data: response.data, cType: cType })
        })
    },
    loadSingleChecklist(context, params) {
        var cType = params.cType

        return api.get('checklist/id/' + params.id, {}).then(function (response) {
            context.commit('formatChecklist', { data: response.data, cType: cType })
        })
    },
    loadSingleChecklistInfo(context, params) {
        var cType = params.cType
        return api.get('checklist/id/' + params.id, {})
    },
    loadTemplates(context, params) {
        if (!fnCheckConnection()) {
            return loadTemplatesOffline(context)
        }

        return api
            .get('checklist/templates/1', {})
            .then(function (response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function (value, key) {
                    db_templates.setItem(value.id, JSON.stringify(value))
                })
            })
            .catch((error) => {
                logError(error)
                loadTemplatesOffline(context)
            })
    },
    loadRegister(context, params) {
        var cType = 3

        return api.get('checklist/resume/' + params.template, {}).then(function (response) {
            context.commit('formatChecklist', {
                data: response.data.values,
                cType: cType
            })
            context.commit('formatRegister', { data: response.data })
        })
    },
    addAnswerComment(context, params) {
        var cType = params.cType
        var sended = {
            checklist: params.check,
            task: params.answer,
            message: params.message
        }
        if (typeof params.file !== 'undefined') sended.file = params.file
        return api.post('checklist/task/comment', sended).then(function (response) {
            var returned = {
                check: params.check,
                answer: params.answer,
                message: response.data.message,
                cType: cType
            }
            if (typeof response.data.image !== 'undefined') {
                returned.image = response.data.image
            }

            context.commit('setAnswerComment', returned)
        })
    },
    addAnswer(context, params) {
        var cType = params.cType
        let auxValue = params.value

        if ([5, 14].includes(params.answer_type)) {
            auxValue = JSON.stringify(auxValue)
        }

        return api
            .post('checklist/task/answer', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                value: auxValue
            })
            .then(function (response) {
                context.commit('setAnswer', {
                    cType: cType,
                    check: params.check,
                    answer: params.answer,
                    emp: params.emp,
                    date: params.date,
                    value: params.value
                })
            })
    },
    resetAnswer(context, params) {
        var cType = params.cType
        return api
            .post('checklist/task/reset', {
                checklist: params.check,
                task: params.answer
            })
            .then(function (response) {
                context.commit('setReset', {
                    cType: cType,
                    check: params.check,
                    answer: params.answer
                })
            })
    },
    validateAnswer(context, params) {
        var cType = params.cType

        return api
            .post('checklist/task/validate', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date
            })
            .then(function (response) {
                context.commit('setValidation', {
                    cType: cType,
                    check: params.check,
                    answer: params.answer,
                    emp: params.emp,
                    date: params.date
                })
            })
    },
    unvalidateAnswer(context, params) {
        var cType = params.cType
        return api
            .post('checklist/task/unvalidate', {
                checklist: params.check,
                task: params.answer
            })
            .then(function (response) {
                context.commit('setUnvalidation', {
                    cType: cType,
                    check: params.check,
                    answer: params.answer
                })
            })
    },
    planAnswer(context, params) {
        var cType = params.cType
        return api
            .post('checklist/task/plan', {
                checklist: params.check,
                task: params.answer,
                date: params.date,
                reason: params.reason,
                description: params.description
            })
            .then(function (response) {
                context.commit('setValidation', {
                    cType: cType,
                    check: params.check,
                    answer: params.answer,
                    date: params.date
                })
                return response
            })
    },
    addChecklist(context, params) {
        var cType = params.cType
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        return api.post('checklist/add', oData).then(function (response) {
            context.commit('formatChecklist', {
                data: response.data,
                append: true,
                cType: cType
            })
            return response.data[Object.keys(response.data)[0]]
        })
    },
    complete(context, params) {
        var cType = params.cType

        return api
            .post('checklist/complete', {
                checklist: params.check,
                employee: params.emp,
                complete_date: params.date,
                values: params.values
            })
            .then(function (response) {
                params.values = JSON.parse(params.values)
                Object.keys(params.values).map(function (id, i) {
                    var val = params.values[id]
                    context.commit('setAnswer', {
                        cType: cType,
                        check: params.check,
                        answer: val.item_id,
                        emp: params.emp,
                        date: params.date,
                        value: val.value
                    })
                })

                return response
            })
    },
    update(context, params) {
        var oData = { checklist: params.check }
        if (params.title) oData.title = params.title
        if (params.status) oData.status = params.status
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        var cType = params.cType

        return api.post('checklist/update', oData).then(function (response) {
            if (params.title) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'title',
                    value: oData.title,
                    cType: cType
                })
            }
            if (params.status) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'status',
                    value: oData.status,
                    cType: cType
                })
            }
            if (params.emp_ref) {
                context.commit('setChecklistProp', {
                    check: oData.checklist,
                    prop: 'employee_ref',
                    value: oData.employee_ref,
                    cType: cType
                })
            }
        })
    },
    reloadChecklist() {
        Object.keys(state.checklist).map(function (cType, i) {
            var checklist = state.checklist[cType]

            if (cType == 1 || cType == 2 || cType == 5) {
                store.commit('checklist/formatChecklist', {
                    data: checklist,
                    cType: cType
                })
            }
        })
        // store.commit('checklist/formatChecklist', { data: state.checklist })
    },
    addTaskToQueue(context, answer) {
        const account_id = context.rootGetters['loginUser/getLocalAccount']
        const location_id = context.rootGetters['loginUser/getLocalLocation'].id

        answer.account_id = account_id
        answer.location_id = location_id

        context.commit('addTaskToQueue', answer)
        log('addTaskToQueue', answer, context.getters.getTaskQueueActive)
        context.commit('setSyncFlag', true, { root: true }) // activate sync flag becouse at least there is one answer to sync
        if (!context.getters.getTaskQueueActive) {
            return context.dispatch('startTaskQueue')
        }
    },
    addAuditToQueue(context, answer) {
        const account_id = context.rootGetters['loginUser/getLocalAccount']
        const location_id = context.rootGetters['loginUser/getLocalLocation'].id

        answer.account_id = account_id
        answer.location_id = location_id

        context.commit('addAuditToQueue', answer)
        log('addAuditToQueue', answer, context.getters.getAuditQueueActive)
        context.commit('setSyncFlag', true, { root: true }) // activate sync flag becouse at least there is one answer to sync
        if (!context.getters.getAuditQueueActive) {
            return context.dispatch('startAuditQueue')
        }
    },
    addRegisterToQueue(context, answer) {
        const account_id = context.rootGetters['loginUser/getLocalAccount']
        const location_id = context.rootGetters['loginUser/getLocalLocation'].id

        answer.account_id = account_id
        answer.location_id = location_id
        context.commit('addRegisterToQueue', answer)
        log('addRegisterToQueue', answer, context.getters.getRegisterQueueActive)
        context.commit('setSyncFlag', true, { root: true }) // activate sync flag becouse at least there is one answer to sync
        if (!context.getters.getRegisterQueueActive) {
            return context.dispatch('startRegisterQueue')
        }
    },
    addIssueToQueue(context, answer) {
        const account_id = context.rootGetters['loginUser/getLocalAccount']
        const location_id = context.rootGetters['loginUser/getLocalLocation'].id

        answer.account_id = account_id
        answer.location_id = location_id

        context.commit('addIssueToQueue', answer)
        log('addIssueToQueue', answer, context.getters.getIssuesQueueActive)
        context.commit('setSyncFlag', true, { root: true }) // activate sync flag becouse at least there is one answer to sync
        if (!context.getters.getIssuesQueueActive) {
            return context.dispatch('startIssuesQueue')
        }
    },
    startTaskQueue(context) {
        log('startTaskQueue - Validating connection...')
        return new Promise((resolve, reject) => {
            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                log('startTaskQueue - Connection correct start queue')

                let tasks = Object.values(context.getters.getTaskQueue)
                tasks.sort((t1, t2) => {
                    return t1.queued_date < t2.queued_date ? -1 : 1
                })
                log(tasks)
                const queue = { data: tasks, type: 'tasks' }
                context.commit('setStatusTasksQueue', true)
                context
                    .dispatch('commonProceedQueue', queue)
                    .then(() => {
                        log('Task queue finished')
                        context.commit('setStatusTasksQueue', false)
                        resolve()
                    })
                    .catch((error) => {
                        logError(error)
                        context.commit('setStatusTasksQueue', false)
                        reject(error)
                    })
            } else {
                reject()
            }
        })
    },
    commonProceedQueue(context, queue) {
        const queueType = queue.type //Tipo de cola que se va a procesar tasks, audit, register, issue
        const queueItems = queue.data //Tareas que se van a procesar
        const queueTypeCapitalized = queueType.charAt(0).toUpperCase() + queueType.slice(1) //Para utilizar los nombres de las funciones de cada herramienta

        log(`proceed${queueType}Queue INIT`)
        log(`proceed${queueType}Queue length: ${queueItems.length}`)
        return new Promise(async (resolve, reject) => {
            log(`proceed${queueType}Queue INIT`)
            if (queueItems.length > 0) {
                let aQueue = []
                let index = 0
                //while (index will be < than tasks.length and index < than state.num_max_tq_batch) or no photo element found
                while (index < queueItems.length && index < state.num_max_tq_batch) {
                    const queueElement = queueItems[index]
                    if (queueElement.type != FORMAT.PHOTO) {
                        aQueue.push(queueElement)
                    } else if (queueElement.type == FORMAT.PHOTO && index == 0) {
                        //break while loop
                        aQueue.push(queueElement)
                        index++
                        break
                    } else {
                        break
                    }
                    index++
                }
                log(`${queueType} QUEUE ORDERED`, JSON.parse(JSON.stringify(aQueue)))
                log(`${queueType} QUEUE INDEX`, index)
                return context
                    .dispatch(`${queueType}/sync`, aQueue, { root: true })
                    .then((response) => {
                        log(`${queueType} QUEUE SYNC RESPONSE GOING TO REMOVE...`, aQueue)
                        if (response) {
                            return context
                                .dispatch(`remove${queueTypeCapitalized}FromQueue`, { queue: aQueue, api: response.data })
                                .then(() => {
                                    const newQueue = { data: queueItems.slice(index), type: queueType }
                                    log(`${queueType} QUEUE NEXT ITERATION`, JSON.parse(JSON.stringify(newQueue)))
                                    return context
                                        .dispatch(`commonProceedQueue`, newQueue)
                                        .then(() => {
                                            resolve()
                                        })
                                        .catch((error) => {
                                            logError(error)
                                            reject()
                                        })
                                })
                                .catch((error) => {
                                    logError(error)
                                    reject()
                                })
                        } else {
                            reject()
                        }
                    })
                    .catch((error) => {
                        logError(error)
                        reject()
                    })
            } else {
                log(`proceed${queueTypeCapitalized}Queue END`)
                return context
                    .dispatch(`clear${queueTypeCapitalized}Queue`)
                    .then(function () {
                        return context
                            .dispatch('getCountSyncItems', {}, { root: true })
                            .then(() => {
                                resolve()
                            })
                            .catch(function (error) {
                                logError(error)
                                reject()
                            })
                    })
                    .catch(function (error) {
                        logError(error)
                        reject()
                    })
            }
        })
    },
    removeTasksFromQueue(context, { queue, api }) {
        return new Promise((resolve, reject) => {
            log('removeTasksFromQueue INIT')
            if (queue.length > 0) {
                const task = queue.pop()
                log('remove task', task)
                context.commit('tasks/setRequireSync', { check: task.checklist, answer: task.task, requireSync: false }, { root: true })
                return db_tasks_queue.removeItem(task.id).then(() => {
                    context.commit('removeTasksFromQueue', task.id)
                    return context
                        .dispatch('removeTasksFromQueue', { queue: queue, api: api })
                        .then(() => {
                            resolve()
                        })
                        .catch((error) => {
                            logError(error)
                            reject()
                        })
                })
            } else {
                log('removeTasksFromQueue END')
                resolve()
            }
        })
    },
    startAuditQueue(context) {
        log('startAuditQueue')
        return new Promise((resolve, reject) => {
            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                let audits = Object.values(context.getters.getAuditQueue)
                audits.sort((a1, a2) => {
                    return a1.queued_date < a2.queued_date ? -1 : 1
                })
                log(audits)
                context.commit('setStatusAuditsQueue', true)
                const queue = { data: audits, type: 'audit' }
                context
                    .dispatch('commonProceedQueue', queue)
                    .then(() => {
                        log('Audit queue finished')
                        context.commit('setStatusAuditsQueue', false)
                        resolve()
                    })
                    .catch((err) => {
                        context.commit('setStatusAuditsQueue', false)
                        reject(err)
                    })
            } else {
                reject()
            }
        })
    },
    removeAuditFromQueue(context, { queue, api }) {
        return new Promise((resolve, reject) => {
            log('removeAuditFromQueue INIT', queue, api)
            if (queue.length > 0) {
                const audit = queue.shift()
                const auditApi = api && api.length > 0 ? api.shift() : []
                audit.apiId = auditApi.checklist
                log('remove audit', audit.id)
                context.commit('audit/setRequireSync', { check: audit.checklist, answer: audit.task, requireSync: false, apiId: audit.apiId }, { root: true })
                return db_audits_queue.removeItem(audit.id).then(() => {
                    context.commit('removeAuditFromQueue', audit.id)
                    return context.dispatch('removeAuditFromQueue', { queue: queue, api: api }).then(() => {
                        resolve()
                    })
                })
            } else {
                log('removeAuditFromQueue END')
                resolve()
            }
        })
    },
    getRegisterQueue(context) {
        return new Promise((resolve, reject) => {
            let registers = {}
            db_registers_queue
                .iterate(function (value, key, iterationNumber) {
                    registers[key] = value
                })
                .then(() => {
                    resolve(registers)
                })
        })
    },
    async startRegisterQueue(context) {
        log('startRegisterQueue')
        return new Promise(async (resolve, reject) => {
            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                let registers = Object.values(await context.dispatch('getRegisterQueue'))
                registers.sort((register1, register2) => {
                    return register1.queued_date < register2.queued_date ? -1 : 1
                })
                context.commit('setStatusRegisterQueue', true)
                const queue = { data: registers, type: 'register' }
                context
                    .dispatch('commonProceedQueue', queue)
                    .then(() => {
                        log('Register queue finished')
                        context.commit('setStatusRegisterQueue', false)
                        resolve()
                    })
                    .catch((err) => {
                        logError(err)
                        context.commit('setStatusRegisterQueue', false)
                        reject(err)
                    })
            } else {
                reject()
            }
        })
    },
    proceedRegisterQueue(context, registers) {
        return new Promise(async (resolve, reject) => {
            log('proceedRegisterQueue INIT')
            if (registers.length > 0) {
                let registerQueue = []
                let index = 0
                for (index; index < registers.length && index < state.num_max_tq_batch; index++) {
                    const queueElement = registers[index]
                    if (queueElement.type != FORMAT.PHOTO) {
                        registerQueue.push(queueElement)
                    } else {
                        const images = JSON.parse(queueElement.value)
                        if (images.length == 1) {
                            registerQueue.push(queueElement)
                            break
                        } else {
                            let galleryElement = { ...queueElement }
                            const image = images.slice(-1)
                            galleryElement.isGalleryElement = 1
                            galleryElement.value = JSON.stringify(image)
                            await context.dispatch('register/sync', [galleryElement], { root: true })
                            await context.dispatch('removeRegisterFromQueue', [queueElement])
                            return context
                                .dispatch('proceedRegisterQueue', audits.slice(index + 1))
                                .then(() => {
                                    resolve()
                                })
                                .catch((error) => {
                                    logError(error)
                                    reject()
                                })
                        }
                    }
                }
                return context.dispatch('register/sync', registerQueue, { root: true }).then((response) => {
                    if (response) {
                        return context
                            .dispatch('removeRegisterFromQueue', registerQueue)
                            .then(() => {
                                return context
                                    .dispatch('proceedRegisterQueue', registers.slice(index + 1))
                                    .then(() => {
                                        resolve()
                                    })
                                    .catch((error) => {
                                        logError(error)
                                        reject()
                                    })
                            })
                            .catch((error) => {
                                logError(error)
                                reject()
                            })
                    } else {
                        reject()
                    }
                })
            } else {
                log('proceedRegisterQueue END')
                return context
                    .dispatch('clearRegisterQueue')
                    .then(function () {
                        return context
                            .dispatch('getCountSyncItems', {}, { root: true })
                            .then(() => {
                                resolve()
                            })
                            .catch(function (error) {
                                logError(error)
                                reject()
                            })
                    })
                    .catch(function (error) {
                        logError(error)
                        reject()
                    })
            }
        })
    },
    removeRegisterFromQueue(context, { queue, api }) {
        return new Promise((resolve, reject) => {
            log('removeRegisterFromQueue INIT')
            if (queue.length > 0) {
                const register = queue.shift()
                const registerApi = api && api.length > 0 ? api.shift() : []
                register.apiId = registerApi.checklist
                log('remove register', register)
                context.commit('register/setRequireSync', { check: register.checklist, apiId: register.apiId, answer: register.task, requireSync: false }, { root: true })
                return db_registers_queue
                    .removeItem(register.id)
                    .then(() => {
                        context.commit('removeRegisterFromQueue', register.id)
                        return context
                            .dispatch('removeRegisterFromQueue', { queue: queue, api: api })
                            .then(() => {
                                resolve()
                            })
                            .catch((error) => {
                                logError(error)
                                reject()
                            })
                    })
                    .catch((error) => {
                        logError(error)
                        reject()
                    })
            } else {
                log('removeRegisterFromQueue END')
                resolve()
            }
        })
    },
    startIssuesQueue(context) {
        log('startIssuesQueue')
        return new Promise((resolve, reject) => {
            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                let issues = Object.values(context.getters.getIssuesQueue)
                issues.sort((issue1, issue2) => {
                    return issue1.queued_date < issue2.queued_date ? -1 : 1
                })
                context.commit('setStatusIssuesQueue', true)
                const queue = { data: issues, type: 'issues' }
                context
                    .dispatch('commonProceedQueue', queue)
                    .then(() => {
                        log('Issues queue finished')
                        context.commit('setStatusIssuesQueue', false)
                        resolve()
                    })
                    .catch((err) => {
                        logError(err)
                        context.commit('setStatusIssuesQueue', false)
                        reject()
                    })
            } else {
                reject()
            }
        })
    },
    removeIssuesFromQueue(context, { queue, api }) {
        return new Promise((resolve, reject) => {
            log('removeIssueFromQueue INIT')
            if (queue.length > 0) {
                const issue = queue.shift()
                const issueApi = api && api.length > 0 ? api.shift() : []
                issue.apiId = issueApi.checklist
                log('remove issue', issue)
                context.commit('issues/setRequireSync', { check: issue.checklist, answer: issue.task, requireSync: false, apiId: issue.apiId }, { root: true })
                return db_issues_queue
                    .removeItem(issue.id)
                    .then(() => {
                        context.commit('removeIssuesFromQueue', issue.id)
                        return context
                            .dispatch('removeIssuesFromQueue', { queue: queue, api: api })
                            .then(() => {
                                resolve()
                            })
                            .catch((error) => {
                                logError(error)
                                reject()
                            })
                    })
                    .catch((error) => {
                        logError(error)
                        reject()
                    })
            } else {
                log('removeIssuesFromQueue END')
                resolve()
            }
        })
    },
    getCountSync(context) {
        let taskCountPromise = new Promise((resolve, reject) => {
            db_tasks_queue.length().then(function (number) {
                resolve(number)
                log(`number tasks in queue: ${number}`)
            })
        })

        let auditCountPromise = new Promise((resolve, reject) => {
            db_audits_queue.length().then(function (number) {
                resolve(number)
                log(`number audits in queue: ${number}`)
            })
        })

        let registerCountPromise = new Promise((resolve, reject) => {
            db_registers_queue.length().then(function (number) {
                resolve(number)
                log(`number register in queue: ${number}`)
            })
        })

        let issuesCountPromise = new Promise((resolve, reject) => {
            db_issues_queue.length().then((number) => {
                resolve(number)
                log(`number issues in queue: ${number}`)
            })
        })

        return Promise.all([taskCountPromise, auditCountPromise, registerCountPromise, issuesCountPromise]).then((values) => {
            let count = 0
            values.forEach((n) => {
                count += n
            })

            return count
        })
    },
    loadOfflineSyncItems() {
        return loadLocalForageInformation()
    },
    clearTasksQueue(context) {
        return true
        // state.task_queue = {}
        // return db_tasks_queue
        //     .clear()
        //     .then((response) => {
        //         return true
        //     })
        //     .catch((error) => {
        //         logError(error)
        //         return false
        //     })
    },
    clearAuditQueue(context) {
        return true
        // state.audit_queue = {}
        // return db_audits_queue
        //     .clear()
        //     .then((response) => {
        //         return true
        //     })
        //     .catch((error) => {
        //         logError(error)
        //         return false
        //     })
    },
    clearRegisterQueue(context) {
        return true
        // state.register_queue = {}
        // return db_registers_queue
        //     .clear()
        //     .then((response) => {
        //         return true
        //     })
        //     .catch((error) => {
        //         logError(error)
        //         return false
        //     })
    },
    clearIssuesQueue(context) {
        return true
        state.issues_queue = {}
        return db_issues_queue
            .clear()
            .then((response) => {
                return true
            })
            .catch((error) => {
                logError(error)
                return false
            })
    }
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    toggleAnswer(state, payload) {
        var id = payload.checklist
        var answer = payload.answer
        var prop = payload.prop
        var cType = payload.cType

        for (var i in state.checklist[cType][id].items) {
            var item = state.checklist[cType][id].items[i]
            if (item.item_id == answer.item_id) item[prop] = !item[prop]
            else item[prop] = false
        }

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    formatChecklist(state, payload) {
        var append = typeof payload.append !== 'undefined' ? payload.append : false
        var cType = payload.cType
        if (append) {
            var bChecklist = state.checklist[cType]
            var bIntime = state.cIntime[cType]
            var bPast = state.cPast[cType]
        } else {
            var bChecklist = {}
            var bIntime = {}
            var bPast = {}
        }

        if (typeof payload.data !== 'undefined') {
            Object.keys(payload.data).map(function (ins_id, i) {
                var ins = payload.data[ins_id]
                ins.def = { ...state.defaults['default'], ...state.defaults[cType] }
                ins.def.item = {
                    ...state.defaults['default'].item,
                    ...state.defaults[cType].item
                }
                ins.def.auth_users = {
                    ...state.defaults['default'].auth_users,
                    ...state.defaults[cType].auth_users
                }
                ins.def.can_edit = {
                    ...state.defaults['default'].can_edit,
                    ...state.defaults[cType].can_edit
                }

                ins.end_date = !moment.isMoment(ins.end_date) && ins.end_date != false ? moment(ins.end_date).set({ s: 0, ms: 0 }) : ins.end_date
                ins.start_date = !moment.isMoment(ins.start_date) && ins.start_date != false ? moment(ins.start_date) : ins.start_date
                ins.created_date = !moment.isMoment(ins.created_date) && ins.created_date != false ? moment(ins.created_date) : ins.created_date
                ins.complete_date = !moment.isMoment(ins.complete_date) && ins.complete_date != false ? moment(ins.complete_date) : ins.complete_date

                ins.uncompleted = 0
                ins.completed = 0
                ins.unanswered = 0
                ins.total = 0
                ins.score = 0

                var now = moment()

                if (ins.def.has_timing) {
                    ins.timing = {}
                    if (ins.start_date.isAfter(now)) {
                        bPast[ins.id] = ins
                        ins.timing.type = 'future'
                        ins.timing.info = i18n.t('checklist.timing.future', {
                            s: now.to(ins.start_date, true)
                        })
                    }
                    if (ins.start_date.isSameOrBefore(now) && ins.end_date.isSameOrAfter(now)) {
                        bIntime[ins.id] = ins
                        ins.timing.type = 'intime'
                        ins.timing.info = timeLeft(ins.end_date)
                    }

                    if (ins.def.close_when_completed) {
                        if (ins.end_date.isSameOrBefore(now) || ins.complete_date) {
                            bPast[ins.id] = ins
                            ins.timing.type = 'past'
                            ins.timing.info = Object.keys(ins.employees).length > 0 ? i18n.t('checklist.detail.completed') : i18n.t('checklist.detail.uncompleted')
                        }
                    } else {
                        if (ins.end_date.isSameOrBefore(now)) {
                            bPast[ins.id] = ins
                            ins.timing.type = 'past'
                            ins.timing.info = ins.total == ins.completed ? i18n.t('checklist.detail.completed') : i18n.t('checklist.detail.uncompleted')
                        }
                    }
                }

                ins.employees = []
                let temp = []
                let iItems = ins.items ? ins.items : {}

                Object.keys(iItems).map(function (item_id, i) {
                    var item = iItems[item_id]

                    if (item.type == 5) {
                        let aux = []

                        if (typeof item.value !== 'undefined' && item.value != null) {
                            if (item.value.length > 0) {
                                if (item.value.indexOf(',') != -1) {
                                    aux = splitCommasBase64(item.value)
                                } else if (typeof item.value === 'object') {
                                    aux = item.value
                                } else {
                                    aux.push(item.value)
                                }
                            }
                        }

                        item.value = aux
                    }

                    item.alerts = []

                    item.config = typeof item.config === 'string' ? JSON.parse(item.config) : item.config

                    item.complete_date = !moment.isMoment(item.complete_date) && item.complete_date != false ? moment(item.complete_date * 1000) : item.complete_date

                    if (item.review) {
                        item.review.complete_date = !moment.isMoment(item.review.complete_date) && item.review.complete_date != false ? moment(item.review.complete_date * 1000) : item.review.complete_date
                    }

                    item.available = false
                    if (item.margin > 0) {
                        var start = moment()

                        if (item.planned_date) {
                            start = moment(item.planned_date * 1000)
                        } else if (!item.planned_date) {
                            start = moment(ins.start)
                        }

                        var av = moment(start).add(item.margin - 1, 'days')
                        item.available = Math.round(moment.duration(av.diff(moment())).asDays(), 0)
                    }

                    if (ins.def.has_score && (!item.parent_id || ins.def.item.count_subtask) && ins.def.item.itemsExcluded.indexOf(item.type) == -1) {
                        switch (item.type) {
                            case 2: // SI/NO
                            case 3: // SI/NO/NSNC
                                var allDone = !(item.require_review && !item.review)

                                if (ins.def.item.rate) {
                                    if (item.complete_date != false) {
                                        if (item.value == 1 || item.value == 'true' || item.value == '1') {
                                            if (allDone) {
                                                ins.completed++
                                            } else {
                                                ins.completed += 0.5
                                            }
                                        } else if (parseInt(item.value) == 0 || parseInt(item.value) == 2) {
                                            ins.uncompleted++
                                        }
                                    } else {
                                        ins.unanswered++
                                    }
                                } else {
                                    if (item.complete_date != false) {
                                        if (allDone) {
                                            ins.completed++
                                        } else {
                                            ins.completed += 0.5
                                        }
                                    } else {
                                        ins.unanswered++
                                    }
                                }
                                break

                            case 1: // CHECKS
                            case 4: // TEXTO
                            case 5: // FOTO
                            case 6: // NUMBER
                            case 7: // DATE
                            case 10: // TEXTAREA
                            case 11: // MULTIPLE SELECT
                            case 12: // TIME
                            case 13: // DATETIME
                            case 14: // SIGNATURE
                            case 15: // TEMPERATURE
                            case 16: // EMAIL
                            case 17: // DOCUMENT
                                var allDone = !(item.require_review && !item.review)

                                if (item.complete_date != false) {
                                    if (allDone) {
                                        ins.completed++
                                    } else {
                                        ins.completed += 0.5
                                    }
                                } else {
                                    ins.unanswered++
                                }
                                break

                            default:
                                break
                        }
                        ins.total++
                    }

                    item.hasComment = false
                    if (item.message != false || item.image != false) {
                        item.hasComment = true
                    }
                    item.message = item.message != false ? item.message : ''

                    if (item.employee) {
                        if (temp.indexOf(item.employee.id) == -1) {
                            temp.push(item.employee.id)
                            ins.employees.push(item.employee)
                        }
                    }

                    item.showComment = !!item.hasComment
                    item.editComment = false
                    ins.items[item_id] = item
                })

                if (ins.def.has_score) {
                    ins.score = Math.round((parseFloat(ins.completed) * 100) / parseInt(ins.total))
                }

                bChecklist[ins.id] = ins
            })
        }
        state.checklist[cType] = bChecklist
        state.cIntime[cType] = bIntime
        state.cPast[cType] = bPast

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })

        Vue.set(state, 'cIntime', { ...state.cIntime })
        Vue.set(state, 'cIntime[' + cType + ']', { ...state.cIntime[cType] })

        Vue.set(state, 'cPast', { ...state.cPast })
        Vue.set(state, 'cPast[' + cType + ']', { ...state.cPast[cType] })
    },
    setTemplates(state, payload) {
        var templates = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} }
        // console.log(payload.data)
        Object.keys(payload.data).map(function (tpl_id, i) {
            var tpl = { ...payload.data[tpl_id] }
            tpl.created_date = tpl.created_date != false ? moment(tpl.created_date) : moment()

            if (tpl) {
                if (!templates[tpl.type]) {
                    templates[tpl.type] = {}
                }
                templates[tpl.type][tpl.id] = tpl
            }
        })

        Vue.set(state, 'templates', { ...templates })
    },
    setShowNoApply(state, payload) {
        state.showNoApply = payload
    },
    formatRegister(state, payload) {
        state.register = payload.data
        Vue.set(state, 'register', { ...state.register })
    },
    setChecklistProp(state, payload) {
        var check = payload.check
        var prop = payload.prop
        var value = payload.value
        var cType = payload.cType

        state.checklist[cType][check][prop] = value

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value
        var cType = payload.cType

        state.checklist[cType][check].items[answer][prop] = value

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setAnswerComment(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var message = payload.message
        var image = payload.image

        state.checklist[cType][check].items[answer].message = message
        if (typeof image !== 'undefined') {
            state.checklist[cType][check].items[answer].image = image
        }

        state.checklist[cType][check].items[answer].editComment = false
        if (image || message != '') {
            state.checklist[cType][check].items[answer].showComment = true
        } else {
            state.checklist[cType][check].items[answer].showComment = false
        }

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setAnswer(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.checklist[cType][check].items[answer].value = value

        if (date) {
            var item = state.checklist[cType][check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.checklist[cType][check].items[answer].employee = emp

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setValidation(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.checklist[cType][check].items[answer].review = {}
        if (date) {
            var item = state.checklist[cType][check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.checklist[cType][check].items[answer].review.employee = employee
        }
        state.checklist[cType][check].items[answer].review.value = 1

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setUnvalidation(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer

        state.checklist[cType][check].items[answer].review = false

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setReset(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer

        state.checklist[cType][check].items[answer].complete_date = null
        state.checklist[cType][check].items[answer].employee = false
        state.checklist[cType][check].items[answer].review = false
        state.checklist[cType][check].items[answer].value = null

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setStatusTasksQueue(state, value) {
        state.tq_active = value
    },
    setStatusAuditsQueue(state, value) {
        state.aq_active = value
    },
    setStatusRegisterQueue(state, value) {
        state.rq_active = value
    },
    setStatusIssuesQueue(state, value) {
        state.iq_active = value
    },
    addTaskToQueue(state, answer) {
        state.task_queue[answer.id] = answer
        db_tasks_queue.setItem(answer.id, answer)
    },
    addAuditToQueue(state, answer) {
        state.audit_queue[answer.id] = answer
        db_audits_queue.setItem(answer.id, answer)
    },
    addRegisterToQueue(state, answer) {
        state.register_queue[answer.id] = answer
        db_registers_queue.setItem(answer.id, answer)
    },
    addIssueToQueue(state, answer) {
        state.issues_queue[answer.id] = answer
        db_issues_queue.setItem(answer.id, answer)
    },
    removeTasksFromQueue(state, id) {
        delete state.task_queue[id]
    },
    removeAuditFromQueue(state, id) {
        delete state.audit_queue[id]
    },
    removeRegisterFromQueue(state, id) {
        delete state.register_queue[id]
    },
    removeIssuesFromQueue(state, id) {
        delete state.issues_queue[id]
    }
}

function timeLeft(deadtime) {
    // var now = store.getters['getMoment'];
    var now = moment()
    var text = ''

    var years = deadtime.diff(now, 'year')
    now.add(years, 'years')

    var months = deadtime.diff(now, 'months')
    now.add(months, 'months')

    var days = deadtime.diff(now, 'days')
    now.add(days, 'days')

    var hours = deadtime.diff(now, 'hours')
    now.add(hours, 'hours')

    var minutes = deadtime.diff(now, 'minutes')
    now.add(minutes, 'minutes')

    var seconds = deadtime.diff(now, 'seconds')
    now.add(seconds, 'seconds')

    if (years > 0) {
        if (years == 1) {
            text += years + ' ' + i18n.tc('checklist.timing.year', 1)
        } else if (years > 1) {
            text += years + ' ' + i18n.tc('checklist.timing.year', 2)
        }
    } else if (years <= 0 && months > 0) {
        if (months == 1) {
            text += months + ' ' + i18n.tc('checklist.timing.month', 1)
        } else if (months > 1) {
            text += months + ' ' + i18n.tc('checklist.timing.month', 2)
        }

        if (text.length > 1 && days != 0) {
            text += ' ' + i18n.t('checklist.timing.connector') + ' '
        }

        if (days == 1) {
            text += days + ' ' + i18n.tc('checklist.timing.day', 1)
        } else if (days > 1) {
            text += days + ' ' + i18n.tc('checklist.timing.day', 2)
        }
    } else if (years <= 0 && months <= 0 && days > 0) {
        if (days == 1) {
            text += days + ' ' + i18n.tc('checklist.timing.day', 1)
        } else if (days > 1) {
            text += days + ' ' + i18n.tc('checklist.timing.day', 2)
        }

        if (text.length > 1 && hours != 0) {
            text += ' ' + i18n.t('checklist.timing.connector') + ' '
        }

        if (hours == 1) {
            text += hours + ' ' + i18n.tc('checklist.timing.hour', 1)
        } else if (hours > 1) {
            text += hours + ' ' + i18n.tc('checklist.timing.hour', 2)
        }
    } else if (years <= 0 && months <= 0 && days <= 0 && hours > 0) {
        if (hours == 1) {
            text += hours + ' ' + i18n.tc('checklist.timing.hour', 1)
        } else if (hours > 1) {
            text += hours + ' ' + i18n.tc('checklist.timing.hour', 2)
        }

        if (text.length > 1 && minutes != 0) {
            text += ' ' + i18n.t('checklist.timing.connector') + ' '
        }

        if (minutes == 1) {
            text += minutes + ' ' + i18n.tc('checklist.timing.minute', 1)
        } else if (minutes > 1) {
            text += minutes + ' ' + i18n.tc('checklist.timing.minute', 2)
        }
    } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes > 0) {
        if (minutes == 1) {
            text += minutes + ' ' + i18n.tc('checklist.timing.minute', 1)
        } else if (minutes > 1) {
            text += minutes + ' ' + i18n.tc('checklist.timing.minute', 2)
        }
    } else if (years <= 0 && months <= 0 && days <= 0 && hours <= 0 && minutes <= 0 && seconds > 0) {
        text += i18n.t('checklist.timing.inminent')
    }

    return i18n.t('checklist.timing.remind', { s: text })
}

function loadTemplatesOffline(context) {
    let templates = []
    let count = 0
    return db_templates
        .iterate(function (value, key, iterationNumber) {
            if (value.type == state.type) {
                templates.push(value)
                count++
            }
        })
        .then(function () {
            if (count > 0) {
                context.commit('setTemplates', { data: templates })
            }
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
