import { Api } from '@/core/api.js'
import Vue from 'vue'
const api = new Api()

const state = {
    sensors: {},
    sensorValues: {},
    createState: false
}

const getters = {
    getList: (state, getters, rootState) => (id) => {
        if (id) {
            return state.sensors[id]
        }
        return state.sensors
    },
    getSensorValues: (state, getters, rootState) => (id) => {
        if (id) {
            return state.sensorValues[id]
        }
        return state.sensorValues
    },
    getCreateState(state, getters, rootState){
        return state.createState
    }
}

const actions = {
    loadList(context, params) {
        return api.get('sensor/devices', {}).then(function (response) {
            return response.status ? context.commit('setList', response.data) : false
        })
    },
    loadSensorValues(context, params) {
        // pasar parametro fecha
        return api.post('sensor/values', params || {}).then(function (response) {
            return response.status ? context.commit('setSensorValues', response.data) : false
        })
    },
    loadSensorValuesDetail(context, params) {
        // pasar parametro fecha
        return api.post('sensor/values/' + params.id, params).then(function (response) {
            return response.status ? context.commit('setSensorValue', { id: params.id, data: response.data[params.id] }) : false
        })
    },

    loadSensorOpenIssues(context, params) {
        // pasar parametro
        // id o sin id
        params = params || ''
        return api.post('sensor/issues/' + (params && params.sensor_id ? params.sensor_id : ''), params).then(function (response) {
            return response.status ? response.data : false
        })
    },

    addSensor(context, params) {
        if (params.newVal) {
            params.sensor.config.value = params.newVal
        }
        params.sensor.config = JSON.stringify(params.sensor.config)
        let sensor = params.sensor
        return api.post('sensor/add', sensor).then(function (response) {
            context.commit('addSensor', response.data)
            return true
        })
    },
    editSensor(context, params) {
        params.sensor.sensor_id = params.sensor.id
        if (params.newVal) {
            params.sensor.config.value = params.newVal
        }
        params.sensor.config = JSON.stringify(params.sensor.config)
        let sensor = params.sensor
        return api.post('sensor/update', sensor).then(function (response) {
            context.commit('editSensor', response.data)
            return true
        })
    },
    removeSensor(context, params) {
        return api.post('sensor/delete', { sensor_id: params }).then(function (response) {
            context.commit('removeSensor', params)
            return true
        })
    }
}

const mutations = {
    setCreateState(state,data){
        state.createState = data
    },
    setList(state, data) {
        state.sensors = data
    },
    setSensorValues(state, data) {
        state.sensorValues = data
    },
    setSensorValue(state, payload) {
        let sensorValues = state.sensorValues
        sensorValues[payload.id] = payload.data
        state.sensorValues = { ...sensorValues }
        Vue.set(state, 'sensorValues', { ...state.sensorValues })
    },
    addSensor(state, data) {
        let sensors = state.sensors
        sensors[data.id] = data
        state.sensors = { ...sensors }
        Vue.set(state, 'sensors', { ...state.sensors })
    },
    editSensor(state, data) {
        let sensors = state.sensors
        sensors[data.id] = data
        state.sensors = { ...sensors }
        Vue.set(state, 'sensors', { ...state.sensors })
    },
    removeSensor(state, payload) {
        let sensors = state.sensors
        delete sensors[payload]
        state.sensors = { ...sensors }
        Vue.set(state, 'sensors', { ...state.sensors })
    },

    setTicket(state, data) {
        let tickets = state.tickets
        tickets[data.id] = data
        state.tickets = { ...tickets }
        Vue.set(state, 'tickets', { ...state.tickets })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
