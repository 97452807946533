import { Api } from '@/core/api.js'
import localforage from 'localforage'
import store from '@/store'
import i18n from '@/i18n'

const db_notification = localforage.createInstance({ name: 'alexdb', storeName: 'notification' })
const db_event = localforage.createInstance({ name: 'alexdb', storeName: 'event' })

export class Notification {
    construction() {}

    async init() {
        console.log('INIT NOTIFICATION')
        console.log('LaunchDetails', cordova.plugins.notification.local.launchDetails)
        console.log('fireQueuedEvents', cordova.plugins.notification.local.fireQueuedEvents())

        let self = this

        if (store.getters.getIsApp && FirebasePlugin) {
            await this.requireFirebaseToken()

            FirebasePlugin.onMessageReceived(
                function (message) {
                    console.log('Message type: ' + message.messageType)
                    if (message.messageType === 'notification') {
                        console.log('Notification message received')
                        if (message.tap) {
                            console.log('Tapped in ' + message.tap)
                        } else {
                            console.log('Not tapped.. Generating local notification')
                            self.addLocalNotification({ title: message.title, text: message.body })
                        }
                    }
                    console.dir(message)
                },
                function (error) {
                    console.error(error)
                }
            )

            cordova.plugins.notification.local.on('click', async function (notification) {
                var objects = notification.data
                console.log('EVENT CLICK', notification)
                store.dispatch('notification/addEvent', notification)

                //TODO Interact with the notification

                await self.cleanNotification(notification.id)
                // My data is now available in objects.heading, objects.subheading and so on.
                // console.log(objects.displayedtext);
            })

            if (cordova.plugins.notification.local.launchDetails) {
                //TODO Interact with the notification
                this.cleanNotification(cordova.plugins.notification.local.launchDetails.id)
            }
        }
        console.log('END INIT NOTIFICATION')
    }

    requireFirebaseToken() {
        FirebasePlugin.getToken(
            function (fcmToken) {
                console.log('Require Firebase Token', fcmToken)
                store.commit('notification/setFirebaseToken', fcmToken)
            },
            function (error) {
                console.error('Require Firebase Token', error)
            }
        )
    }

    subscribe(topic) {
        const current_topics = store.getters['notification/getTopics']
        if (FirebasePlugin) {
            if (!current_topics.includes(topic)) {
                FirebasePlugin.subscribe(
                    topic,
                    function () {
                        console.log('Subscribed to topic ', topic)
                        store.commit('notification/setSubscribeTopic', topic)
                    },
                    function (error) {
                        console.error('Error subscribing to topic: ', error)
                    }
                )
            } else {
                console.warn('Device already subscribed to topic', topic)
            }
        }
    }

    unsubscribe(topic) {
        if (FirebasePlugin) {
            FirebasePlugin.unsubscribe(
                topic,
                function () {
                    console.log('Unsubscribed from topic', topic)
                    store.commit('notification/setUnsubscribeTopic', topic)
                },
                function (error) {
                    console.error('Error unsubscribing from topic: ' + error)
                }
            )
        }
    }

    async getNextNotificationId() {
        return localStorage.last_notification_id ? parseInt(localStorage.last_notification_id) + 1 : 1
    }

    existsSameNotification(config) {
        return new Promise((resolve, reject) => {
            if (store.getters.getIsApp) {
                cordova.plugins.notification.local.getAll((notifications) => {
                    let exists = false
                    for (let i = 0; i < notifications.length; i++) {
                        let data = notifications[i].data ? JSON.parse(notifications[i].data) : false
                        if (!data && !config.data) {
                            exists = true
                        } else {
                            // compare if notifications[i] object is equal to config object, compare if has same keys and values
                            if (data && config.data) {
                                if (Object.keys(data).length === Object.keys(config.data).length) {
                                    let equal = true
                                    for (let key in data) {
                                        if (data[key] !== config.data[key]) {
                                            equal = false
                                            break
                                        }
                                    }
                                    if (equal) {
                                        exists = true
                                        break
                                    }
                                }
                            }
                        }
                    }
                    resolve(exists)
                })
            } else {
                resolve(false)
            }
        })
    }

    async addLocalNotification(params) {
        if (store.getters.getIsApp) {
            const nextKey = await this.getNextNotificationId()
            const defaults = {
                id: nextKey || 1,
                title: '',
                description: '',
                text: '',
                smallIcon: 'res://ic_launcher',
                ongoing: false,
                data: false
            }

            let config = Object.assign(defaults, params)

            if (!(await this.existsSameNotification(config))) {
                console.log('NO EXISTE NOTIFICATION IGUAL', config)
                // if (config.group) {
                //     let configGroup = { ...config }
                //     configGroup.groupSummary = true
                //     configGroup.id = config.id + 1
                //     configGroup.summary = config.group
                //     cordova.plugins.notification.local.schedule(configGroup)
                //     localStorage.setItem('last_notification_id', configGroup.id)
                // }

                localStorage.setItem('last_notification_id', config.id)

                cordova.plugins.notification.local.schedule(config)
            } else {
                console.log('YA EXISTE NOTIFICATION IGUAL')
            }

            // store.dispatch('notification/addNotification', config)
        }
    }

    async removeLocalNotification(params) {
        if (store.getters.getIsApp) {
            console.log('Found to remove notification, params:', params)
            cordova.plugins.notification.local.getAll((notifications) => {
                for (let i = 0; i < notifications.length; i++) {
                    let exists = false
                    let data = notifications[i].data ? JSON.parse(notifications[i].data) : false
                    if (data && params) {
                        if (Object.keys(data).length === Object.keys(params).length) {
                            let equal = true
                            for (let key in data) {
                                if (data[key] !== params[key]) {
                                    equal = false
                                }
                            }
                            if (equal) {
                                exists = true
                            }
                        }
                    }

                    if (exists) {
                        cordova.plugins.notification.local.clear([notifications[i].id], function () {
                            console.log('Notification canceled')
                        })
                    } else {
                        if (params.timerId === data.timerId && moment(params.date).isAfter(moment(notifications[i].date))) {
                            cordova.plugins.notification.local.clear([notifications[i].id], function () {
                                console.log('Notification canceled')
                            })
                        }
                    }
                }
            })
        }
    }

    async cleanNotification(id) {
        return new Promise((resolve, reject) => {
            if (store.getters.getIsApp) {
                cordova.plugins.notification.local.clear([id], function () {
                    console.log('Notification canceled')
                    resolve()
                })
            } else {
                resolve()
            }
        })
    }
}
