import Template from './SnackBar.vue'
import i18n from '@/i18n'
// import store from '@/store'

let globalOptions = {
    show: false,
    talk: false,
    closeable: false,
    type: 'main',
    duration: 3000,
    customClass: '',
    message: i18n.t('unexpected'),
    action: function () {
        if (this.closeable) {
            instance.$snackbar.close();
        }
    },

}

let instance

const Init = function (config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) {
        instance = new Tpl()
        config = {
            ...globalOptions,
            ...config
        }
    } else {
        config = {
            ...globalOptions,
            ...instance.$data,
            ...config
        }
    }

    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            // console.log(key + "   " + config[key])
            instance.$data[key] = config[key]
        }
    }
}

const Open = function (config = {}) {
    Init.call(this, config)

    instance.$data.show = true

    if (instance.$data.talk) {
        log("Talking alex -> Tooltip");
        // if(process.env.VUE_APP_IS_APP === "true") {
        //     TTS.speak(data.message,function () { }, function (reason) {});
        // } else {
        //     speaker.setPitch(0.9);
        //     speaker.setVoice('Google español');
        //     speaker.speak(data.message);
        // }
    }

    document.body.appendChild(instance.$mount().$el)
    setTimeout(function () {
        if (instance) {
            if (instance.$data.closeable) {
                Close.call(this, config);
            }
        }
    }, instance.$data.duration);
}
const Close = function (config = {}) {
    if (typeof instance !== 'undefined') {
        instance.$data.show = false
        document.body.removeChild(instance.$mount().$el)
        instance = undefined
    }
}

const Main = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Info = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'info',
        message: i18n.t('tooltip.done'),
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Success = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'success',
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Warning = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'warning',
        duration: 6000,
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Progress = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'progress',
        customClass: 'progress',
        duration: 6000,
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Error = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'error',
        duration: 6000,
        message: i18n.t('tooltip.error'),
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Login = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'login',
        message: i18n.t('tooltip.login'),
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Offline = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'offline',
        message: i18n.t('tooltip.conexion'),
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Important = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'important',
        closeable: false,
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Loading = function (config = {}) {
    var configAction = config.action;

    var defaults = {
        type: 'loading',
        message: i18n.t('tooltip.loading'),
        closeable: false,
        action: function (self) {
            if (typeof configAction !== 'undefined') {
                configAction(self);
            }
            self.$snackbar.close();
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}


export default {
    install (Vue) {
        Vue.prototype.$snackbar = Init.bind(Vue)
        Vue.prototype.$snackbar.open = Open.bind(Vue)
        Vue.prototype.$snackbar.show = Open.bind(Vue)
        Vue.prototype.$snackbar.close = Close.bind(Vue)
        Vue.prototype.$snackbar.hide = Close.bind(Vue)

        Vue.prototype.$snackbar.main = Main.bind(Vue)
        Vue.prototype.$snackbar.info = Info.bind(Vue)
        Vue.prototype.$snackbar.success = Success.bind(Vue)
        Vue.prototype.$snackbar.warning = Warning.bind(Vue)
        Vue.prototype.$snackbar.progress = Progress.bind(Vue)
        Vue.prototype.$snackbar.error = Error.bind(Vue)
        Vue.prototype.$snackbar.login = Login.bind(Vue)
        Vue.prototype.$snackbar.offline = Offline.bind(Vue)
        Vue.prototype.$snackbar.important = Important.bind(Vue)
        Vue.prototype.$snackbar.loading = Loading.bind(Vue)
    }
}
