import axios from 'axios'
import localforage from 'localforage'
import store from '@/store'

let API_URL = process.env.VUE_APP_API_URL
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

// ADD BY DEFAULT TIMEOUT 10sec
const timeout = 15000
axios.defaults.timeout = timeout

export class Api {
    constructor() {}

    async get(uri, options = {}) {
        this.setEnviromentUrl()

        let token = store.getters['login/getToken']
        if (!token) {
            token = await db_login.getItem('token')
        }
        axios.defaults.headers.common['Authorization'] = token

        const url = API_URL + uri
        return axios
            .get(url, {
                withCredentials: true,
                timeout: options.timeout || timeout,
                validateStatus: function (status) {
                    if ([401, 402, 403].includes(status)) {
                        window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (window.location.hash == '' ? '' : window.location.pathname.substring(0, window.location.pathname.length - 1)) + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                        return false
                    }
                    if ([503].includes(status) && process.env.VUE_APP_IS_APP !== 'TRUE') {
                        localStorage.setItem('maintenance', true)
                        store.commit('setMaintenance', true)
                        return false
                    }
                    store.commit('setMaintenance', false)
                    localStorage.setItem('maintenance', false)
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
            .catch((error) => {
                // TODO: validate more error messages
                return { status: false, msg: 'Error: Network Error' + error }
            })
    }

    async post(uri, params, options = {}) {
        this.setEnviromentUrl()

        let token = store.getters['login/getToken']
        if (!token) {
            token = await db_login.getItem('token')
        }

        axios.defaults.headers.common['Authorization'] = token

        const oParams = new URLSearchParams()
        Object.keys(params).forEach((key) => {
            oParams.append(key, params[key])
        })

        const url = API_URL + uri
        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
        const coords = localStorage.device_coords ? localStorage.device_coords : false
        const uuid = localStorage.device_uuid ? localStorage.device_uuid : false

        if (!params.version) oParams.append('version', appVersion)
        if (!params.platform) oParams.append('platform', platform)
        if (coords) oParams.append('geolocation', JSON.parse(coords).latitude + ',' + JSON.parse(coords).longitude)
        if (uuid) oParams.append('uuid', uuid)

        return axios
            .post(url, oParams, {
                withCredentials: true,
                timeout: options.timeout || timeout,
                validateStatus: function (status) {
                    if ([401, 402, 403].includes(status)) {
                        window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (window.location.hash == '' ? '' : window.location.pathname.substring(0, window.location.pathname.length - 1)) + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                        return false
                    }
                    if ([503].includes(status) && process.env.VUE_APP_IS_APP !== 'TRUE') {
                        store.commit('setMaintenance', true)
                        localStorage.setItem('maintenance', true)
                        return false
                    }
                    store.commit('setMaintenance', false)
                    localStorage.setItem('maintenance', false)
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
            .catch((error) => {
                // TODO: validate more error messages
                return { status: false, msg: 'Error: Network Error' + error }
            })
    }

    login(uri, params) {
        this.setEnviromentUrl()

        const oParams = new URLSearchParams()
        Object.keys(params).forEach((key) => {
            oParams.append(key, params[key])
        })

        const url = API_URL + uri
        return axios.post(url, oParams, {}).then(function (response) {
            return response.data
        })
    }

    async getSession(params) {
        this.setEnviromentUrl()

        let token = store.getters['login/getToken']
        if (!token) {
            token = await db_login.getItem('token')
        }

        axios.defaults.headers.common['Authorization'] = token

        const url = API_URL + 'init'
        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
        const coords = localStorage.device_coords ? localStorage.device_coords : false
        const uuid = localStorage.device_uuid ? localStorage.device_uuid : false
        const oParams = new URLSearchParams()
        const mq = typeof params.mq !== 'undefined' && params.mq ? params.mq : null
        const auditor = typeof params.auditor !== 'undefined' && params.auditor ? JSON.stringify({...params.auditor}) : false

        oParams.append('version', appVersion)
        oParams.append('platform', platform)
        oParams.append('mq', mq)
        oParams.append('auditor', auditor)

        if (uuid) oParams.append('uuid', uuid)
        if (coords) oParams.append('geolocation', JSON.parse(coords).latitude + ',' + JSON.parse(coords).longitude)

        return axios
            .post(url, oParams, {
                validateStatus: function (status) {
                    if ([401, 402, 403].includes(status)) {
                        window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                        return false
                    }
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
            .catch((error) => {
                // TODO: validate more error messages
                return { status: false, msg: 'Error: Network Error' }
            })
    }

    async getFile(uri) {
        this.setEnviromentUrl()
        // axios.defaults.headers.common['Authorization'] = await db_login.getItem('token')
        delete axios.defaults.headers.common['Authorization']
        const url = API_URL + uri
        return axios
            .get(url, {
                withCredentials: false,
                responseType: 'blob',
                validateStatus: function (status) {
                    if ([401, 402, 403].includes(status)) {
                        window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                        return false
                    }
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
            .catch((error) => {
                // TODO: validate more error messages
                return { status: false, msg: 'Error: Network Error' }
            })
    }

    language(lang) {
        this.setEnviromentUrl()
        const url = API_URL + 'lang/' + lang
        return axios
            .get(url, {
                validateStatus: function (status) {
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
    }

    setEnviromentUrl(){
        if(store){
            const config = store.getters['login/getConfig']

            if(config && config.api){
                API_URL = config.api
            }else{
                API_URL = process.env.VUE_APP_API_URL
            }
        }
    }
}
