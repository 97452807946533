<template>
    <div class="geolocation-container">
        <div class="andy-geolocation-image"></div>
        <div class="content-release">
            <div class="first-paragraph">
                {{ translation('popup.geolocation.paragraph1') }}
            </div>
            <div class="second-paragraph">
                {{ translation('popup.geolocation.paragraph2') }}
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import router from '@/router'

export default {
    props: {
        data: { type: undefined, default: '' }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        translation(translation) {
            return i18n.t(translation)
        }
    },
    created() {},
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.geolocation {
    .pop-footer {
        .buttons {
            margin: 0 !important;

            .act {
                height: 50px;
            }
        }
    }
}

.geolocation-container {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: 100%;

    .andy-geolocation-image {
        @include background($image: img('andy_geolocation.svg'));
        @include align-self(center);
        margin-top: 20px;
        width: 300px;
        height: 150px;
    }

    .first-paragraph,
    .second-paragraph {
        @include font-size(ml);
        color: $neutro-s90;
        font-family: $text;
        margin: 40px;
        margin-top: 20px;
        max-width: 500px;
    }
}
</style>
