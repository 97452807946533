import Template from './filter.vue'
import i18n from '@/i18n'
// import router from '@/router'

let globalOptions = {
  show: false,
  list: {},
  selected: [],
  callback: function () {},
}


let instance

const Init = function (config = {}) {
  let Tpl = this.extend(Template)

  if (!instance) {
    instance = new Tpl()
    config = {
      ...globalOptions,
      ...config
    }
  } else {
    config = {
      ...globalOptions,
      ...instance.$data,
      ...config
    }
  }

  for (let key in config) {
    if (config.hasOwnProperty(key)) {
      instance.$data[key] = config[key]
    }
  }
}

const Show = function (config = {}) {
  Init.call(this, config)

  instance.$data.show = true
  document.body.appendChild(instance.$mount().$el)
}

const Close = function () {
  instance.$data.show = false
  document.body.removeChild(instance.$mount().$el)
  instance = undefined
}


export default {
  install (Vue) {
    Vue.prototype.$filter = Init.bind(Vue)
    Vue.prototype.$filter.show = Show.bind(Vue)
    Vue.prototype.$filter.close = Close.bind(Vue)
  }
}
