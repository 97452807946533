import localforage from 'localforage'

export const db_tasks_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'tasks_queue'
})

export const db_audits_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'audits_queue'
})

export const db_registers_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'registers_queue'
})

export const db_issues_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues_queue'
})

export const db_templates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'templates'
})

export const db_registers = localforage.createInstance({
    name: 'alexdb',
    storeName: 'registers'
})

export const db_registers_schemas = localforage.createInstance({
    name: 'alexdb',
    storeName: 'registers_schemas'
})

export const db_issues = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues'
})

export const db_timers = localforage.createInstance({
    name: 'alexdb',
    storeName: 'timers'
})

export const db_checks_to_sync = localforage.createInstance({
    name: 'alexdb',
    storeName: 'checks_to_sync'
})

export const db_screenlock = localforage.createInstance({
    name: 'alexdb',
    storeName: 'screenlock'
})
