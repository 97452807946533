<template>
    <div id="splashscreeen">
        <transition name="fade">
            <div name="modal" id="overlay" v-if="show">
                <div v-if="locationData.avatar" class="image">
                    <div class="avatar" :style="{ backgroundImage: 'url(' + locationData.avatar + ')' }"></div>
                </div>
                <div class="splash">
                    <div class="alex-face" v-if="alexIcon" :style="{ backgroundImage: 'url(' + alexIcon + ')' }"></div>
                    <LottieAnimation v-else :timeToRepeat="0" :loopAnimation="true" :animationName="'Andy-logo.json'"></LottieAnimation>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LottieAnimation from '../../components/layout/LottieAnimation.vue'
import store from '@/store'

export default {
    components: { LottieAnimation },
    computed: {
        locationData() {
            return store.getters['loginUser/getLocalLocation']
        },
        alexIcon() {
            return store.getters.getAppIcon
        }
    },
    data() {
        return {
            show: false
        }
    },
    created() {}
}
</script>

<style lang="scss">
#splashscreeen {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease;
    }
    .fade-enter-active {
        transition-delay: 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-to,
    .fade-leave {
        opacity: 1;
    }

    #overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fbfbfb;
        width: 100%;
        height: 100%;
        z-index: $z-overlay-default + 2;

        .image {
            width: 280px;
            height: auto;
            min-height: 100px;

            .avatar {
                @include opacity(0.8);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                width: 280px;
                height: 200px;
                cursor: auto;
            }
        }

        .splash {
            width: 35%;
            max-width: 380px;
            height: auto;
            min-width: 300px;

            .alex-face {
                @include background($image: '', $size: 150px, $position: center);
                position: relative;
                cursor: pointer;
                left: -2px;
                height: 150px;
                display: block;

                &:hover {
                    background-size: 150px + 3px;
                }
            }
        }
    }
}
</style>
