<template>
    <div class="calendar-container" :data-id="id">
        <div class="custom">
            {{ val.selectedDate ? format(val.selectedDate) : format(val.currentDate) }}
            <div class="edit" @click="openYearSelector()"></div>
        </div>
        <functional-calendar v-model="val" :dayNames="translation('calendar.day_names')" :monthNames="translation('calendar.months')" :shortMonthNames="translation('calendar.months')" :newCurrentDate="date" @input="updateValue()" :isDatePicker="true" :date-format="'yyyy/mm/dd'" :change-month-function="true" :change-year-function="true" :markedDates="markedDates" :limits="disabledDates" :isModal="isModal" ref="Calendar"> </functional-calendar>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    props: {
        givenValue: { type: String, default: '' },
        pastDates: { type: Boolean, default: false },
        minDate: { type: String, default: undefined },
        maxDate: { type: String, default: undefined },
        isModal: { type: Boolean, default: false }
    },
    data() {
        return {
            val: {},
            date: new Date(),
            markedDates: [],
            id: null
        }
    },
    computed: {
        disabledDates() {
            if (this.pastDates) return { min: '', max: this.today() }
            else if (this.minDate == undefined && this.maxDate == undefined) return { min: '', max: '' }
            else return { min: this.today(), max: '' }
        }
    },
    methods: {
        format(date) {
            return moment(date).format('DD/MM/YYYY')
        },
        translation(translation) {
            return i18n.t(translation)
        },
        updateValue() {
            if (moment(this.val.selectedDate).isValid()) {
                this.emitResult(moment(this.val.selectedDate).format('YYYY/MM/DD'))
                // this.val.selectedDate = this.val.selectedDate
            }
        },
        load() {
            if (this.givenValue !== '') {
                const aDate = this.givenValue.split('/')
                const day = parseInt(aDate[0])
                const month = parseInt(aDate[1])
                const year = parseInt(aDate[2])
                const date = `${year}/${month}/${day}`
                // this.val.selectedDate = date
                this.$refs.Calendar.input.selectedDate = date
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear()

            if (month.length < 2) month = '0' + month
            if (day.length < 2) day = '0' + day

            return [year, month, day].join('-')
        },

        today() {
            var x = new Date()
            var y = x.getFullYear().toString()
            var m = (x.getMonth() + 1).toString()
            var d = x.getDate().toString()
            // d.length == 1 && (d = "0" + d);
            // m.length == 1 && (m = "0" + m);
            var yyyymmdd = y + '/' + m + '/' + d
            return yyyymmdd
        },
        emitResult(result) {
            this.$emit('input', result)
        },
        openYearSelector() {
            var self = this
            setTimeout(function() {
                var element = document.querySelector('.calendar-container[data-id="' + self.id + '"] .vfc-top-date')
                // var element = document.getElementsByClassName('vfc-top-date')[0]
                element.click()
                setTimeout(function() {
                    var element = document.querySelector('.calendar-container[data-id="' + self.id + '"] .vfc-top-date')
                    element.click()
                }, 0)
            }, 0)
        }
    },
    mounted() {
        this.id = this._uid
    },
    created() {
        if (this.givenValue) {
            let date = moment(this.givenValue, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm')
            this.date = new Date(date)
        }
    },
    updated() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.calendar-container {
    @include display-flex();
    @include justify-content();
    width: 100%;
    text-align: center;
    margin-top: rem(18px);
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;

    .custom {
        padding: 0;
        position: relative;
        height: 35px;
        line-height: 35px;
        font-family: $text;
        margin: 10px 0;
        margin-top: 25px;
        @include font-size(s);
        color: #333;

        .edit {
            width: 35px;
            height: 35px;
            display: inline-block;
            position: absolute;
            right: 0;
            @include background($image: img('edit_neutro.svg'), $size: 20px);
            background-position: center;
            border-radius: 3px;
            background-color: $neutro;
            background-color: #fff;
            cursor: pointer;
        }
    }

    .vfc-disabled {
    }

    .vfc-cursor-not-allowed {
    }

    .vfc-day {
        .vfc-hide {
        }
    }

    .vfc-main-container {
        @include border-radius(8px);
        width: 100%;
        height: auto !important;
        padding: 15px;

        .vfc-calendars {
            width: 100%;
            height: 100%;
            background-color: #fff;

            div.vfc-content {
                background-color: #fff;
                width: 100%;
                height: auto;
                margin: 0 !important;
                z-index: 3 !important;

                .vfc-marked {
                    background-color: $main-dark !important;
                }

                .vfc-top-date {
                    position: relative;
                    top: -8px;
                }
            }
        }

        .vfc-navigation-buttons {
            height: 35px;
            .vfc-cursor-pointer {
                width: 35px;
                height: 35px;
                background-color: #eeeeee;

                .vfc-arrow-right {
                    top: -8px;
                    right: 1px;
                }
                .vfc-arrow-left {
                    top: -8px;
                    left: 4px;
                }
                .vfc-disabled {
                    top: 0px;
                }
            }
        }
    }
}
</style>
