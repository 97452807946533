function pressAndLongPress(Vue, options = { longPressDuration: 1000 }) {
    let isLongPressed = false

    Vue.directive('longpress', {
        bind: function (el, binding, vNode) {
            isLongPressed = false

            // Verifica que el valor pasado sea una función
            if (typeof binding.value !== 'function') {
                const compName = vNode.context.name
                let warn = `[longpress:] provided expression '${binding.expression}' is not a function. `
                if (compName) {
                    warn += `Found in component '${compName}' `
                }
                console.warn(warn)
            }

            let pressTimer = null

            const start = (e) => {
                if (e.type === 'click' && e.button !== 0) return

                if (pressTimer === null) {
                    pressTimer = setTimeout(() => {
                        isLongPressed = true
                        handler(e)
                    }, options.longPressDuration) // Usar la duración personalizada
                }
            }

            const cancel = (e) => {
                if (pressTimer !== null) {
                    clearTimeout(pressTimer)
                    pressTimer = null
                }
            }

            const handler = (e) => {
                binding.value(e)
            }

            el.addEventListener('mousedown', start)
            el.addEventListener('touchstart', start)
            el.addEventListener('click', cancel)
            el.addEventListener('mouseout', cancel)
            el.addEventListener('touchend', cancel)
            el.addEventListener('touchcancel', cancel)
        }
    })

    Vue.directive('press', {
        bind: function (el, binding, vNode) {
            if (typeof binding.value !== 'function') {
                const compName = vNode.context.name
                let warn = `[press:] provided expression '${binding.expression}' is not a function. `
                if (compName) {
                    warn += `Found in component '${compName}' `
                }
                console.warn(warn)
            }

            function start(e) {
                if (e.type === 'touchend') {
                    isLongPressed = false
                    return
                }

                if (!isLongPressed) {
                    binding.value(e)
                } else {
                    isLongPressed = false
                }
            }

            el.addEventListener('click', start)
            el.addEventListener('touchend', start)
            el.addEventListener('touchcancel', start)
        }
    })
}

export default pressAndLongPress
