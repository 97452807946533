import Vue from 'vue'
import i18n from '@/i18n'
import { Api } from '@/core/api.js'
import * as bpac from '@/../public/js/bpac.js'

import localforage from 'localforage'
const api = new Api()

const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        printer: false,
        models: false,
        templates: false,
        labels: false,
        config: false,
        current_printer: false,
        update_template: false,
        status_printer: true,
        check_status_printer: false,
        error_printer: false,
        connect_to_printer: false,
        newPrinterConfig: { name: '', model: false, status: 1, mode: false, mac: '', ip: '', templates: {}, path: '', platform: process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.platformId : 'windows' },
        current_printer_config: false,
        copy_current_config: false,
        device_with_new_configuration: localStorage.printer_device_new_configuration ? localStorage.printer_device_new_configuration == 'true' : false,

        // VARIABLE OF CONTROL TO PREVENT VALIDATE WHEN ENTERS TO LABELS OR CONFIG PRINTERS
        isValidatebPac: false,

        // VARIABLES TEMPLATES COUNT
        num_total_templates: -1,
        num_current_templates: 1,

        // VARIABLE CONTROL CONFIGURATION ZEBRA BLUETOOTH
        status_zebra_configuration: -1,

        firstBackButton: true
    }
}

// initial state
const state = getDefaultState()

;(async() => {
    const printer = await db_login.getItem('printer')
    state.config = printer && printer.config ? Object.assign({}, printer.config) : {}
    state.models = printer && printer.models ? Object.assign({}, printer.models) : []
    state.templates = printer && printer.templates ? Object.assign({}, printer.templates) : []
    state.labels = printer && printer.labels ? Object.assign({}, printer.labels) : []
    const current_printer = await db_login.getItem('current_printer')
    state.current_printer = current_printer || false
})()

// getters
const getters = {
    getFormat: (state) => (id) => {
        let result = false

        let printer = state.printer
        let oTemplates = state.templates ? state.templates : printer && printer.templates ? printer.templates : {}

        // let keysTpl = Object.keys(oTemplates)
        // let tpl = oTemplates[state.config.template_id]
        for (var key in oTemplates) {
            let tpl = oTemplates[key]

            if (tpl) {
                console.log('tpl', tpl)
                let keysFormats = Object.keys(tpl.formats)

                if (keysFormats) {
                    let fId = id || keysFormats[0]
                    result = typeof tpl.formats[fId] !== 'undefined' ? tpl.formats[fId] : result
                }
            }
        }

        // if (tpl) {
        //   let keysFormats = Object.keys(tpl.formats)

        //   if (keysFormats) {
        //     let fId = id || keysFormats[0]
        //     result = tpl.formats[fId]
        //   }
        // }

        return result
    },

    getFormatFromTpl: (state) => (tpl_id, format_id) => {
        let result = false

        let printer = state.printer
        let oTemplates = state.templates ? state.templates : printer && printer.templates ? printer.templates : {}

        let keysTpl = Object.keys(oTemplates)
        if (keysTpl) {
            let tId = tpl_id || keysTpl[0]
            let tpl = oTemplates[tId]

            if (tpl) {
                let keysFormats = Object.keys(tpl.formats)

                if (keysFormats) {
                    let fId = format_id || keysFormats[0]
                    result = tpl.formats[fId]
                }
            }
        }

        return result
    },

    getPrinter: (state, getters, rootState) => {
        const storage = rootState.login.printer ? rootState.login.printer : {}
        return state.printer ? state.printer : storage
    },

    getConfig: (state, getters, rootState) => {
        // TODO Deprecated
        const storage = rootState.login.printer && rootState.login.printer.config ? rootState.login.printer : []
        log('getConfig', storage)
        log('getConfig - state ', state.config)
        return state.config ? state.config : storage.config
    },

    getModels: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return Object.assign({}, state.models)
        } else if (typeof state.models[id] !== 'undefined') {
            return Object.assign({}, state.models[id])
        } else {
            return false
        }
    },

    getLabels: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return Object.assign({}, state.labels)
        } else if (typeof state.labels[id] !== 'undefined') {
            return Object.assign({}, state.labels[id])
        } else {
            return false
        }
    },

    getLabelsByPrinterModel: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return false
        } else {
            var labels = {}
            for (var l in state.labels) {
                if (state.labels[l].printer_id == id) {
                    labels[l] = Object.assign({}, state.labels[l])
                }
            }

            return labels
        }
    },

    getTemplates: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return Object.assign({}, state.templates)
        } else if (typeof state.templates[id] !== 'undefined') {
            return Object.assign({}, state.templates[id])
        } else {
            return false
        }
    },

    getTemplatesByPrinterModel: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return false
        } else {
            var templates = {}
            for (var t in state.templates) {
                if (state.templates[t].printer_id == id) {
                    templates[t] = Object.assign({}, state.templates[t])
                }
            }

            return templates
        }
    },

    getPrinterConfig: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') return state.config
        if (typeof state.config[id] === 'undefined') return false
        return Object.assign({}, state.config[id])
    },

    getCurrentPrinter: (state, getters, rootState) => {
        if (!state.current_printer) return false
        if (typeof state.config[state.current_printer] === 'undefined ') return false
        return state.current_printer
    },

    getCurrentPrinterConfig: (state, getters, rootState) => {
        if (!state.current_printer_config) return false
        if (typeof state.config[state.current_printer_config] === 'undefined ') return false
        return Object.assign({}, state.config[state.current_printer_config])
    },

    getCopyCurrentPrinterConfig: (state, getters, rootState) => {
        return state.copy_current_config
    },

    getUpdateTemplate: (state, getters, rootState) => {
        return state.update_template
    },

    getStatusPrinter: (state, getters, rootState) => {
        return state.status_printer
    },

    getStatusErrorPrinter: (state, getters, rootState) => {
        return state.error_printer
    },

    connectToPrinter: (state, getters, rootState) => {
        return state.connect_to_printer
    },

    isCheckingStatusPrinter: (state, getters, rootState) => {
        return state.check_status_printer
    },

    getDeviceNewConfiguration: (state, getters, rootState) => {
        return state.device_with_new_configuration
    },

    getIsValidatebPac: (state, getters, rootState) => {
        return state.isValidatebPac
    },

    getProcessingTemplates: (state, getters, rootState) => {
        return { current: state.num_current_templates, total: state.num_total_templates }
    },

    getFirstBackButton: (state, getter, rootState) => {
        return state.firstBackButton
    },

    getStatusZebraConfiguration: (state, getter, rootState) => {
        return state.status_zebra_configuration
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    // TODO PUSH SET PRINTER INFO AFTER CALL COMMIT SETPRINTER
    loadPrinter(context) {
        log(state, context.state)
        if (!context.state) context.commit('resetState')
        var printer = false
        if (state.printer) {
            var printer = state.printer
            var config = printer.config ? printer.config : false
        }
        // log('setCurrentPrint', printer)
        return true // context.commit('setCurrentPrint', printer)
    },

    savePrinterData(context, printer) {
        log('---savePrinterData---', printer)
        // log(printer.config)
        // log({ ...context.getters.getConfig })
        // log(deepCompare({ ...printer.config }, { ...context.getters.getConfig }))

        return context.dispatch('setPrinterData', printer.printers).then(() => {
            log('FINISH SET PRINTER DATA')
            return context.dispatch('setPrinter_Config', printer.config).then(() => {
                log('FINISH SET CONFIG')
                return true
            })
        })

        // TODO SOLO DESCOMENTAR SI SE REVISA EL PROCESO DE MACHAQUE, ESTA DANDO PROBLEMAS
        // if (deepCompare({ ...printer.config }, { ...context.getters.getConfig })) {
        //     // Misma configuración
        //     return context.dispatch('setPrinterData', printer.printers)
        //     return true
        // } else if (deepCompare({}, { ...context.getters.getConfig })) {
        //     // Tenemos configuración en el servidor pero no en el dispositivo
        //     context.dispatch('setConfig', printer.config)
        //     return context.dispatch('setPrinterData', printer.printers)
        // } else {
        //     // Al tener una configuración en el dispositivo actualizamos datos del servidor
        //     var printerDeviceConfig = JSON.stringify({ ...context.getters.getConfig })
        //     context.dispatch('sendPrinterInfo', printerDeviceConfig)
        //     return context.dispatch('setPrinterData', printer.printers)
        // }
    },

    setPrinterData(context, data) {
        var oPrinters = []
        var oTemplates = []
        var oLabels = []
        for (var p in data) {
            var printer = Object.assign({}, data[p])
            oPrinters[printer.id] = {
                id: printer.id,
                image: printer.image,
                code: printer.code,
                name: printer.name,
                sdk: printer.config ? printer.config.sdk : printer.sdk,
                config: printer.config ? printer.config : {}
            }

            for (var l in printer.labels) {
                var label = Object.assign({}, printer.labels[l])
                label.printer_id = printer.id
                // label.templates = []
                oLabels[l] = label
            }

            for (var t in printer.templates) {
                var template = Object.assign({}, printer.templates[t])
                template.printer_id = printer.id
                oTemplates[t] = template
                // oLabels[template.label_id].templates.push(template.id)
            }
        }

        log('setPrinterData - printers', oPrinters)
        log('setPrinterData - templates', oTemplates)
        log('setPrinterData - labels', oLabels)

        context.commit('setPrinterModels', oPrinters)
        context.commit('setTemplates', oTemplates)
        context.commit('setLabels', oLabels)
    },

    setPrinter_Config(context, params) {
        return new Promise((resolve) => {
            log('ACTION CONFIG - printer.js')
            var printerStructure = {
                models: context.getters['getModels'](),
                templates: context.getters['getTemplates'](),
                labels: context.getters['getLabels']()
            }
            log('printer', printerStructure)
            log('config', { ...params })
            var currentPrinterConfigs = Object.assign({}, params)
            var printerConfigs = {}
            var bEmptyPrinterConfigFound = false
            for (var pc in currentPrinterConfigs) {
                if (!(currentPrinterConfigs[pc].name == '' || currentPrinterConfigs[pc].model == false)) {
                    printerConfigs[pc] = Object.assign({}, currentPrinterConfigs[pc])
                } else {
                    if (pc == state.current_printer) {
                        context.commit('setCurrentPrinter', false)
                    }
                    bEmptyPrinterConfigFound = true
                }
            }
            printerStructure.config = Object.assign({}, printerConfigs)

            db_login.setItem('printer', Object.assign({}, printerStructure), async function() {
                log('FINISH SET CONFIG ON LOCALFORAGE')
                context.commit('setConfig', printerConfigs)
                if (bEmptyPrinterConfigFound) {
                    context.dispatch('update').then(() => {
                        log('FINISH SET CONFIG ON STORE AFTER REMOVE EMPTY CONFIGURATIONS')
                        resolve()
                    })
                } else {
                    log('FINISH SET CONFIG ON STORE')
                    resolve()
                }
            })
        })
    },

    setCurrentPrinter(context, config_id) {
        return new Promise((resolve) => {
            db_login.setItem('current_printer', config_id, async function() {
                context.commit('setCurrentPrinter', config_id)
                resolve()
            })
        })
    },

    deletePrinterConfig(context, config_id) {
        return new Promise((resolve, reject) => {
            context.commit('deletePrinterConfig', config_id)
            context
                .dispatch('update')
                .then((response) => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // setPrinter(context, printer) {
    //     if (!context.state) context.commit('resetState')
    //     return context.commit('setPrinter', printer)
    // },

    // updateConfig(context, params) {
    //     log('updateConfig', state)
    //     if (!context.state) context.commit('resetState')
    //     // return context.dispatch('setConfig', params).then(() => {
    //     //     return true
    //     // })
    //     return context.commit('setConfig', params)
    // },

    loadPrinterInfo(context, params) {
        if (!context.state) context.commit('resetState')
        log('-> LOAD PRINTER INFO')

        if (fnCheckConnection()) {
            return api
                .post('printer', {})
                .then(function(response) {
                    var data = response.data
                    log('PRINTER INFO --> ', data)
                    return context.dispatch('savePrinterData', data).then(() => {
                        return true
                    })
                })
                .catch((error) => {
                    return false
                })
        }
    },

    sendPrinterInfo(context, info) {
        if (!context.state) context.commit('resetState')

        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2

        if (fnCheckConnection()) {
            return api.post('location/printer/update', { data: info, version: appVersion, platform: platform }).then(function(response) {
                //
                context.dispatch('setConfig', JSON.parse(info))
            })
        } else {
            context.dispatch('setConfig', JSON.parse(info))
            return true
        }
    },

    checkPrinterConfiguration(context, data) {
        if (!state) commit('resetState')

        log('--------------- checkPrinterConfiguration -------------- ')
        var bErrorFound = false
        var current_printer = context.getters.getCurrentPrinter
        var config = null
        if (!current_printer || !context.getters.getPrinterConfig(current_printer)) {
            var printersConfig = context.getters.getPrinterConfig()
            for (var p in printersConfig) {
                if (config == null) {
                    var pc = printersConfig[p]
                    if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'TRUE') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'TRUE')) {
                        config = Object.assign({}, pc)
                    }
                }
            }
        } else {
            config = Object.assign({}, context.getters.getPrinterConfig(current_printer))
        }
        if (config) {
            var printer = context.getters.getModels(config.model)
            if (printer.sdk == 'brother' && process.env.VUE_APP_IS_APP === 'TRUE') {
                var labels = context.getters.getLabelsByPrinterModel(config.model)

                for (var l in labels) {
                    var label = labels[l]
                    log('-> label: ', label)
                    if (!label.tpl_file_app.includes('.z')) {
                        // Only validate labels to print template, not to print ZPL
                        if (typeof config.templates[label.id] === 'undefined') {
                            bErrorFound = true
                        } else if (moment(label.created_date).valueOf() > config.templates[label.id].date) {
                            bErrorFound = true
                        }
                    }
                }
            }

            return context.commit('setUpdateTemplate', bErrorFound ? config.id : false)
        } else {
            return context.commit('setUpdateTemplate', false)
        }
    },

    sync(context, config_id) {
        log('-- sync --')
        return new Promise((resolve, reject) => {
            var config = context.getters['getPrinterConfig'](config_id)
            var printer = context.getters['getModels'](config.model)
            log('config -> ', config)
            log('printer -> ', printer)
            context.commit('setProcessingTemplates', false)
            if (printer) {
                if (process.env.VUE_APP_IS_APP !== 'TRUE') {
                    // CASE ZEBRA || BROTHER WINDOWS JUST SAVE CONFIGURATION TO SERVER
                    return context
                        .dispatch('update')
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else if (printer.sdk == 'zebra') {
                    // CASE ZEBRA WITH RECONFIGURATION BY BLUETOOTH, BEFORE SAVE CONFIGURATION NEED TO CONFIGURE PRINTER
                    log('CONFIGURAMOS ZEBRA PRINTER')
                    if (printer.config.connection == 'ALL' && config.reconfigure) {
                        log('CONFIGURAMOS ZEBRA WIFI POR BLUETOOH')
                        return context
                            .dispatch('configureWIFI', { config: config_id, printer: printer.id })
                            .then((response) => {
                                resolve(response)
                            })
                            .catch((error) => {
                                log('ERROR - CONFIGURAMOS ZEBRA WIFI', error)
                                reject(error)
                            })
                    } else {
                        return context
                            .dispatch('configureBT', { config: config_id, printer: printer.id })
                            .then((response) => {
                                resolve(response)
                            })
                            .catch((error) => {
                                log('ERROR - CONFIGURAMOS ZEBRA BLUETOOH', error)
                                reject(error)
                            })
                    }
                } else {
                    // CASE BROTHER ANDROID, BEFORE SAVE CONFIGURATION NEED TO DOWNLOAD ALL FILES
                    return context
                        .dispatch('removeTemplates', { config: config_id, printer: printer.id })
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                }
            } else {
                // TODO RETURN ERROR PRINTER MODEL DOESN'T EXISTS
                reject({ status: false, code: 'C-S1', msg: 'config.printer.errors.no_config' })
            }
        })
    },

    configureWIFI(context, params) {
        return new Promise((resolve, reject) => {
            var config = context.getters['getPrinterConfig'](params.config)
            log('configureWIFI', config)
            context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.connecting')

            cordova.plugins.zebraprinter.isConnected(
                (connected) => {
                    if (connected) {
                        log('configureWIFI - PRINTER CONNECTED')
                        return context
                            .dispatch('sendTemplateConfiguration', params)
                            .then((response) => {
                                log('configureWIFI - ALL OK - ', response)
                                resolve(response)
                            })
                            .catch((error) => {
                                log('configureWIFI - ERROR - ', error)
                                if (!error || !error.msg) {
                                    error = { status: false, msg: 'config.printer.errors.zebra_wifi' }
                                }
                                reject(error)
                            })
                    } else {
                        log('configureWIFI - PRINTER NOT CONNECTED')
                        return context
                            .dispatch('connectZebraPrinter', { num_tries: 3, fc: 'sendTemplateConfiguration', config: config.id })
                            .then((response) => {
                                log('configureWIFI - ALL OK  1 - ', response)
                                resolve(response)
                            })
                            .catch((error) => {
                                log('configureWIFI - ERROR  1- ', JSON.stringify(error))
                                if (!error || !error.msg) {
                                    error = { status: false, msg: 'config.printer.errors.zebra_wifi' }
                                }
                                reject(error)
                            })
                    }
                },
                (error) => {
                    log('ERROR TO GET IF IS CONNECTED', error)
                    if (!error || !error.msg) {
                        error = { status: false, msg: 'config.printer.errors.zebra_wifi' }
                    }
                    reject(error)
                }
            )
        })
    },

    configureBT(context, params) {
        return new Promise((resolve, reject) => {
            var config = context.getters['getPrinterConfig'](params.config)
            log('configureBT', config)
            context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.connecting')

            cordova.plugins.zebraprinter.isConnected(
                (connected) => {
                    if (connected) {
                        log('configureWIFI - PRINTER CONNECTED')
                        return context
                            .dispatch('sendBluetoothConfiguration', params)
                            .then((response) => {
                                log('configureBT - ALL OK - ', response)
                                resolve(response)
                            })
                            .catch((error) => {
                                log('configureBT - ERROR - ', error)
                                if (!error || !error.msg) {
                                    error = { status: false, msg: 'config.printer.errors.zebra_bt' }
                                }
                                reject(error)
                            })
                    } else {
                        log('configureBT - PRINTER NOT CONNECTED')
                        return context
                            .dispatch('connectZebraPrinter', { num_tries: 3, fc: 'sendBluetoothConfiguration', config: config.id })
                            .then((response) => {
                                log('configureBT - ALL OK  1 - ', response)
                                resolve(response)
                            })
                            .catch((error) => {
                                log('configureBT - ERROR  1- ', JSON.stringify(error))
                                if (!error || !error.msg) {
                                    error = { status: false, msg: 'config.printer.errors.zebra_bt' }
                                }
                                reject(error)
                            })
                    }
                },
                (error) => {
                    log('ERROR TO GET IF IS CONNECTED', error)
                    if (!error || !error.msg) {
                        error = { status: false, msg: 'config.printer.errors.zebra_bt' }
                    }
                    reject(error)
                }
            )
        })
    },

    disconnectZebraPrinter(context, params) {
        cordova.plugins.zebraprinter.disconnect(
            () => {
                log('PRINTER DISCONNECTED')
            },
            () => {
                log('ERROR PRINTER DISCONNECTED')
            }
        )
    },

    connectZebraPrinter(context, params) {
        log('connectZebraPrinter', JSON.parse(JSON.stringify(params)))
        return new Promise((resolve, reject) => {
            if (params.num_tries <= 0) reject({ status: false, msg: 'config.printer.errors.default' })
            else {
                var config = context.getters['getPrinterConfig'](params.config)
                log('connectZebraPrinter', config)
                params.num_tries--
                cordova.plugins.zebraprinter.connect(
                    config.mac,
                    (data) => {
                        log('CONNECTED TO ZEBRA PRINTER')
                        params.num_tries = 4
                        return context
                            .dispatch(params.fc, params)
                            .then((response) => {
                                log('connectZebraPrinter - ALL OK 2 - ', response)
                                resolve(response)
                            })
                            .catch((error) => {
                                log('connectZebraPrinter - ERROR 2 - ', error)
                                reject(error)
                            })
                    },
                    (erro) => {
                        log('ERROR CONNECT ZEBRA PRINTER', erro)
                        if (context.rootGetters.getIsAndroid) {
                            return context
                                .dispatch('connectZebraPrinter', params)
                                .then((response) => {
                                    log('connectZebraPrinter - ALL OK 3 - ', response)
                                    resolve(response)
                                })
                                .catch((error) => {
                                    log('connectZebraPrinter - ERROR 3 - ', error)
                                    reject(error)
                                })
                        } else {
                            return setTimeout(() => {
                                context
                                    .dispatch('connectZebraPrinter', params)
                                    .then((response) => {
                                        log('connectZebraPrinter - ALL OK 3 - ', response)
                                        resolve(response)
                                    })
                                    .catch((error) => {
                                        log('connectZebraPrinter - ERROR 3 - ', JSON.stringify(error))
                                        reject(error)
                                    })
                            }, 5000)
                        }
                    }
                )
            }
        })
    },

    sendTemplateConfiguration(context, params) {
        return new Promise((resolve, reject) => {
            context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.sending_configuration')

            var config = context.getters['getPrinterConfig'](params.config)
            let templateConfiguration = '^XA' + '^WIA' + '^NC2' + '^NPP' + '^KC0,0,,' + '^WAD,D' + '^WEOFF,1,,,,,,' + '^WP0,0' + '^WR,,,,100' + '^WS' + config.ssid + ',I,L,,,' + '^NBS' + '^WLOFF,,' + '^WKOFF,,,,' + '^WX09,' + getWpaPskKeyFromPassphrase(config.password, config.ssid) + '^XZ' + '^XA' + '^JUS' + '^XZ' + '! U1 setvar "wlan.country_code" "europe"' + '! U1 setvar "wlan.international_mode" "off"' + '! U1 setvar "wlan.allowed_band" "all"' + '! U1 do "device.reset" ""'

            cordova.plugins.zebraprinter.print(
                templateConfiguration,
                (data) => {
                    log('TEMPLATE WIFI CONFIGURATION SENDED', data)
                    setTimeout(() => {
                        log('GETTING STATUS PRINTER')
                        cordova.plugins.zebraprinter.printerStatus(
                            (data) => {
                                log('PRINTER STATUS ', JSON.parse(JSON.stringify(data)))
                                context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.reset_printer')
                                if (!data.connected) {
                                    // TODO LAUNCH PROCESS TO DETECT WHEN PRINTER IS AVAILABLE AGAIN
                                    context
                                        .dispatch('connectZebraPrinter', { num_tries: 20, fc: 'getZebraWIFIConfiguration', config: config.id })
                                        .then((zebraConfig) => {
                                            context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.saving_config')
                                            log('GETTING DATA FROM PRINTER', JSON.stringify(zebraConfig))
                                            config.ip = zebraConfig.ip
                                            delete config.password
                                            cordova.plugins.zebraprinter.disconnect(
                                                () => {
                                                    log('PRINTER DISCONNECTED')
                                                },
                                                () => {
                                                    log('ERROR PRINTER DISCONNECTED')
                                                }
                                            )
                                            context.commit('setPrinterConfig', config)
                                            context
                                                .dispatch('update')
                                                .then((response) => {
                                                    resolve(response)
                                                })
                                                .catch((error) => {
                                                    log('ERROR ON UPDATE -> ', error)
                                                    reject(error)
                                                })
                                        })
                                        .catch((error) => {
                                            cordova.plugins.zebraprinter.disconnect(
                                                () => {
                                                    log('PRINTER DISCONNECTED')
                                                },
                                                () => {
                                                    log('ERROR PRINTER DISCONNECTED')
                                                }
                                            )
                                            reject(error)
                                        })
                                }
                            },
                            (err) => {
                                context
                                    .dispatch('connectZebraPrinter', { num_tries: 20, fc: 'getZebraWIFIConfiguration', config: config.id })
                                    .then((zebraConfig) => {
                                        context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.saving_config')
                                        log('GETTING DATA FROM PRINTER', JSON.stringify(zebraConfig))
                                        config.ip = zebraConfig.ip
                                        delete config.password
                                        cordova.plugins.zebraprinter.disconnect(
                                            () => {
                                                log('PRINTER DISCONNECTED')
                                            },
                                            () => {
                                                log('ERROR PRINTER DISCONNECTED')
                                            }
                                        )
                                        context.commit('setPrinterConfig', config)
                                        context
                                            .dispatch('update')
                                            .then((response) => {
                                                resolve(response)
                                            })
                                            .catch((error) => {
                                                log('ERROR ON UPDATE -> ', error)
                                                reject(error)
                                            })
                                    })
                                    .catch((error) => {
                                        cordova.plugins.zebraprinter.disconnect(
                                            () => {
                                                log('PRINTER DISCONNECTED')
                                            },
                                            () => {
                                                log('ERROR PRINTER DISCONNECTED')
                                            }
                                        )
                                        reject(error)
                                    })
                            }
                        )
                    }, 5000)
                    // TODO CHECK IF THIS PETITION RETURN IP, MAYBE NEED TO GET A NEW CONNECTION
                },
                (err) => {
                    log('ERROR TO PRINT', err)
                    reject()
                }
            )
        })
    },

    sendBluetoothConfiguration(context, params) {
        return new Promise((resolve, reject) => {
            context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.sending_configuration')

            var config = context.getters['getPrinterConfig'](params.config)
            let templateConfiguration = '! U1 setvar "card.enable" "on"' + '! U1 setvar "power.energy_star.enable" "off"' + '! U1 setvar "power.inactivity_timeout" "0"' + '! U1 setvar "power.dtr_power_off" "off"' + '! U1 setvar "power.inactivity_timeout" "0"' + '! U1 setvar "power.inactivity_timeout_alt" "0"' + '! U1 setvar "power.energy_star.enable" "off"'

            cordova.plugins.zebraprinter.print(
                templateConfiguration,
                (data) => {
                    log('TEMPLATE BT CONFIGURATION SENDED', data)
                    cordova.plugins.zebraprinter.disconnect(
                        () => {
                            log('PRINTER DISCONNECTED')
                        },
                        () => {
                            log('ERROR PRINTER DISCONNECTED')
                        }
                    )
                    context.commit('setPrinterConfig', config)
                    context
                        .dispatch('update')
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            log('ERROR ON UPDATE -> ', error)
                            reject(error)
                        })
                },
                (err) => {
                    log('ERROR TO PRINT', err)
                    reject(err)
                }
            )
        })
    },

    getZebraWIFIConfiguration(context, params) {
        log('getZebraWifiConfiguration', JSON.parse(JSON.stringify(params)))
        return new Promise((resolve, reject) => {
            if (params.num_tries <= 0) reject({ status: false, msg: 'config.printer.errors.zebra_wifi' })
            else {
                params.num_tries--
                context.commit('setStatusZebraConfiguration', 'config.printer.ZEBRA.status.getting_configuration')

                return setTimeout(() => {
                    log('getZebraWifiConfiguration getting status')
                    cordova.plugins.zebraprinter.printerStatus(
                        (data) => {
                            log('getZebraWifiConfiguration - STATUS', data)
                            if (data.ip && data.ip != '0.0.0.0') {
                                resolve(data)
                            } else {
                                log('getZebraWifiConfiguration - wrong ip')
                                context
                                    .dispatch('getZebraWIFIConfiguration', params)
                                    .then((response) => {
                                        resolve(response)
                                    })
                                    .catch((error) => {
                                        reject({ status: false, msg: 'config.printer.errors.zebra_wifi' })
                                    })
                            }
                        },
                        (err) => {
                            logError(err)
                            reject({ status: false, msg: 'config.printer.errors.zebra_wifi' })
                        }
                    )
                }, 5000)
            }
        })
    },

    removeTemplates(context, params) {
        return new Promise((resolve, reject) => {
            var config = context.getters['getPrinterConfig'](params.config)
            var printer = context.getters['getModels'](config.model)
            var oLabels = context.getters['getLabelsByPrinterModel'](printer.id)

            // Delete only templates that we doesn't update later
            var templates = []
            for (var t in config.templates) {
                if (typeof oLabels[t] === 'undefined') {
                    templates.push(config.templates[t].position)
                }
            }
            log('Remove templates, templates --> ', templates, {
                model: printer.code,
                port: config.mode,
                ipAddress: config.ip,
                macAddress: config.mac,
                orientation: 'LANDSCAPE'
            })

            cordova.plugins.brotherprinter.removeTemplates(
                templates,
                {
                    model: printer.code,
                    port: config.mode,
                    ipAddress: config.ip,
                    macAddress: config.mac,
                    orientation: 'LANDSCAPE'
                },
                function(response) {
                    config.templates = {}
                    context.commit('setPrinterConfig', config)
                    context
                        .dispatch('processFiles', { config: config.id, printer: printer.id })
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                },
                function(error) {
                    // TODO RETURN ERROR
                    log('Error removing templates -> ', error)
                    reject({ status: false, code: 'C-R1', msg: 'config.printer.errors.no_config' })
                }
            )
        })
    },

    processFiles(context, params) {
        return new Promise((resolve, reject) => {
            log('-- processFiles --')
            log('params -> ', params)
            var files = []
            var filesTransfer = []
            var labels = context.getters.getLabelsByPrinterModel(params.printer)
            for (var l in labels) {
                var label = labels[l]
                if (!label.tpl_file_app.includes('.z')) {
                    let tpl_file_app_url = label.tpl_file_app_url ? label.tpl_file_app_url : process.env.VUE_APP_API_URL + 'static/printer/' + label.tpl_file_app
                    files.push({ fileurl: tpl_file_app_url, filename: label.tpl_file_app, type: 'template' })
                    filesTransfer.push({ filename: label.tpl_file_app, label: label.id, type: 'template' })
                }
                let printer_file_url = label.printer_file_url ? label.printer_file_url : process.env.VUE_APP_API_URL + 'static/printer/' + label.printer_file
                files.push({ fileurl: printer_file_url, filename: label.printer_file, type: 'printer' })
            }
            context.commit('setProcessingTemplates', { total: filesTransfer.length })

            log('files --> ', JSON.parse(JSON.stringify(files)))
            log('filesTransfer --> ', JSON.parse(JSON.stringify(filesTransfer)))

            context
                .dispatch('download', files)
                .then((response) => {
                    context
                        .dispatch('transfer', { config: params.config, files: filesTransfer })
                        .then((response) => {
                            context
                                .dispatch('update')
                                .then((response) => {
                                    resolve()
                                })
                                .catch((error) => {
                                    log('ERROR ON UPDATE -> ', error)
                                    reject(error)
                                })
                        })
                        .catch((error) => {
                            log('ERROR ON UPDATE -> ', error)
                            reject(error)
                        })
                })
                .catch((error) => {
                    log('ERROR ON DOWNLOAD -> ', error)
                    reject(error)
                })
        })
    },

    download(context, files) {
        return new Promise((resolve, reject) => {
            // If Queue files length == 0 then call to resolve function
            // Else if, device hasn't connection to internet call reject function
            // Else, try to download file

            log('-- download --')
            log('files --> ', files)

            if (files.length === 0) resolve()
            if (!fnCheckConnection()) reject({ status: false, msg: 'config.printer.errors.connection' })

            var file = files.pop()
            var fileTransfer = new FileTransfer()
            fileTransfer.download(
                file.fileurl,
                context.rootGetters.getPathFiles.replace('file://', '') + 'printer/' + file.filename,
                function(entry) {
                    log('Successful download...')
                    log('download complete: ' + entry.toURL())

                    // Recursive call
                    return context
                        .dispatch('download', files)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                },
                function(error) {
                    log('download error source ' + error.source)
                    log('download error target ' + error.target)
                    log('error', error)

                    reject({ status: false, code: 'C-D' + error.code, msg: 'config.printer.errors.no_config' })
                },
                true, // or, pass false
                {
                    // headers: {
                    //    "Authorization": "Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA=="
                    // }
                }
            )
        })
    },

    transfer(context, data) {
        return new Promise((resolve, reject) => {
            log('-- transfer --')
            log('data --> ', data)
            if (data.files.length === 0) resolve()

            var config = context.getters['getPrinterConfig'](data.config)
            var printer = context.getters['getModels'](config.model)
            var labelTemplate = data.files.pop()
            log('labelTemplate --> ', labelTemplate)
            log('printer info --> ', {
                model: printer.code,
                port: config.mode,
                ipAddress: config.ip,
                macAddress: config.mac,
                orientation: 'LANDSCAPE'
            })
            cordova.plugins.brotherprinter.addTemplate(
                {
                    path: context.rootGetters.getPathFiles.replace('file://', '') + 'printer/' + labelTemplate.filename
                },
                {
                    model: printer.code,
                    port: config.mode,
                    ipAddress: config.ip,
                    macAddress: config.mac,
                    orientation: 'LANDSCAPE'
                },
                function(response) {
                    // Recursive call
                    log('SEND TEMPLATE:', response)
                    config.templates[labelTemplate.label] = { position: response.key, date: moment().valueOf() }
                    context.commit('setPrinterConfig', config)
                    context.commit('setProcessingTemplates', { total: context.getters.getProcessingTemplates.total, current: context.getters.getProcessingTemplates.current + 1 })
                    return context
                        .dispatch('transfer', data)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                },
                function(err) {
                    log(err)
                    reject({ status: false, code: 'C-T' + err.code, msg: 'config.printer.errors.no_config' })
                }
            )
        })
    },

    update(context, params) {
        return new Promise((resolve, reject) => {
            var config = Object.assign({}, context.getters['getConfig'])
            if (fnCheckConnection()) {
                return api.post('location/printer/update', { data: JSON.stringify(config) }).then(function(response) {
                    context
                        .dispatch('setPrinter_Config', config)
                        .then((response) => {
                            context.commit('setDeviceNewConfiguration', true)
                            context.commit('setStatusZebraConfiguration', -1)
                            resolve()
                        })
                        .catch((error) => {
                            reject({ status: false, code: 'C-U1', msg: 'config.printer.errors.no_config' })
                        })
                })
            } else {
                reject({ status: false, msg: 'config.printer.errors.connection' })
            }
        })
    },

    printLabel(context, params) {
        log(params)
        return new Promise((resolve, reject) => {
            var config = context.getters.getPrinterConfig(params.context.config)
            var printer = context.getters.getModels(params.context.printer)
            var template = context.getters.getTemplates(params.context.template)
            var label = context.getters.getLabels(params.context.label)

            if (config && printer && template && label) {
                log('-------- printing label ---------------')
                log('printData -> ', params.printData)
                log('config -> ', config)
                log('printer -> ', printer)
                log('template -> ', template)
                log('label -> ', label)
                // Vue.prototype.$snackbar.info({ message: i18n.t('tooltip.print') })

                // -- APP PRINT
                if (process.env.VUE_APP_IS_APP === 'TRUE') {
                    // self.labelSelected = false
                    log('---- PRINT ----')

                    if ((config.templates[label.id] || printer.sdk == 'zebra') && !context.getters.connectToPrinter) {
                        context.commit('setConnectToPrinter', true)
                        switch (printer.sdk) {
                            case 'brother':
                                context
                                    .dispatch('printBrother', {
                                        config: config,
                                        data: params,
                                        context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id }
                                    })
                                    .then(function() {
                                        log('RESOLVE - printLabel')
                                        context.commit('setConnectToPrinter', false)
                                        resolve()
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        context.commit('setConnectToPrinter', false)
                                        reject(e)
                                    })
                                break
                            case 'zebra':
                                context
                                    .dispatch('printZebra', {
                                        data: params.printData,
                                        config: config,
                                        context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id }
                                    })
                                    .then(function() {
                                        log('RESOLVE - printLabel')
                                        context.commit('setConnectToPrinter', false)
                                        resolve()
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        context.commit('setConnectToPrinter', false)
                                        reject(e)
                                    })
                                break
                            // TODO DEFINE WHAT'S HAPPEN WHEN NO SDK DEFINED
                        }
                    } else {
                        // TODO RETURN THAT TEMPLATE IS NOT LOADED ON PRINTER
                        reject('config.printer.errors.default')
                    }
                } else {
                    // NOT APP - DEBUG INFO
                    log('PRINTING ON WINDOWS - DEBUG INFO')
                    log(label)
                    log(printer)
                    log(config)

                    if (printer.sdk == 'zebra') {
                        context
                            .dispatch('printZebraBrowser', {
                                data: params.printData,
                                num_copies: params.num_copies,
                                context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id }
                            })
                            .then(function() {
                                context.commit('setConnectToPrinter', false)
                                resolve()
                            })
                            .catch(function(e) {
                                context.commit('setConnectToPrinter', false)
                                reject(e)
                            })
                    } else {
                        if (label.tpl_file_windows) {
                            // Windows configured
                            context
                                .dispatch('printWindows', {
                                    data: params.printData,
                                    num_copies: params.num_copies,
                                    context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id }
                                })
                                .then(function() {
                                    context.commit('setConnectToPrinter', false)
                                    resolve()
                                })
                                .catch(function(e) {
                                    context.commit('setConnectToPrinter', false)
                                    reject(e)
                                })
                        } else {
                            reject('config.printer.errors.default')
                        }
                    }
                }
            } else {
                log('-- PRINTER IS NOT CONFIGURED')
                reject('config.printer.errors.default')
            }
        })
    },

    printQueueLabels(context, params) {
        var current_printer = context.getters.getCurrentPrinter
        var config = null
        if (!current_printer || !context.getters.getPrinterConfig(current_printer)) {
            var printersConfig = context.getters.getPrinterConfig()
            for (var p in printersConfig) {
                if (config == null) {
                    var pc = printersConfig[p]
                    if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'TRUE') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'TRUE')) {
                        config = Object.assign({}, pc)
                    }
                }
            }
        } else {
            config = Object.assign({}, context.getters.getPrinterConfig(current_printer))
        }
        var printer = context.getters.getModels(config.model)
        var templates = context.getters.getTemplatesByPrinterModel(printer.id)

        var aLabels = []
        for (var key in params) {
            var oLabel = Object.assign({}, params[key])
            oLabel.id_db = key
            var template = context.getters.getTemplates(oLabel.template)
            var label = context.getters.getLabels(oLabel.label)
            if (oLabel.template && oLabel.label && template && label && (config.templates[oLabel.label] || label.tpl_file_app.includes('.z') || printer.sdk == 'zebra')) {
                oLabel.status = 1
                aLabels.push(oLabel)
                context.commit('label/setItemQueue', oLabel, { root: true })
            }
        }

        if (aLabels.length > 0) {
            return new Promise((resolve, reject) => {
                log('-------- printing Queue Labels ---------------')
                log('aLabels -> ', aLabels)
                log('config -> ', config)
                log('printer -> ', printer)
                log('template -> ', template)
                log('label -> ', label)
                // Vue.prototype.$snackbar.info({ message: i18n.t('tooltip.print') })

                // -- APP PRINT
                const isApp = process.env.VUE_APP_IS_APP === 'TRUE'
                if (isApp) {
                    context.commit('label/setIsPrinting', true, { root: true })

                    // self.labelSelected = false
                    if (!context.getters.connectToPrinter) {
                        context.commit('setConnectToPrinter', true)
                        switch (printer.sdk) {
                            case 'brother':
                                // setTimeout(() => {
                                context
                                    .dispatch('printQueueBrother', {
                                        config: config,
                                        data: aLabels,
                                        context: { config: config.id, printer: printer.id }
                                    })
                                    .then((response) => {
                                        log('RESOLVE - printQueueLabels', response)
                                        context.commit('setConnectToPrinter', false)

                                        if (!response || response.status) {
                                            context
                                                .dispatch('label/clearQueueItems', aLabels, { root: true })
                                                .then(() => {
                                                    context.commit('label/setIsPrinting', false, { root: true })
                                                    resolve()
                                                })
                                                .catch((error) => {
                                                    context.commit('label/setIsPrinting', false, { root: true })
                                                    logError(error)
                                                    reject(response)
                                                })
                                        } else {
                                            context.commit('label/setIsPrinting', false, { root: true })
                                            reject(response)
                                        }
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        context.commit('setConnectToPrinter', false)
                                        context.commit('label/updateQueueStatus', { queue: aLabels, status: 2 }, { root: true })
                                        context.commit('label/setIsPrinting', false, { root: true })
                                        reject(e)
                                    })
                                // }, 15000)
                                break
                            case 'zebra':
                                context
                                    .dispatch('printQueueZebra', {
                                        data: aLabels,
                                        context: { config: config.id, printer: printer.id }
                                    })
                                    .then(function(response) {
                                        log('RESOLVE - printQueueLabels')
                                        context.commit('setConnectToPrinter', false)
                                        if (!response || response.status) {
                                            context
                                                .dispatch('label/clearQueueItems', aLabels, { root: true })
                                                .then(() => {
                                                    context.commit('label/setIsPrinting', false, { root: true })
                                                    resolve()
                                                })
                                                .catch((error) => {
                                                    context.commit('label/setIsPrinting', false, { root: true })
                                                    logError(error)
                                                    reject(response)
                                                })
                                        } else {
                                            context.commit('label/setIsPrinting', false, { root: true })
                                            reject(response)
                                        }
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        context.commit('setConnectToPrinter', false)
                                        context.commit('label/updateQueueStatus', { queue: aLabels, status: 2 }, { root: true })
                                        context.commit('label/setIsPrinting', false, { root: true })
                                        reject(e)
                                    })
                                break
                            // TODO DEFINE WHAT'S HAPPEN WHEN NO SDK DEFINED
                        }
                    } else {
                        // TODO RETURN THAT TEMPLATE IS NOT LOADED ON PRINTER
                        context.commit('label/setIsPrinting', false, { root: true })
                        context.commit('label/updateQueueStatus', { queue: aLabels, status: 2 }, { root: true })
                        reject({ status: false, error: 'config.printer.errors.busy2' })
                    }
                }
            })
        }
    },

    printLabelTest(context, params) {
        return new Promise((resolve, reject) => {
            var config = false
            var current_printer = params.config_id
            if (typeof current_printer === 'undefined') {
                var printersConfig = context.getters.getPrinterConfig()
                for (var p in printersConfig) {
                    if (!config) {
                        var pc = printersConfig[p]
                        if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'true') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'true')) {
                            log('ASSIGN -> ', pc)
                            config = Object.assign({}, pc)
                        }
                    }
                }
            } else {
                // console.warn(context.getters.getPrinterConfig(current_printer))
                config = Object.assign({}, context.getters.getPrinterConfig(current_printer))
            }

            if (config) {
                var printer = context.getters.getModels(config.model)
                var templates = context.getters.getTemplatesByPrinterModel(printer.id)

                for (var t in templates) {
                    templates[t].label = context.getters.getLabels(templates[t].label_id)
                }

                log(config, printer, templates)

                if (printer && Object.keys(templates).length > 0) {
                    var template = Object.values(templates)[0]

                    log('-------- printing label test ---------------')
                    log(params.printData)
                    log('Config -> ', config)
                    log('Printer -> ', printer)
                    log('Template -> ', template)

                    if (process.env.VUE_APP_IS_APP === 'TRUE') {
                        // self.labelSelected = false
                        log('---- PRINT ----')

                        switch (printer.sdk) {
                            case 'brother':
                                context
                                    .dispatch('printBrother', {
                                        context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id },
                                        data: params
                                    })
                                    .then(function() {
                                        log('RESOLVE - printLabel')
                                        resolve()
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        reject(e)
                                    })
                                break
                            case 'zebra':
                                context
                                    .dispatch('printZebra', {
                                        data: params.printData,
                                        preview: true,
                                        context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id }
                                    })
                                    .then(function() {
                                        log('RESOLVE - printLabel')
                                        resolve()
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        reject(e)
                                    })
                                break
                        }
                    } else {
                        log('--- imprimiendo en windows ---')
                        switch (printer.sdk) {
                            case 'brother':
                                if (template.label.tpl_file_windows) {
                                    // Windows configured
                                    context
                                        .dispatch('printWindows', {
                                            data: params.printData,
                                            context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id },
                                            num_copies: params.num_copies
                                        })
                                        .then(function() {
                                            context.commit('setConnectToPrinter', false)
                                            resolve()
                                        })
                                        .catch(function(e) {
                                            context.commit('setConnectToPrinter', false)
                                            reject(e)
                                        })
                                } else {
                                    reject('label.print.error')
                                }
                                break
                            case 'zebra':
                                context
                                    .dispatch('printZebra', {
                                        data: params,
                                        context: { config: config.id, printer: printer.id, template: template.id, label: template.label_id },
                                        preview: true
                                    })
                                    .then(function() {
                                        log('RESOLVE - printLabel')
                                        resolve()
                                    })
                                    .catch(function(e) {
                                        log('CATCH - error', e)
                                        reject(e)
                                    })
                                break
                        }
                    }
                } else {
                    log('-- PRINTER IS NOT CONFIGURED')
                    reject('label.print.error')
                }
            } else {
                log('-- PRINTER IS NOT CONFIGURED')
                reject('label.print.error')
            }
        })
    },

    printBrother(context, params) {
        var config = context.getters.getPrinterConfig(params.context.config)
        var printer = context.getters.getModels(params.context.printer)
        var label = context.getters.getLabels(params.context.label)
        log(' --- PRINT BROTHER --- ')
        log('Config -> ', config)
        log('Printer -> ', printer)
        log('Label -> ', label)

        var oLabel = { data: params.data.printData, numberOfCopies: params.data.num_copies }
        if (config.templates[params.context.label]) {
            oLabel.id = config.templates[params.context.label].position
        } else {
            oLabel.zpl =
                '^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR3,3~SD30^JUS^LRN^CI0^XZ' +
                '^XA' +
                '^MMT' +
                '^PW831' +
                '^LL0360' +
                '^LS0' +
                '^FO288,64^GFA,04096,04096,00032,:Z64:' +
                'eJzt1Utu2zAQBuBhBZgtYEDbAgVK9Aa9AdOb5CQlb+AjVVl1mSsoyAGiXWiU0fQfyoooWaIXBbrSIA5MfTBFzvBBtMcee/xL3JVZ+bJreMXbXuNjucmemLxBX/EK5jZ7YkPuP9EFc5f1cOWO+bzpUbrn/A0zV5F0yatIJnm34YHsU8F1IG5cyRV7Cw/rXkfdpxFseh0lQRzX3fSmSzPccrYt6ldw9imDPdVB6uw6e8ajMd+Go0WLX+HRcKPQ/iPejt7gq+NXhjtuK+7n3iukxvGzOLKEoc77jxWmbpO/wTHVX2d5zehBY+jiXrIQJFVzrzF0yw8Xt0vv6pMk+IGb5G7hdTvzKB64nbzBG+FePHDkXq/4F3hbc+siRzW4H1xLhlHA5N5GTBbeVaMfLk4OTuZNkhVc7jI/8W7wdtv7wW1woewWSb3U/wNJfg1SGAZvxGPmFQ9bJHOTu9R36X3mshTEY+aM70NUWI+e7OQyvppPo6thyS798dr7ybUs50ukPLjRY8pvlW2HZuFSn9zv5B+e1jw46ou/abveyyAmd3xCPTP/Tk+NpEiPjvBu8s/k/CG5rMNok9vpOPlIlo5oVoOnw8qbyQ/4fGLZ44/iWMQvshXa0ZFg+jbzR/bV5AoJ+IGmGhxTPzGp7Li9J1WhqfhFHFPXcz/SMXNy6ILelx8C+yXtBv6Ngwx5akmO5Mkxf1qGLlw4Eu/LZyPMDbex7C6UPTvt10LdcD27D6/DzO7T63B5+q4DNSypzy+D1V9zafp6dtmte3vDfdmL1dPl4YmXXg+/tTh82ffYY4//En8BmZeSEQ==:2CC5' +
                '^FT30,243^A0N,31,31^FH^FD[t1]^FS' +
                '^PQ1,0,1,Y^XZ'

            oLabel.data = [
                {
                    k: 't1',
                    v: i18n.t('config.printer.test.label_msg')
                }
            ]
        }

        log('PRINT BROTHER -> ', oLabel, {
            numberOfCopies: params.data.num_copies,
            orientation: 'LANDSCAPE',
            model: printer.code,
            port: config.mode,
            macAddress: config.mac,
            customPaper: context.rootGetters.getPathFiles + 'printer/' + label.printer_file,
            ipAddress: config.ip
        })

        return new Promise((resolve, reject) => {
            cordova.plugins.brotherprinter.printLabels(
                {
                    labels: [oLabel],
                    customPaper: context.rootGetters.getPathFiles + 'printer/' + label.printer_file
                },
                {
                    orientation: 'LANDSCAPE',
                    model: printer.code,
                    port: config.mode,
                    macAddress: config.mac,
                    ipAddress: config.ip
                },
                function(data) {
                    // success
                    log('success')
                    log(data)
                    context.commit('setStatusPrinter', true)
                    resolve()
                },
                function(e) {
                    // error
                    log('error ' + e)
                    var msg = 'config.printer.error_print'
                    reject(msg)
                }
            )
        })
    },

    printMultipleBrother(context, params) {
        log('---- printMultipleBrother ----', params)
        var config = context.getters.getPrinterConfig(params.context.config)
        var printer = context.getters.getModels(params.context.printer)
        var label = context.getters.getLabels(params.context.label)
        log(' --- PRINT MULTIPLE BROTHER --- ')
        log('Config -> ', config)
        log('Printer -> ', printer)
        log('Label -> ', label)
        log('Data -> ', params.data)

        return new Promise((resolve, reject) => {
            cordova.plugins.brotherprinter.printLabels(
                {
                    labels: params.data,
                    customPaper: context.rootGetters.getPathFiles + 'printer/' + label.printer_file
                },
                {
                    orientation: 'LANDSCAPE',
                    model: printer.code,
                    port: config.mode,
                    ipAddress: config.ip,
                    macAddress: config.mac
                },
                function(data) {
                    // success
                    log('success')
                    log(data)
                    context.commit('setStatusPrinter', true)
                    resolve()
                },
                function(e) {
                    // error
                    log('error ', e)
                    var msg = printer_error_message(e.code)
                    context.commit('setStatusPrinter', msg)
                    var msg = 'config.printer.error_queue_print'
                    reject(msg)
                }
            )
        })
    },

    cleanLabelsPrinted(context, params) {
        log('--- cleanLabelsPrinted ---', params)
        return new Promise((resolve, reject) => {
            if (params.length == 0) resolve()

            var label = params.pop()
            log('Eliminando registro -> ', label.id_db)
            context
                .dispatch('label/removePrintLabelLogAndroid', label.id_db, { root: true })
                .then(() => {
                    return context
                        .dispatch('cleanLabelsPrinted', params)
                        .then(() => {
                            resolve()
                        })
                        .catch((err) => {
                            reject()
                        })
                })
                .catch((err) => {
                    reject()
                })
        })
    },

    printQueueBrother2(context, params) {
        log('---- printQueueBrother2 --- ', JSON.parse(JSON.stringify(params)))
        return new Promise((resolve, reject) => {
            var aLabels = params.data
            if (aLabels.length == 0) resolve()

            var oLabel = aLabels.pop()
            var label = oLabel.label
            var labels = JSON.parse(JSON.stringify(oLabel.data))
            log('params -> ', { data: labels, context: { config: params.context.config, printer: params.context.printer, label: label } })
            context
                .dispatch('printMultipleBrother', { data: labels, context: { config: params.context.config, printer: params.context.printer, label: label } })
                .then((response) => {
                    // TODO CLEAN LABELS PRINTED
                    var aQueueIDs = []
                    labels.forEach((label) => {
                        aQueueIDs.push(label.id_db)
                    })
                    return context
                        .dispatch('label/sendPrintLabelLogAndroid', aQueueIDs, { root: true })
                        .then((response) => {
                            return context
                                .dispatch('printQueueBrother2', { data: aLabels, context: params.context })
                                .then((response) => {
                                    resolve(response)
                                })
                                .catch((error) => {
                                    reject({ status: false, error: err })
                                })
                        })
                        .catch((err) => {
                            return context
                                .dispatch('printQueueBrother2', { data: aLabels, context: params.context })
                                .then((response) => {
                                    resolve(err)
                                })
                                .catch((error) => {
                                    reject(error)
                                })
                        })
                })
                .catch((err) => {
                    log('ERROR AL IMPRIMIR', err)
                    return context
                        .dispatch('printQueueBrother2', { data: aLabels, context: params.context })
                        .then((response) => {
                            log('TODO OK PERO VENGO DE ERROR', err)
                            resolve({ status: false, error: err })
                        })
                        .catch((error) => {
                            reject({ status: false, error: err })
                        })
                })
        })
    },

    printQueueBrother(context, params) {
        return new Promise((resolve, reject) => {
            log('---- printQueueBrother --- ', params)
            var config = context.getters.getPrinterConfig(params.context.config)
            var printer = context.getters.getModels(params.context.printer)

            var aLabels = []
            params.data.forEach((oLabel) => {
                const label = context.getters.getLabels(oLabel.label)
                if (!aLabels['label_' + oLabel.label]) {
                    aLabels['label_' + oLabel.label] = { label: oLabel.label, data: [] }
                }
                if (!label.tpl_file_app.includes('.z')) {
                    aLabels['label_' + oLabel.label].data.push({
                        id: config.templates[oLabel.label].position,
                        data: oLabel.printData,
                        numberOfCopies: oLabel.quantity,
                        id_db: oLabel.id_db
                    })
                } else {
                    aLabels['label_' + oLabel.label].data.push({
                        zpl: label.data,
                        data: oLabel.printData,
                        numberOfCopies: oLabel.quantity,
                        id_db: oLabel.id_db
                    })
                }
            })

            return context
                .dispatch('printQueueBrother2', { data: Object.values(aLabels), context: params.context })
                .then((response) => {
                    log('--- FIN printQueueBrother2 ---')
                    resolve(response)
                })
                .catch((err) => {
                    log('--- FIN ERROR printQueueBrother2 ---')
                    reject(err)
                })
        })
    },

    printZebraWIFI(context, params) {
        log('PRINT ZEBRA WIFI')
        return new Promise((resolve, reject) => {
            var config = context.getters.getPrinterConfig(params.config)
            var template = params.template

            cordova.plugins.zebraprinter.printTemplate(
                template,
                { ipaddress: config.ip },
                function(e) {
                    log(e)
                    context.commit('setStatusPrinter', true)
                    resolve()
                },
                function(e) {
                    log(e)
                    var msg = 'config.printer.error_print'
                    reject(msg)
                }
            )
        })
    },

    // NEED LAUNCH BEFORE CONNECT TO PRINTER
    printZebraBluetooth(context, params) {
        log('PRINT ZEBRA BLUETOOTH')
        return new Promise((resolve, reject) => {
            cordova.plugins.zebraprinter.print(
                params.template,
                function(e) {
                    log(e)
                    context.commit('setStatusPrinter', true)
                    resolve()
                },
                function(e) {
                    log(e)
                    var msg = 'config.printer.error_print'
                    reject(msg)
                }
            )
        })
    },

    printQueueZebra2(context, params) {
        log('---- printQueueZebra2 --- ', JSON.parse(JSON.stringify(params)))
        return new Promise((resolve, reject) => {
            var aLabels = params.data
            if (aLabels.length == 0) resolve()
            else {
                var label = aLabels.pop()
                context
                    .dispatch('printZebra', {
                        data: label,
                        context: { config: params.context.config, label: label.label }
                    })
                    .then((response) => {
                        var aQueueIDs = [label.id_db]
                        log('RESOLVE printQueueZebra2 ->', aQueueIDs)
                        context
                            .dispatch('label/sendPrintLabelLogAndroid', aQueueIDs, { root: true })
                            .then(() => {
                                context
                                    .dispatch('printQueueZebra2', {
                                        data: aLabels,
                                        context: params.context
                                    })
                                    .then(() => {
                                        log('printQueueZebra2 FINISHED')
                                        resolve()
                                    })
                                    .catch((err) => {
                                        log('ERROR AFTER CLEAN LABEL AND LAUNCH PRINT QUEUE ZEBRA 2', err)
                                        reject(err)
                                    })
                            })
                            .catch((err) => {
                                log('ERROR AFTER CLEAN LABEL ')
                                reject(err)
                            })
                    })
                    .catch((err) => {
                        log('ERROR printQueueZebra2->', err)
                        context
                            .dispatch('printQueueZebra2', {
                                data: aLabels,
                                context: params.context
                            })
                            .then(() => {
                                log('TODO OK PERO VENGO DE ERROR', err)
                                resolve({ status: false, error: err })
                            })
                            .catch((err) => {
                                log('ERROR FOUND', err)
                                reject({ status: false, error: err })
                            })
                    })
            }
        })
    },

    printQueueZebra(context, params) {
        log('---- printQueueZebra ----')
        log('params ->', params)
        return new Promise((resolve, reject) => {
            var aLabels = params.data
            if (aLabels.length == 0) {
                log('printQueueZebra no more labels to print')
                resolve()
            } else {
                context
                    .dispatch('printQueueZebra2', { data: Object.values(aLabels), context: params.context })
                    .then((response) => {
                        log('--- FIN printQueueZebra2 ---')
                        resolve(response)
                    })
                    .catch((err) => {
                        log('--- FIN ERROR printQueueZebra2 ---')
                        reject(err)
                    })
            }
        })
    },

    printZebra(context, params) {
        log('---- printZebra ----')
        log('params ->', params)
        return new Promise((resolve, reject) => {
            var config = context.getters.getPrinterConfig(params.context.config)
            var label = context.getters.getLabels(params.context.label)

            log('config ->', config)
            log('label ->', label)

            let template = label.data

            if (params.preview) {
                template =
                    '^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR3,3~SD30^JUS^LRN^CI0^XZ' +
                    '^XA' +
                    '^MMT' +
                    '^PW831' +
                    '^LL0360' +
                    '^LS0' +
                    '^FO288,64^GFA,04096,04096,00032,:Z64:' +
                    'eJzt1Utu2zAQBuBhBZgtYEDbAgVK9Aa9AdOb5CQlb+AjVVl1mSsoyAGiXWiU0fQfyoooWaIXBbrSIA5MfTBFzvBBtMcee/xL3JVZ+bJreMXbXuNjucmemLxBX/EK5jZ7YkPuP9EFc5f1cOWO+bzpUbrn/A0zV5F0yatIJnm34YHsU8F1IG5cyRV7Cw/rXkfdpxFseh0lQRzX3fSmSzPccrYt6ldw9imDPdVB6uw6e8ajMd+Go0WLX+HRcKPQ/iPejt7gq+NXhjtuK+7n3iukxvGzOLKEoc77jxWmbpO/wTHVX2d5zehBY+jiXrIQJFVzrzF0yw8Xt0vv6pMk+IGb5G7hdTvzKB64nbzBG+FePHDkXq/4F3hbc+siRzW4H1xLhlHA5N5GTBbeVaMfLk4OTuZNkhVc7jI/8W7wdtv7wW1woewWSb3U/wNJfg1SGAZvxGPmFQ9bJHOTu9R36X3mshTEY+aM70NUWI+e7OQyvppPo6thyS798dr7ybUs50ukPLjRY8pvlW2HZuFSn9zv5B+e1jw46ou/abveyyAmd3xCPTP/Tk+NpEiPjvBu8s/k/CG5rMNok9vpOPlIlo5oVoOnw8qbyQ/4fGLZ44/iWMQvshXa0ZFg+jbzR/bV5AoJ+IGmGhxTPzGp7Li9J1WhqfhFHFPXcz/SMXNy6ILelx8C+yXtBv6Ngwx5akmO5Mkxf1qGLlw4Eu/LZyPMDbex7C6UPTvt10LdcD27D6/DzO7T63B5+q4DNSypzy+D1V9zafp6dtmte3vDfdmL1dPl4YmXXg+/tTh82ffYY4//En8BmZeSEQ==:2CC5' +
                    '^FT30,243^A0N,31,31^FH^FD[t1]^FS' +
                    '^PQ1,0,1,Y^XZ'

                params.data.printData = [
                    {
                        k: 't1',
                        v: i18n.t('config.printer.test.label_msg')
                    }
                ]

                log('template', template)
                log('printData', params.data.printData)
            } else {
                let numberOfCopies = params.data.num_copies ? params.data.num_copies : params.data.quantity ? params.data.quantity : 1
                template = template.replace('PQ1', 'PQ' + numberOfCopies)
            }
            params.data.printData.forEach((field) => {
                template = template.replace('[' + field.k + ']', field.v)
            })

            if (process.env.VUE_APP_IS_APP === 'TRUE') {
                if (config.mode == 'WIFI' || (config.mode == 'ALL' && config.print_mode == 'wifi')) {
                    context
                        .dispatch('printZebraWIFI', { template: template, config: config.id })
                        .then((response) => {
                            resolve()
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else if (config.mode == 'BLUETOOTH' || (config.mode == 'ALL' && config.print_mode == 'bluetooth')) {
                    cordova.plugins.zebraprinter.isConnected(
                        (connected) => {
                            if (connected) {
                                log('printTemplate - PRINTER CONNECTED')
                                context
                                    .dispatch('printZebraBluetooth', { template: template })
                                    .then((response) => {
                                        context.dispatch('disconnectZebraPrinter')
                                        resolve(response)
                                    })
                                    .catch((error) => {
                                        context.dispatch('disconnectZebraPrinter')
                                        reject(error)
                                    })
                            } else {
                                log('printTemplate - PRINTER NOT CONNECTED')
                                context
                                    .dispatch('connectZebraPrinter', { num_tries: 1, fc: 'printZebraBluetooth', config: config.id, template: template })
                                    .then((response) => {
                                        context.dispatch('disconnectZebraPrinter')
                                        resolve()
                                    })
                                    .catch((error) => {
                                        context.dispatch('disconnectZebraPrinter')
                                        reject(error)
                                    })
                            }
                        },
                        (error) => {
                            log('ERROR GETTING IS CONNECT')
                            reject(error)
                        }
                    )
                }
            } else {
                var ip_addr = config.ip
                var url = 'http://' + ip_addr + '/pstprnt'
                var async = true
                var method = 'POST'
                var zpl = template
                var request = new XMLHttpRequest()

                request.onload = function() {
                    var status = request.status
                    var data = request.responseText
                    log(data)
                    log(status)
                }

                request.open(method, url, async)
                request.send(zpl)
            }
        })
    },

    printQueueZebra(context, params) {
        return new Promise((resolve, reject) => {
            var aLabels = params.data
            if (aLabels.length == 0) resolve()
            else {
                var label = aLabels.pop()
                context
                    .dispatch('printZebra', { data: label, context: { config: params.context.config, label: label.label } })
                    .then((response) => {
                        var aQueueIDs = [label.id_db]
                        return context.dispatch('label/sendPrintLabelLogAndroid', aQueueIDs, { root: true }).then(() => {
                            return context
                                .dispatch('printQueueZebra', {
                                    config: params.config,
                                    data: aLabels,
                                    context: params.context
                                })
                                .then(() => {
                                    resolve()
                                })
                                .catch((err) => {
                                    reject(err)
                                })
                        })
                    })
                    .catch((err) => {
                        reject(err)
                    })
            }
        })
    },

    printZebraBrowser(context, params) {
        var config = context.getters.getPrinterConfig(params.context.config)
        var label = context.getters.getLabels(params.context.label)

        let template = label.data
        if (params.preview) {
            template =
                '^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR3,3~SD30^JUS^LRN^CI0^XZ' +
                '^XA' +
                '^MMT' +
                '^PW831' +
                '^LL0360' +
                '^LS0' +
                '^FO288,64^GFA,04096,04096,00032,:Z64:' +
                'eJzt1Utu2zAQBuBhBZgtYEDbAgVK9Aa9AdOb5CQlb+AjVVl1mSsoyAGiXWiU0fQfyoooWaIXBbrSIA5MfTBFzvBBtMcee/xL3JVZ+bJreMXbXuNjucmemLxBX/EK5jZ7YkPuP9EFc5f1cOWO+bzpUbrn/A0zV5F0yatIJnm34YHsU8F1IG5cyRV7Cw/rXkfdpxFseh0lQRzX3fSmSzPccrYt6ldw9imDPdVB6uw6e8ajMd+Go0WLX+HRcKPQ/iPejt7gq+NXhjtuK+7n3iukxvGzOLKEoc77jxWmbpO/wTHVX2d5zehBY+jiXrIQJFVzrzF0yw8Xt0vv6pMk+IGb5G7hdTvzKB64nbzBG+FePHDkXq/4F3hbc+siRzW4H1xLhlHA5N5GTBbeVaMfLk4OTuZNkhVc7jI/8W7wdtv7wW1woewWSb3U/wNJfg1SGAZvxGPmFQ9bJHOTu9R36X3mshTEY+aM70NUWI+e7OQyvppPo6thyS798dr7ybUs50ukPLjRY8pvlW2HZuFSn9zv5B+e1jw46ou/abveyyAmd3xCPTP/Tk+NpEiPjvBu8s/k/CG5rMNok9vpOPlIlo5oVoOnw8qbyQ/4fGLZ44/iWMQvshXa0ZFg+jbzR/bV5AoJ+IGmGhxTPzGp7Li9J1WhqfhFHFPXcz/SMXNy6ILelx8C+yXtBv6Ngwx5akmO5Mkxf1qGLlw4Eu/LZyPMDbex7C6UPTvt10LdcD27D6/DzO7T63B5+q4DNSypzy+D1V9zafp6dtmte3vDfdmL1dPl4YmXXg+/tTh82ffYY4//En8BmZeSEQ==:2CC5' +
                '^FT30,243^A0N,31,31^FH^FD[t1]^FS' +
                '^PQ1,0,1,Y^XZ'

            params.data = [
                {
                    k: 't1',
                    v: i18n.t('config.printer.test.label_msg')
                }
            ]
        }

        params.data.forEach((field) => {
            template = template.replace('[' + field.k + ']', field.v)
        })

        var ip_addr = config.ip
        var url = 'http://' + ip_addr + '/pstprnt'
        var async = true
        var method = 'POST'
        var zpl = template
        var request = new XMLHttpRequest()

        request.onload = function() {
            var status = request.status
            var data = request.responseText
            log(data)
            log(status)
        }

        request.open(method, url, async)
        request.send(zpl)
    },

    printWindows(context, params) {
        var config = context.getters.getPrinterConfig(params.context.config)
        var label = context.getters.getLabels(params.context.label)

        var tpl_path = config.path ? config.path : false
        if (tpl_path.slice(-1) != '/' && tpl_path.slice(-1) != '\\') tpl_path = tpl_path + '/'
        var tpl_file_windows = label.tpl_file_windows
        tpl_file_windows = tpl_file_windows.split('/')
        var tpl_file = tpl_file_windows[tpl_file_windows.length - 1]

        log('Print Windows -> ', tpl_path + tpl_file)
        window.DoPrint = async function DoPrint() {
            log('--- Validate if bpac is installed ---')
            if (bpac.IsExtensionInstalled() == false) {
                const agent = window.navigator.userAgent.toLowerCase()
                const ischrome = agent.indexOf('chrome') !== -1 && agent.indexOf('edge') === -1 && agent.indexOf('opr') === -1
                if (ischrome) {
                    // window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank')
                }
                log('--- BPAC NO INSTALLED ---')
                return -1
            }

            log('--- Validate if path is correct ---')
            const objDoc = bpac.IDocument
            try {
                const strPath = tpl_path + tpl_file
                const ret = await objDoc.Open(strPath)

                if (ret == true) {
                    log('--- All correct we go to print ---')
                    for (var i in params.data) {
                        var item = params.data[i]
                        let field = await objDoc.GetObject(item.k)
                        if (typeof field !== 'undefined') {
                            // Limpiamos de caracteres
                            field.Text = item.v
                                .replace(/<.?u>/gi, '')
                                .replace(/<.?b>/gi, '')
                                .replace(/<.?strong>/gi, '')
                        }
                    }

                    objDoc.StartPrint('', 0)
                    objDoc.PrintOut(parseInt(params.num_copies), 0)
                    objDoc.EndPrint()

                    // Vue.prototype.$snackbar.info({
                    //     message: i18n.t('tooltip.print')
                    // })

                    await objDoc.Close()
                    context.commit('setStatusPrinter', true)
                    return true
                } else {
                    await objDoc.Close()
                    log('--- Error -2 ---')
                    return -2
                }
            } catch (e) {
                log('--- Error -3 ---', e, typeof e)
                if (e != "Can't connect to b-PAC") {
                    await objDoc.Close()
                    return -3
                }
                return -1
            }
        }

        return new Promise((resolve, reject) => {
            log('--- launch DoPrint ---')
            return DoPrint().then(function(response) {
                if (response === true) resolve()
                else {
                    var msg = ''
                    switch (response) {
                        case -1:
                            msg = 'config.printer.errors.bpac_no_installed'
                            break
                        case -2:
                            msg = i18n.t('config.printer.errors.bpac_fail_routh')
                            msg.replace('[path]', tpl_path + tpl_file)
                            break
                        case -3:
                            msg = 'config.printer.error_print_default'
                            break
                    }
                    // alert(msg)
                    context.commit('setStatusPrinter', { status: false, msg: msg })
                    reject(msg)
                }
            })
        })
    },

    statusPrinter(context, params) {
        log('statusPrinter')
        var current_printer = context.getters.getCurrentPrinter
        var config = null
        if (!current_printer || !context.getters.getPrinterConfig(current_printer)) {
            var printersConfig = context.getters.getPrinterConfig()
            for (var p in printersConfig) {
                if (config == null) {
                    var pc = printersConfig[p]
                    if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'TRUE') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'TRUE')) {
                        config = Object.assign({}, pc)
                    }
                }
            }
        } else {
            config = Object.assign({}, context.getters.getPrinterConfig(current_printer))
        }
        var printer = context.getters.getModels(config.model)
        var templates = context.getters.getTemplatesByPrinterModel(printer.id)

        if (Object.keys(templates).length > 0) {
            var label = context.getters.getLabels(Object.values(templates)[0].label_id)
            if (label) {
                var printerConfig = {
                    id: config.id,
                    name: printer.name,
                    code: printer.code,
                    mode: config.mode,
                    mac: config.mac,
                    ip: config.ip,
                    customPaper: label.printer_file
                }

                if (!context.getters.connectToPrinter) {
                    context.commit('setConnectToPrinter', true)
                    context.commit('setCheckingStatusPrinter', true)
                    var self = this

                    switch (printer.sdk) {
                        case 'brother':
                            return context
                                .dispatch('statusBrotherPrinter', {
                                    config: printerConfig,
                                    data: params
                                })
                                .then(function() {
                                    context.commit('setConnectToPrinter', false)
                                    context.commit('setCheckingStatusPrinter', false)
                                })
                                .catch(function(error) {
                                    context.commit('setConnectToPrinter', false)
                                    context.commit('setCheckingStatusPrinter', false)
                                })
                            break
                        case 'zebra':
                            context
                                .dispatch('statusZebraPrinter', {
                                    config: printerConfig,
                                    data: params
                                })
                                .then(() => {
                                    context.commit('setConnectToPrinter', false)
                                    context.commit('setCheckingStatusPrinter', false)
                                })
                                .catch((error) => {
                                    context.commit('setConnectToPrinter', false)
                                    context.commit('setCheckingStatusPrinter', false)
                                })
                            break
                    }
                }
            } else {
                // TODO DEFINE msg
                var msg = printer_error_message(false, false)
                context.commit('setConnectToPrinter', false)
                context.commit('setCheckingStatusPrinter', false)
                context.commit('setStatusPrinter', msg)
            }
        } else {
            // TODO DEFINE msg
            var msg = printer_error_message(false, false)
            context.commit('setConnectToPrinter', false)
            context.commit('setCheckingStatusPrinter', false)
            context.commit('setStatusPrinter', msg)
        }
    },

    checkFilesPrinter(context, files) {
        return new Promise((resolve, reject) => {
            if (files.length == 0) resolve()
            else {
                // log('checkFilesPrinter...')
                // log('checkFilesPrinter...', files.length, files, typeof files)
                var file = files.pop()
                // log('checkFilesPrinter...', Object.assign({}, file), typeof file)
                if (typeof file === 'undefined') {
                    return context
                        .dispatch('checkFilesPrinter', [...files])
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                }
                window.resolveLocalFileSystemURL(
                    context.rootGetters.getPathFiles,
                    function(fileSystem) {
                        fileSystem.getFile(
                            'printer/' + file.filename,
                            { create: false },
                            function() {
                                // log('checkFilesPrinter - file - ', file, ' EXISTS ')
                                // FILE EXISTS
                                return context
                                    .dispatch('checkFilesPrinter', [...files])
                                    .then((response) => {
                                        resolve(response)
                                    })
                                    .catch((error) => {
                                        reject(error)
                                    })
                            },
                            function() {
                                // FILE DOESN'T EXISTS
                                // log('checkFilesPrinter - file - ', file, " DOESN'T EXISTS ")
                                return context
                                    .dispatch('download', [file])
                                    .then((response) => {
                                        return context
                                            .dispatch('checkFilesPrinter', [...files])
                                            .then((response) => {
                                                resolve(response)
                                            })
                                            .catch((error) => {
                                                reject(error)
                                            })
                                    })
                                    .catch((error) => {
                                        reject(error)
                                    })
                            }
                        )
                    },
                    function(err) {
                        log('download error source ' + err.source)
                        log('download error target ' + err.target)
                        log('error', err)

                        reject({ status: false, code: 'C-D' + err.code, msg: 'config.printer.errors.no_config' })
                    }
                )
            }
        })
    },

    validateLocalFiles(context, params) {
        // log('validateLocalFiles...')
        return new Promise((resolve, reject) => {
            var config = context.getters.getPrinterConfig(params.config.id)
            var labels = { ...context.getters.getLabelsByPrinterModel(config.model) }
            // log('validateLocalFiles...', labels)
            var files = []
            for (var l in labels) {
                var label = labels[l]
                if (!label.tpl_file_app.includes('.z')) {
                    files.push({ filename: label.tpl_file_app, type: 'template' })

                    files.push({ filename: label.printer_file, type: 'printer' })
                }
            }

            log('validateLocalFiles...', files)
            return context
                .dispatch('checkFilesPrinter', [...files])
                .then(() => {
                    log('validateLocaFiles end')
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    statusBrotherPrinter(context, params) {
        log('--- statusBrotherPrinter ---')
        log('params -> ', params)
        return new Promise((resolve, reject) => {
            context
                .dispatch('validateLocalFiles', params)
                .then(() => {
                    log('Init status printer Brother')
                    cordova.plugins.brotherprinter.statusPrinter(
                        {
                            orientation: 'LANDSCAPE',
                            model: params.config.code,
                            port: params.config.mode,
                            macAddress: params.config.mac,
                            ipAddress: params.config.ip,
                            customPaper: context.rootGetters.getPathFiles + 'printer/' + params.config.customPaper
                        },
                        function(data) {
                            // success
                            log('success')
                            log(data)
                            if (data.code == 'ERROR_NONE') {
                                context.commit('setStatusPrinter', true)
                                resolve()
                            } else {
                                var msg = printer_error_message(data.code)
                                // if( params.tooltip ){
                                //     Vue.prototype.$snackbar.error({ message: msg })
                                // }
                                context.commit('setStatusPrinter', msg)
                                reject(msg)
                            }
                        },
                        function(e) {
                            // error
                            log('error', e)
                            var msg = printer_error_message(e.code)
                            //   if( params.tooltip ){
                            //         Vue.prototype.$snackbar.error({ message: msg })
                            //   }
                            context.commit('setStatusPrinter', msg)
                            reject(msg)
                        }
                    )
                })
                .catch((err) => {
                    var msg = printer_error_message(false, false)
                    context.commit('setConnectToPrinter', false)
                    context.commit('setCheckingStatusPrinter', false)
                    context.commit('setStatusPrinter', msg)
                    reject(msg)
                })
        })
    },

    statusZebraPrinter(context, params) {
        var config = params.config
        return new Promise((resolve, reject) => {
            if (params.config && !params.config.id) {
                config = context.getters['getPrinterConfig'](params.config)
            }

            if (config.mode == 'WIFI' || (config.mode == 'ALL' && config.print_mode == 'wifi')) {
                context.commit('setStatusPrinter', true)
                resolve()
            } else {
                cordova.plugins.zebraprinter.isConnected(
                    (connected) => {
                        if (connected) {
                            context.dispatch('disconnectZebraPrinter')
                            context.commit('setStatusPrinter', true)
                            resolve()
                        } else {
                            log('statusZebraPrinter - PRINTER NOT CONNECTED')
                            return context
                                .dispatch('connectZebraPrinter', { num_tries: 1, fc: 'statusZebraPrinter', config: config.id })
                                .then((response) => {
                                    log('statusZebraPrinter - ALL OK  1 - ', response)
                                    context.commit('setStatusPrinter', true)
                                    resolve(response)
                                })
                                .catch((error) => {
                                    log('statusZebraPrinter - ERROR  1- ', JSON.stringify(error))
                                    if (!error || !error.msg) {
                                        error = { status: false, msg: 'config.printer.errors.zebra_wifi' }
                                    }
                                    context.commit('setStatusPrinter', error)
                                    reject(error)
                                })
                        }
                    },
                    (error) => {
                        log('ERROR TO GET IF IS CONNECTED', error)
                        if (!error || !error.msg) {
                            error = { status: false, msg: 'config.printer.errors.zebra_wifi' }
                        }
                        context.commit('setStatusPrinter', error)
                        reject(error)
                    }
                )
            }
        })
    },

    validateBpac(context, config_id) {
        // TODO - Fix validation and remove first return
        log('VALIDATING BPAC....', config_id)
        var current_printer = config_id
        // console.warn('Current printer...', current_printer)
        var config = {}
        if (!current_printer) {
            var printersConfig = context.getters.getPrinterConfig()
            for (var p in printersConfig) {
                if (Object.keys(config).length == 0) {
                    var pc = printersConfig[p]
                    if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'true') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'true')) {
                        config = Object.assign(config, pc)
                    }
                }
            }
        } else {
            // console.warn(context.getters.getPrinterConfig(current_printer))
            config = Object.assign(config, context.getters.getPrinterConfig(current_printer))
        }
        var printer = context.getters.getModels(config.model)
        var templates = context.getters.getTemplatesByPrinterModel(printer.id)
        var aBpacs = []
        var aLabelsPushed = []
        context.commit('setCheckingStatusPrinter', true)
        for (var t in templates) {
            var label = context.getters.getLabels(templates[t].label_id)
            var promise = new Promise((resolve, reject) => {
                var tpl_path = config.path ? config.path : false
                var tpl_file = label.tpl_file_windows ? label.tpl_file_windows : ''
                // log(config, label.tpl_file_windows)
                if (!tpl_path || !label.tpl_file_windows) reject({ status: false, msg: 'config.printer.errors.config_error', type: 1 })
                if (tpl_path && tpl_path.slice(-1) != '/' && tpl_path.slice(-1) != '\\') tpl_path = tpl_path + '/'
                // console.warn(tpl_file, tpl_path)

                if (!aLabelsPushed.includes(templates[t].label_id)) {
                    aBpacs.push({ tpl_path: tpl_path, tpl_file: tpl_file })
                    aLabelsPushed.push(templates[t].label_id)
                }
            })
        }

        context.commit('setProcessingTemplates', { total: aBpacs.length, current: 1 })

        return context
            .dispatch('bpacQueue', aBpacs)
            .then((response) => {
                var statusPrinter = response.status ? true : response
                context.commit('setStatusPrinter', statusPrinter)
                context.commit('setCheckingStatusPrinter', false)
                return response
            })
            .catch((error) => {
                var statusPrinter = error.status ? true : error
                context.commit('setStatusPrinter', statusPrinter)
                context.commit('setCheckingStatusPrinter', false)
                return error
            })
    },

    bpacQueue(context, aBpacs) {
        return new Promise((resolve, reject) => {
            if (aBpacs.length == 0) resolve({ status: true })
            var bpac = aBpacs.pop()
            log('bpacQueue ->', bpac)
            validateBpac(bpac.tpl_path, bpac.tpl_file).then(async function(response) {
                // log(response)
                if (response.status) {
                    context.commit('setProcessingTemplates', { total: context.getters.getProcessingTemplates.total, current: context.getters.getProcessingTemplates.current + 1 })
                    return context
                        .dispatch('bpacQueue', aBpacs)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else reject(response)
            })
        })
    },

    updateDeviceToNewConfiguration(context, params) {
        if (Object.keys(context.getters.getPrinterConfig()).length > 0) {
            return new Promise((resolve, reject) => {
                window.resolveLocalFileSystemURL(
                    cordova.file.externalRootDirectory + 'ALEX/',
                    function(fileSystem) {
                        var reader = fileSystem.createReader()
                        reader.readEntries(
                            function(entries) {
                                log(entries)

                                window.resolveLocalFileSystemURL(
                                    context.rootGetters.getPathFiles,
                                    function(appSystem) {
                                        appSystem.getDirectory(
                                            'printer',
                                            { create: true },
                                            function(dirEntry) {
                                                var aMoves = []
                                                entries.forEach((entry) => {
                                                    if (entry.isFile) {
                                                        aMoves.push(
                                                            new Promise((resolve, reject) => {
                                                                entry.moveTo(
                                                                    dirEntry,
                                                                    entry.name,
                                                                    function() {
                                                                        log('File moved', entry)
                                                                    },
                                                                    function(err) {
                                                                        log('Error moving file', err)
                                                                    }
                                                                )
                                                            })
                                                        )
                                                    }
                                                })

                                                Promise.all(aMoves).then((values) => {
                                                    log('All files moved')
                                                    context.commit('setDeviceNewConfiguration', true)
                                                    resolve()
                                                })
                                            },
                                            function(err) {
                                                log('Error Getting new Printer Directory', err)
                                                context.commit('setDeviceNewConfiguration', true)
                                                resolve()
                                            }
                                        )
                                    },
                                    function(err) {
                                        log('Error Getting new Printer Directory', err)
                                        context.commit('setDeviceNewConfiguration', true)
                                        resolve()
                                    }
                                )
                            },
                            function(err) {
                                log('Error Getting files ALEX Directory', err)
                                context.commit('setDeviceNewConfiguration', true)
                                resolve()
                            }
                        )
                    },
                    function(err) {
                        log('Error Open ALEX Directory', err)
                        context.commit('setDeviceNewConfiguration', true)
                        resolve()
                    }
                )
            })
        } else {
            return context.commit('setDeviceNewConfiguration', true)
        }
    },

    detectDevices(context, params) {
        return new Promise((resolve, reject) => {
            cordova.plugins.brotherprinter.getPrinters(function(aPrintersData) {
                resolve(aPrintersData.data)
            })
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        log('--- RESET STATE ----')

        Object.assign(state, {
            printer: false,
            templates: false,
            models: [],
            config: {},
            update_template: false
        })
        var newState = getDefaultState()
        Vue.set(state, 'printer', { ...newState.printer })
        Vue.set(state, 'templates', { ...newState.templates })
        Vue.set(state, 'models', { ...newState.models })
        Vue.set(state, 'config', { ...newState.config })
        Vue.set(state, 'update_template', { ...newState.printer })
        log(state)
    },

    setCurrentPrint(state, data) {
        log('setCurrentPrint -> setCurrentPrint', state, data)
        state.printer = data
        state.config = { ...data.config }
    },

    setUpdateTemplate(state, value) {
        log('COMMIT setUpdateTemplate')
        log(value)
        state.update_template = value
    },

    setConfig(state, value) {
        log('SET CONFIG - printer.js')
        // var printer = state.printer ? state.printer : {}
        // log('printer', printer)
        log('value', { ...value })
        // printer.config = { ...value }

        // var printer = await db_login.getItem('printer')
        // log('PRINTER FROM LOCALFORAGE', printer)

        Vue.set(state, 'config', Object.assign({}, value))
        // state.config = { ...value }
        // Vue.set(state, 'printer', { ...printer })
        // log('PRINTER ->', JSON.parse(JSON.stringify(printer)))
    },

    // TODO Revisar
    setPrinter(state, value) {
        log('->> SET PRINTER')

        var printer = state.printer ? state.printer : {}
        var config = typeof printer.config !== 'undefined' ? printer.config : {}
        config.name = value.name
        config.template_id = value.template_id ? value.template_id : ''
        config.port = value.port
        config.macAddress = value.macAddress ? value.macAddress : ''
        config.ip = value.ip ? value.ip : ''
        printer.config = config

        state.printer = printer

        // log("->> data:");
        // log(printer);
        // log("->>------");
    },

    // TODO Revisar
    setPrinterData(state, data) {
        // log("->> SET PRINTER DATA");

        Vue.set(state, 'printer', Object.assign(data.printers))
        return true
    },

    setPrinterModels(state, models) {
        Vue.set(state, 'models', Object.assign({}, models))
    },

    setTemplates(state, templates) {
        Vue.set(state, 'templates', Object.assign({}, templates))
    },

    setLabels(state, labels) {
        Vue.set(state, 'labels', Object.assign({}, labels))
    },

    setStatusPrinter(state, data) {
        if (data === true) {
            state.isValidatebPac = true
            Vue.set(state, 'isValidatebPac', true)
            state.status_printer = true
            Vue.set(state, 'status_printer', true)
        } else {
            state.isValidatebPac = false
            Vue.set(state, 'isValidatebPac', false)
            state.status_printer = false
            Vue.set(state, 'status_printer', false)
            state.error_printer = data
            Vue.set(state, 'error_printer', data)
        }
    },

    setConnectToPrinter(state, conn) {
        Vue.set(state, 'connect_to_printer', conn)
    },

    setCheckingStatusPrinter(state, conn) {
        Vue.set(state, 'check_status_printer', conn)
    },

    addNewPrinterConfig(state, params) {
        var id = 'tpitw' + moment().valueOf()
        var printerConfig = Object.assign({}, state.newPrinterConfig)

        printerConfig.id = id

        var config = Object.assign({}, state.config)
        config[id] = printerConfig

        Vue.set(state, 'config', Object.assign({}, config))
        Vue.set(state, 'current_printer_config', id)
        Vue.set(state, 'copy_current_config', Object.assign({}, config))
    },

    deletePrinterConfig(state, config_id) {
        var config = Object.assign({}, state.config)
        delete config[config_id]

        Vue.set(state, 'config', Object.assign({}, config))
    },

    setCurrentPrinter(state, config_id) {
        Vue.set(state, 'current_printer', config_id)
        state.current_printer = config_id
    },

    setCurrentPrinterConfig(state, config_id) {
        Vue.set(state, 'current_printer_config', config_id)
        Vue.set(state, 'copy_current_config', Object.assign({}, state.config[config_id]))
    },

    setPrinterConfig(state, printer) {
        var config = Object.assign({}, state.config)
        config[printer.id] = Object.assign({}, printer)

        Vue.set(state, 'config', Object.assign({}, config))
    },

    setDeviceNewConfiguration(state, value) {
        state.device_with_new_configuration = value
        localStorage.setItem('printer_device_new_configuration', value)
    },

    setIsValidatebPac(state, status = false) {
        Vue.set(state, 'isValidatebPac', status)
        state.isValidatebPac = status
    },

    setProcessingTemplates(state, value) {
        if (!value) {
            state.num_current_templates = 1
            state.num_total_templates = -1
        } else {
            state.num_current_templates = value.current ? (value.current > value.total ? value.total : value.current) : 1
            state.num_total_templates = value.total ? value.total : 1
        }
    },

    setFirstBackButton(state, value) {
        state.firstBackButton = value
    },

    setStatusZebraConfiguration(state, value) {
        state.status_zebra_configuration = value
    }
}

function printer_error_message(error, translate) {
    translate = typeof translate === 'undefined' ? false : translate
    var oError = {}
    switch (error) {
        // CONFIGURATION ERRORS
        case 'ERROR_NOT_SAME_MODEL':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C01' } // Found a different printer model than expected
            break
        case 'ERROR_BROTHER_PRINTER_NOT_FOUND':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C02' } // Cannot find a Brother printer
            break
        case 'ERROR_COMMUNICATION_ERROR':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C03' } // Failed to retrieve printer status
            break
        case 'ERROR_NO_ADDRESS':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C04' } // IP and/or MAC address not set
            break
        case 'ERROR_FILE_NOT_SUPPORTED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C05' } // Unsupported file type
            break
        case 'ERROR_WRONG_CUSTOM_INFO':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C06' } // Wrong information in custom paper setting file
            break
        case 'ERROR_WRONG_TEMPLATE_KEY':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C07' } // No file exists with the specified template key
            break
        case 'ERROR_TEMPLATE_NOT_EXIST':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C08' } // Template not found in selected printer
            break
        case 'ERROR_CREATE_SOCKET_FAILED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C09' } // Failed to create socket
            break
        case 'ERROR_CONNECT_SOCKET_FAILED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C10' } // Failed to connect
            break
        case 'ERROR_CLOSE_SOCKET_FAILED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C11' } // Failed to close socket
            break
        case 'ERROR_TEMPLATE_FILE_NOT_MATCH_MODEL':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C12' } // Printer model in template file does not match selected printer
            break
        case 'ERROR_WRONG_CASSETTE_DIRECT':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C13' } // Paper-cassette loaded incorrectly
            break
        case 'ERROR_GET_OUTPUT_STREAM_FAILED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C14' } // Failed to open output stream
            break
        case 'ERROR_GET_INPUT_STREAM_FAILED':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C15' } // Failed to open input stream
            break
        case 'ERROR_NOT_MATCH_ADDRESS':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C16' } // No printer found with the expected IP and/or MAC address
            break
        case 'ERROR_FILE_NOT_FOUND':
            oError = { status: false, msg: 'config.printer.errors.configuration', code: 'E-C17' } // File does not exist
            break

        // PAPER ERRORS
        case 'ERROR_PAPER_EMPTY':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P01' } // Paper empty
            break
        case 'ERROR_PAPER_JAM':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P02' } // Paper jam
            break
        case 'ERROR_OVERHEAT':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P03' } // Print-head overheated
            break
        case 'ERROR_FEED_OR_CASSETTE_EMPTY':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P04' } // Feed error or paper cassette empty
            break
        case 'ERROR_NO_CASSETTE':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P05' } // No paper-cassette
            break
        case 'ERROR_WRONG_LABEL':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P07' } // Wrong paper size
            break

        // USER CHANGE PAPER WHILE PRINTING
        case 'ERROR_CHANGE_CASSETTE':
            oError = { status: false, msg: 'config.printer.errors.paper', code: 'E-P06' } // Paper cassette change while printing
            break

        // SYSTEM ERRORS
        case 'ERROR_SYSTEM_ERROR':
            oError = { status: false, msg: 'config.printer.errors.system', code: 'E-S01' } // System error
            break
        case 'ERROR_INTERNAL_ERROR':
            oError = { status: false, msg: 'config.printer.errors.system', code: 'E-S02' } // Internal error
            break

        // COVER OPEN
        case 'ERROR_COVER_OPEN':
            oError = { status: false, msg: 'config.printer.errors.coveropen', code: 'E-T01' } // Cover is open (RJ/TD/PT-E550W)
            break

        // PINTER IS PRINTING
        case 'ERROR_BUSY':
            oError = { status: false, msg: 'config.printer.errors.busy', code: 'E-B01' } // Busy (PT series/RJ-3xxx/TD-4xxx)
            break

        // USER CANCEL PRINT
        case 'ERROR_CANCEL':
            oError = { status: false, msg: 'config.printer.errors.canceled', code: 'E-CA01' } // Printing has been cancelled
            break

        default:
            oError = { status: false, msg: 'config.printer.errors.default', code: '' }
            break
    }

    if (translate) {
        return i18n.t(oError.message, { s: oError.code })
    } else {
        return oError
    }
}

window.validateBpac = async function validateBpac(tpl_path, tpl_file) {
    // return { status: true }
    try {
        log('VALIDATEBPAC -> init ValidateBpac, ', tpl_path, tpl_file)
        var installed = bpac.IsExtensionInstalled()
        if (installed) {
            var aTplfile = tpl_file.split('/')
            tpl_file = aTplfile[aTplfile.length - 1]
            log('VALIDATEBPAC -> EXTENSION INSTALLED')
            const strPath = tpl_path + tpl_file
            const objDoc = bpac.IDocument
            log('VALIDATEBPAC -> OPENNING FILE')
            const ret = await objDoc.Open(strPath)
            if (ret) await objDoc.Close()
            log('VALIDATEBPAC -> CLOSING FILE')
            var msg = 'config.printer.errors.bpac_fail_routh'
            return { status: ret == true, msg: msg, type: 2 }
        }
        log('VALIDATEBPAC -> NO INSTALLED')
        return { status: false, msg: 'config.printer.errors.bpac_no_installed', type: 1 }
    } catch (e) {
        log('VALIDATEBPAC -> ERROR -> ', e)
        return { status: false, msg: 'config.printer.errors.bpac_no_installed', type: 1 }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
